import { useStores } from 'store/root-store/root-store-context';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';
import colors from 'theme/colors';
import Button from '../buttons/button';
import { H5, P3 } from '../typography/styles';

interface PerimetersNotSaveProps {
  setOpenAlert: (a: boolean) => void;
  openAlert: boolean;
  acceptToChangeTab?: 'Campo' | 'Lotes';
  onClose?: () => void;
}

const PerimetersNotSave = ({
  setOpenAlert,
  openAlert,
  acceptToChangeTab,
  onClose,
}: PerimetersNotSaveProps) => {
  const { fieldsStore } = useStores();
  const { setChangesSaved, setTabMode } = fieldsStore;

  return (
    <Dialog
      open={openAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: '24px' }}>
        <H5 color={colors.complementaryGreen} style={{ fontWeight: 500 }}>
          ¿Abandonar la sección?
        </H5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <P3 color={colors.tertiaryMedium}>
            Al irte de la sección, se perderá el polígono no guardado.
          </P3>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 24px 24px 24px' }}>
        <Button
          variant="outlined"
          preset="danger"
          size="small"
          onClick={() => {
            if (acceptToChangeTab) {
              setOpenAlert(false);
              setTabMode(acceptToChangeTab);
            }
            if (onClose) {
              setOpenAlert(false);
              setChangesSaved(false);
              onClose();
            }
          }}
          text="Abandonar"
        />
        <Button
          variant="contained"
          preset="normal"
          size="small"
          onClick={() => setOpenAlert(false)}
          text="Continuar editando"
        />
      </DialogActions>
    </Dialog>
  );
};

export default PerimetersNotSave;
