import { Divider, Grid, useMediaQuery } from '@mui/material';
import { P3, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';

type THomeCardCounter = {
  complete: number | string;
  incomplete: number | string;
};
const circleStyle = {
  width: '43px',
  height: '28px',
  background: '#E6F5DD',
  borderRadius: '100px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  display: 'inline-flex',
  textAlign: 'center',
  marginTop: '4px',
} as React.CSSProperties;

export const HomeCardCounter = ({ complete, incomplete }: THomeCardCounter) => {
  const isMobile = useMediaQuery('(min-width:900px)');

  return (
    <Grid container>
      <Grid item xs display="flex" flexDirection="column" alignItems="center">
        <P4 fontSize="12px" color={colors.tertiaryMedium}>
          Completados
        </P4>
        <div style={circleStyle}>
          <P3 style={{ color: colors.complementaryGreen }}>{complete}</P3>
        </div>
      </Grid>
      {isMobile && (
        <>
          <Grid item xs={1} display="flex" justifyContent="center">
            <Divider orientation="vertical" variant="middle" />
          </Grid>
          <Grid
            item
            xs
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <P4 fontSize="12px" color={colors.tertiaryMedium}>
              Incompletos
            </P4>
            <div style={circleStyle}>
              <P3 style={{ color: colors.complementaryGreen }}>{incomplete}</P3>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
