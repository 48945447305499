import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Divider } from '@mui/material';
import Content from 'components/layouts/content';
import { H3, P3 } from 'components/common/typography/styles';
import ProcedureModal from 'components/common/modals/procedureModal';
import DesktopAlert from 'components/common/alerts/desktopAlert';
import BannerDaruma from 'components/common/banners/BannerDaruma';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import useWindowDimensions from 'hooks/useWindowsSize';
import { Credits } from 'components/sections/procedures/finance/Credits';
import { ProceduresOnProcess } from 'components/sections/procedures/finance/ProceduresOnProcess';
import { ProceduresFinished } from 'components/sections/procedures/finance/ProceduresFinished';
import { ProcedureAvailable } from 'models/procedure';
import UploadCompanyModal from 'components/common/modals/uploadCompanyModal';
import api from '../../services/api/api';
import { useData } from '../../hooks/useData';
import { SpinnerXL } from '../../components/common/icon/icons/spinner';
import { AvailableProcedure } from '../../models/finance';

const FinanceScreen: React.FC = observer(() => {
  const { procedureHeaderStore } = useStores();
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUploadCompanies, setOpenUploadCompanies] = useState(false);
  const [isThereDaruma, setIsThereDaruma] = useState(false);
  const {
    sessionStore: { isExternal },
    cuitsStore: { cuitsByEstados },
  } = useStores();
  const [creditSelected, setCreditSelected] =
    useState<ProcedureAvailable | null>(null);

  const { width } = useWindowDimensions();

  const { sessionStore } = useStores();
  const fetcher = async () =>
    // eslint-disable-next-line no-return-await
    await api.procedure.getAvailableProcedures('financiamiento');
  const { data: procedures, isLoading } = useData(
    fetcher,
    `credits/${sessionStore?.currentUser?.cuentaActual}`,
  );

  const darumaProcedure = procedures?.filter(
    (procedure: AvailableProcedure) => procedure.alias === 'alta_daruma',
  )[0];
  const approvedCompanies = cuitsByEstados();

  const handleClose = () => {
    setCreditSelected(null);
    setOpen(false);
  };

  const handleOpen = (
    credit: ProcedureAvailable,
    isProcedureInfo?: boolean,
  ) => {
    procedureHeaderStore.setProcedure(
      '/financiamiento',
      'Financiamiento',
      credit.nombre || '',
    );
    setCreditSelected(credit);
    if (isProcedureInfo) {
      setOpen(true);
    } else if (approvedCompanies?.length <= 0) {
      setOpenUploadCompanies(true);
    } else {
      setOpen(true);
    }
  };

  const logos: string[] = [];

  if (creditSelected?.logo) {
    logos.push(creditSelected.logo);
  }

  if (creditSelected?.entidad.logo) {
    logos.push(creditSelected.entidad.logo);
  }

  return (
    <Content justifyContent="flex-start">
      <>
        {(isExternal || approvedCompanies?.length <= 0) && (
          <UploadCompanyModal
            imgHeader={creditSelected?.imgHeader || ''}
            redirectTo="/micuenta"
            logos={logos}
            title={creditSelected?.nombre || ''}
            subtitle={creditSelected?.entidad.nombre || ''}
            open={openUploadCompanies}
            onClose={() => setOpenUploadCompanies(false)}
            onlyRead={false}
            width={1150}
          />
        )}
        {creditSelected && (
          <ProcedureModal
            imgHeader={creditSelected.imgHeader}
            redirectTo={`/financiamiento/${creditSelected.alias}`}
            markdownTemplate={creditSelected.markdownTemplate}
            logos={logos}
            title={creditSelected.nombre}
            subtitle={creditSelected.entidad.nombre}
            open={open}
            onClose={handleClose}
            onlyRead={false}
            alias={creditSelected.alias}
          />
        )}
        <Box paddingBottom="24px" width="100%">
          <Box mb="16px">
            <H3 color={colors.tertiaryLow} style={{ marginBottom: '16px' }}>
              Financiamiento
            </H3>

            <Box marginBottom="24px">
              <P3 style={{ width: '100% ' }}>
                Te ofrecemos una amplia variedad de líneas de crédito para que
                puedas elegir la que mejor se adapte a las necesidades de tu
                campo.
              </P3>
            </Box>
            {isThereDaruma && !isLoading && procedures !== undefined && (
              <BannerDaruma
                setOpenUploadCompanies={handleOpen}
                darumaProcedure={darumaProcedure}
              />
            )}

            {isLoading && procedures === undefined ? (
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  height: '40px',
                  margin: '20px',
                }}
              >
                <SpinnerXL />
              </Box>
            ) : (
              <Credits
                handleOpen={handleOpen}
                width={width}
                setIsThereDaruma={setIsThereDaruma}
                procedures={procedures}
                isLoading={isLoading}
              />
            )}
          </Box>

          <Divider />
          <ProceduresOnProcess setOpenAlert={setOpenAlert} width={width} />
          <ProceduresFinished setOpenAlert={setOpenAlert} width={width} />
          <DesktopAlert
            showModal={openAlert}
            handleClose={() => setOpenAlert(false)}
          />
        </Box>
      </>
    </Content>
  );
});

export default FinanceScreen;
