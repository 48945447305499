import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { ConfirmAbandonment } from 'components/common/modals/confirmAbandonment';
import { useNavigatingAway } from 'hooks/useNavigateBlocker';
import { useViterraContext } from './useViterraContext';
import { Routes, Route } from 'react-router-dom';
import { RoutesWithProcedure } from './RutasConTrazabilidad/RoutesWithProcedure';
import { RoutesWithoutProcedure } from './RutasSinTrazabilidad/RoutesWithoutProcedure';

export const CertificacionesViterraContainer = observer(() => {
  const { state } = useViterraContext();
  const {
    showDialogPrompt: showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation,
  } = useNavigatingAway(state.moveForward);

  return (
    <>
      <ConfirmAbandonment
        body="Al cerrar la solicitud se perderán los datos ingresados hasta el momento sin guardar."
        open={showDialogLeavingPage}
        onClose={cancelNavigation}
        onClick={cancelNavigation}
        onClickButtonRejection={confirmNavigation}
        title="¿Abandonar solicitud?"
        textBtnRejection="Abandonar"
        textBtnSuccess="Continuar solicitud"
      />
      <Box width="100%">
        <Routes>
          <Route path="/" element={<RoutesWithoutProcedure />} />
          <Route path="/:code" element={<RoutesWithProcedure />} />
        </Routes>
      </Box>
    </>
  );
});
