import { lazy } from 'react';

export const LazyInsuranceSelectionScreen = lazy(
  () => import('./Seleccion/seleccion'),
);
export const LazyInsuranceReportReviewScreen = lazy(
  () => import('./Revision/revision'),
);
export const LazyInsuranceGenerationScreen = lazy(
  () => import('./EnvioSolicitud/envio-solicitud'),
);
export const LazyInsuranceAmountScreen = lazy(() => import('./Monto/monto'));
export const LazyInsuranceQuotationScreen = lazy(
  () => import('./Cotizacion/cotizacion'),
);
export const LazyInsuranceQuotationFinishedScreen = lazy(
  () => import('./Finalizada/finalizada'),
);
