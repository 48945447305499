export const errors = {
  misCampos: {
    POLYGON_NOT_COMPLETED: 'Tenés que completar el polígono del campo',
    LOCATION_NOT_COMPLETED: 'Tenés que completar la ubicación del campo',
    LOCATION_AND_POLYGON_NOT_COMPLETED:
      'Tenés que completar la ubicación y el polígono del campo',
  },
  viterra: {
    GROW_SOJA_NOT_FOUND: 'Atención: No encontramos cultivos de Soja',
    GROW_SOJA_NOT_FOUND_MODAL:
      'ATENCIÓN: No encontramos cultivos de soja en tu sistema de gestión para la campaña a certificar en los siguientes campos:',
    FIELD_IN_PROTECTED_AREA:
      'Este campo no se puede certificar ya que se encuentra dentro de una zona protegida',
    POLYGON_NOT_COMPLETED: 'Tenés que completar el polígono del campo',
    LOCATION_NOT_COMPLETED: 'Tenés que completar la ubicación del campo',
    LOCATION_AND_POLYGON_NOT_COMPLETED:
      'Tenés que completar la ubicación y el polígono del campo',
  },
  hcPuma: {
    LOCATION_NOT_COMPLETED: 'Tenés que completar la ubicación del campo',
    POLYGON_NOT_COMPLETED: 'Tenés que completar el polígono del campo',
    ACTIVE_LOTS_NOT_FOUND: 'No se encontraron lotes con cultivos',
    ACTIVE_LOTS_NOT_COMPLETED: 'Dibujá un polígono para cada lote activo',
  },
  sancorMultiriesgo: {
    LOCATION_NOT_COMPLETED: 'Tenés que completar la ubicación del campo',
    ACTIVE_LOTS_NOT_FOUND: 'No se encontraron lotes con cultivos',
    ACTIVE_LOTS_NOT_COMPLETED: 'Dibujá un polígono para cada lote activo',
  },
  DEFAULT: 'Error al obtener los datos del campo',
};
