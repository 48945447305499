import React from 'react';
import { H6 } from 'components/common/typography/styles';
import CustomTooltip from 'components/common/tooltips';
import { StyledEventBtn } from './index.styled';
import { useMediaQuery } from '@mui/material';

interface EventBtnProps {
  icon: React.ReactNode;
  text: string;
  primary: boolean;
  onPress: () => void;
  disabledBtn?: boolean;
}

const EventBtn: React.FC<EventBtnProps> = ({
  icon,
  text,
  primary,
  onPress,
  disabledBtn = false,
}) => {
  const width = useMediaQuery('(min-width:768px)');

  return (
    <CustomTooltip title={text} arrowLocation="top-start">
      <StyledEventBtn
        disabledBtn={disabledBtn}
        onClick={() => onPress()}
        primary={primary}
        type="button"
      >
        {icon}
        {width && text === 'Completar datos' && (
          <H6 className={primary ? 'btn-text' : 'btn-text-secondary'}>
            {text}
          </H6>
        )}
      </StyledEventBtn>
    </CustomTooltip>
  );
};

export default EventBtn;
