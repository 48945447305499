const fontFamilies = {
  latto: 'Latto',
  gothamRounded: 'Gotham Rounded',
  workSans: 'Work Sans',
};

const fontWeights = {
  latto: 'Latto',
  gothamRounded: 'Gotham Rounded',
};

export default {
  fontWeights,
  fontFamilies,
};
