import React from 'react';
import { Box, SxProps } from '@mui/material';
import { Caps } from 'components/common/typography/styles';
import colors from 'theme/colors';
import CustomTooltip from 'components/common/tooltips';

interface ICultivoIcon {
  nombre: string;
  preset?: 'active' | 'error' | 'warning' | 'disabled';
  backgroundDisableColor?: string;
}

const CultivoIcon: React.FC<ICultivoIcon> = ({
  nombre,
  preset = 'active',
  backgroundDisableColor = colors.tertiaryMedium,
}) => {
  const nameFormatted = nombre.charAt(0) + nombre.slice(1).toLowerCase();

  const handleSxProps = () => {
    const style: SxProps = {
      userSelect: 'none',
      backgroundImage: 'none',
      bgcolor: 'none',
    };

    switch (preset) {
      case 'active':
        return {
          ...style,
          backgroundImage: `linear-gradient(90deg, ${colors.primaryBase}, ${colors.complementaryGreen})`,
        };
      case 'disabled':
        return {
          ...style,
          bgcolor: backgroundDisableColor,
        };
      case 'error':
        return {
          ...style,
          bgcolor: colors.complementaryError,
        };
      case 'warning':
        return {
          ...style,
          bgcolor: colors.complementaryYellow,
        };
      default:
        return style;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="24px"
      height="24px"
      border="2px solid white"
      borderRadius="8px 14px 8px 14px"
      ml="-4px"
      sx={handleSxProps()}
    >
      <CustomTooltip title={nameFormatted} arrowLocation="top">
        <Caps
          color={colors.white}
          style={{
            fontSize: '18px',
            padding: '3.5px',
            fontWeight: 350,
          }}
        >
          {nombre.charAt(0)}
        </Caps>
      </CustomTooltip>
    </Box>
  );
};

export default CultivoIcon;
