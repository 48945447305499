import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { H5 } from 'components/common/typography/styles';
import ProcedureProcessCard from 'components/common/cards/procedures/Process';
import api from 'services/api/api';
import colors from 'theme/colors';
import { useData } from 'hooks/useData';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { ProcedureInProcess } from 'models/procedure';
import { useStores } from 'store/root-store/root-store-context';
import { ProceduresEmpty } from '../proceduresEmpty/ProceduresEmpty';
import {
  ARRAY_EMPTY,
  PROCEDURES_ONPROCESS_EMPTY,
} from '../proceduresEmpty/messages.procedures';
import Button from 'components/common/buttons/button';
import { useSancorContext } from 'contexts/Sancor/useSancorContext';

interface IProceduresFinished {
  handleOnAlert: (v: boolean) => void;
}

export const ProceduresOnProcess = ({ handleOnAlert }: IProceduresFinished) => {
  const { sessionStore } = useStores();
  const { dispatch } = useSancorContext();

  const fetcher = async () =>
    // eslint-disable-next-line no-return-await
    await api.procedure.getProcedures('seguro', 0);
  const { data, isLoading } = useData(
    fetcher,
    `procedureOnProcessInsurance/${sessionStore?.currentUser?.cuentaActual}`,
  );

  const [showAll, setShowAll] = useState(false);

  const handleShow = () => {
    setShowAll(!showAll);
  };
  const displayedData = showAll ? data : data?.slice(0, 9);

  return (
    <>
      <H5
        style={{ color: colors.tertiaryMedium, marginTop: '24px' }}
        id="fin_solicitudes_proceso"
      >
        Solicitudes en proceso
      </H5>
      {(data !== undefined && data.length === ARRAY_EMPTY) ||
      (data === undefined && !isLoading) ? (
        <ProceduresEmpty message={PROCEDURES_ONPROCESS_EMPTY} />
      ) : (
        <Grid container spacing="12px" mt="8px" mb="24px">
          {isLoading && data === undefined ? (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                height: '40px',
                margin: '20px',
              }}
            >
              <SpinnerXL />
            </Box>
          ) : (
            displayedData
              .filter(
                (procedure: ProcedureInProcess) =>
                  procedure.estado !== 'revision_informe',
              )
              .map((procedure: ProcedureInProcess) => {
                return (
                  <Grid
                    key={procedure.codigo}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    onClick={() =>
                      dispatch({
                        type: 'set-nameInsurance',
                        value:
                          procedure.producto.extrasproducto?.aseguradora
                            ?.nombre ?? '',
                      })
                    }
                  >
                    <ProcedureProcessCard
                      trace={procedure}
                      waitingTime={
                        procedure.estado === 'envio_solicitud' ||
                        procedure.estado === 'chequeo_agrology'
                      }
                      procedure="seguros"
                      handleOnAlert={() => handleOnAlert(true)}
                    />
                  </Grid>
                );
              })
          )}
        </Grid>
      )}
      {data?.length > 9 && (
        <Button
          text={showAll ? 'Ver menos' : `Ver más (${data.length - 9})`}
          preset="normal"
          size="large"
          onClick={handleShow}
          sx={{ width: '100%' }}
          variant="outlined"
        />
      )}
    </>
  );
};
