import React from 'react';
import { MyDataSchema } from 'utils/schema-validations';
import DynamicForm from '../dynamic';
import { inputsForm } from './index.inputs';

enum FieldNames {
  fieldName = 'firstName',
  fieldSurname = 'lastName',
  fieldEmail = 'email',
  fieldPhone = 'telefono',
}

interface IModifyDataForm {
  handleSubmitForm: (data: IUserData) => void;
  userData: any; // IUserData;
}

interface IUserData {
  [FieldNames.fieldName]: string;
  [FieldNames.fieldSurname]: string;
  [FieldNames.fieldEmail]: string;
  [FieldNames.fieldPhone]: string;
}

const ModifyDataForm: React.FC<IModifyDataForm> = (props) => {
  const { handleSubmitForm, userData } = props;

  const initialValues = {
    [FieldNames.fieldName]: userData ? userData.firstName : '',
    [FieldNames.fieldSurname]: userData ? userData.lastName : '',
    [FieldNames.fieldEmail]: userData ? userData.email : '',
    [FieldNames.fieldPhone]: userData.telefono,
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      inputs={inputsForm}
      handleSubmitFormData={(data) => handleSubmitForm(data as IUserData)}
      validationSchema={MyDataSchema}
    />
  );
};

export default ModifyDataForm;
