/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Button from '@mui/material/Button';
import colors from 'theme/colors';

interface StyledButtonProps {
  stylebtn: string;
  size: 'small' | 'large';
  disabledbtn: boolean;
  width?: string;
  deleteUser?: boolean;
  daruma?: boolean;
}

interface StyledEventBtnProps {
  primary: boolean;
  disabledBtn?: boolean;
  hasPolygon?: boolean;
}

export const StyledEventBtn = styled(Button)<StyledEventBtnProps>`
  box-shadow: none;
  width: max-content;
  height: 32px;
  padding: 0 18px;
  border-radius: 4px;
  background: ${({ primary, hasPolygon }) =>
    primary && !hasPolygon
      ? `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`
      : '#FFFFFF'};

  &.MuiButton-root {
    border: 1px solid
      ${(props) =>
        props.primary
          ? 'none'
          : props.disabledBtn
          ? colors.tertiaryMedium
          : colors.complementaryGreen};
    min-width: 48px;
    @media (max-width: 767px) {
      padding: ${(props) => (props.primary ? '0 14px' : '0px')};
    }
  }

  .btn-text {
    color: white;
  }

  .btn-text-secondary {
    background: -webkit-linear-gradient(
      left,
      ${colors.primaryBase},
      ${colors.complementaryGreen}
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ColorButton = styled(Button)<StyledButtonProps>`
  box-shadow: none;
  /* width: ${(props) => props.width}; */
  width: 100%;
  height: ${(props) => (props.size === 'small' ? '30px' : '48px')};
  padding: 0 18px;
  border: 1px solid
    ${(props) =>
      props.disabledbtn
        ? 'none'
        : props.stylebtn === 'primary'
        ? 'none'
        : props.stylebtn === 'secondary' && props.daruma
        ? colors.complementaryError
        : props.stylebtn === 'secondary' && props.deleteUser
        ? colors.complementaryError
        : colors.complementaryGreen} !important;
  border-radius: 4px;

  @media (max-width: 1023px) {
    height: 30px;
  }

  &.MuiButton-root {
    padding: 0 16px;
    background: ${(props) =>
      props.disabledbtn
        ? colors.tertiaryIntermediate
        : props.stylebtn === 'primary' && props.daruma
        ? `linear-gradient(90deg, #F34202 0%, #FF9B25 100%)`
        : props.stylebtn === 'primary' && props.deleteUser
        ? `linear-gradient(90.06deg, #D00B0B 0.06%, #FF3C00 99.95%)`
        : props.stylebtn === 'primary'
        ? `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`
        : props.stylebtn === 'secondary' && props.deleteUser
        ? colors.transparent
        : colors.transparent};
    @media (max-width: 900px) {
      padding: 0 16px;
      min-width: 43px;
    }
    /* &.MuiButton-root:hover: {
      background: 'none';
    } */
  }

  .btn-text {
    color: white;
  }

  .btn-text-disabled {
    color: ${colors.tertiaryHigh};
  }
  .btn-text-secondary {
    /* font-weight: ${(props) =>
      props.stylebtn === 'secondary' && props.daruma ? 700 : 400}; */
    background: ${(props) =>
      props.stylebtn === 'secondary' && props.daruma
        ? colors.complementaryError
        : props.stylebtn === 'secondary' && props.deleteUser
        ? colors.complementaryError
        : `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

interface StyledPerimeterButtonProp {
  disabledbtn: boolean;
}

export const StyledPerimeterButton = styled.button<StyledPerimeterButtonProp>`
  width: 100%;
  height: 48px;
  padding: 0 15px;
  border: ${(props) => (props.disabledbtn ? 'none' : '1px solid #4b484b')};
  border-radius: 8px;
  text-align: left;
  background-color: ${(props) =>
    props.disabledbtn ? colors.tertiaryIntermediate : colors.white};
  cursor: ${(props) => (props.disabledbtn ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  position: relative;

  p {
    margin: 0 0 0 8px;
    color: ${(props) =>
      props.disabledbtn ? colors.tertiaryMedium : colors.tertiaryBase};
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.01em;
  }

  .cancel-btn-icon {
    position: absolute;
    right: 18px;
  }
`;
interface BtnLink {
  inline?: boolean;
}
export const StyledLinkBtn = styled.button<BtnLink>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  display: ${(props) => (props.inline ? 'inline' : 'flex')};
  align-items: center;
  padding: 0;
`;

export const StyleBackToTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  .button {
    background-color: ${colors.tertiaryLow};
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0;
    transform: rotate(90deg);

    &:hover {
      background-color: ${colors.tertiaryMedium};
    }
  }

  position: absolute;
  bottom: 10px;
  right: 15px;
`;
