import React, { CSSProperties } from 'react';

interface IRainyCloud {
  width: string;
  height: string;
  color: string;
  style?: CSSProperties;
}

const RainyCloud = ({ width, height, color, style }: IRainyCloud) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00008 7.50016C6.35341 7.5935 6.56675 7.96016 6.47341 8.3135L5.60675 11.5335C5.51341 11.8935 5.14675 12.1002 4.79341 12.0068C4.43341 11.9135 4.22675 11.5468 4.33341 11.1935L5.18675 7.9735C5.28008 7.6135 5.64675 7.40683 6.00008 7.50016ZM8.66675 7.50016C9.02008 7.5935 9.23341 7.96016 9.14008 8.3135L7.76008 13.4668C7.66675 13.8335 7.30008 14.0335 6.94008 13.9402C6.58675 13.8335 6.37341 13.4802 6.46675 13.1202L7.85341 7.9735C7.94675 7.6135 8.31341 7.40683 8.66675 7.50016ZM11.3334 7.50016C11.6867 7.5935 11.9001 7.96016 11.8067 8.3135L10.9401 11.5335C10.8467 11.8935 10.4801 12.1002 10.1267 12.0068C9.76675 11.9135 9.56008 11.5468 9.66675 11.1935L10.5201 7.9735C10.6134 7.6135 10.9801 7.40683 11.3334 7.50016ZM11.3334 6.16683V5.50016C11.3334 4.61611 10.9822 3.76826 10.3571 3.14314C9.73198 2.51802 8.88414 2.16683 8.00008 2.16683C6.33341 2.16683 4.96675 3.38016 4.70675 4.96016C4.48675 4.88016 4.24675 4.8335 4.00008 4.8335C3.46965 4.8335 2.96094 5.04421 2.58587 5.41928C2.21079 5.79436 2.00008 6.30306 2.00008 6.8335C2.00008 7.5735 2.40008 8.22016 3.00008 8.56683V8.56016C3.33341 8.74683 3.42675 9.16683 3.24675 9.4735C3.06008 9.78683 2.66675 9.90016 2.33341 9.7135V9.72016C1.33341 9.14683 0.666748 8.06683 0.666748 6.8335C0.666748 5.94944 1.01794 5.1016 1.64306 4.47647C2.26818 3.85135 3.11603 3.50016 4.00008 3.50016C4.66675 1.9335 6.20008 0.833496 8.00008 0.833496C10.2867 0.833496 12.1601 2.60683 12.3334 4.8535L12.6667 4.8335C13.374 4.8335 14.0523 5.11445 14.5524 5.61455C15.0525 6.11464 15.3334 6.79292 15.3334 7.50016C15.3334 8.50016 14.8001 9.34683 14.0001 9.80683C13.6667 9.98683 13.2734 9.88016 13.0867 9.56016C12.9067 9.24016 13.0001 8.8335 13.3334 8.64683V8.6535C13.7334 8.42683 14.0001 7.9935 14.0001 7.50016C14.0001 7.14654 13.8596 6.8074 13.6096 6.55735C13.3595 6.30731 13.0204 6.16683 12.6667 6.16683H11.3334Z"
        fill={color}
      />
    </svg>
  );
};

export default RainyCloud;
