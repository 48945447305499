import React from 'react';
import { Box, Stack } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import DataVerificationChip from 'components/common/chips/dataVerification';
import { H5, H6, P3, P4 } from 'components/common/typography/styles';
import EventBtn from 'components/common/buttons/eventBtn';
import colors from 'theme/colors';
import { Field } from 'models/fields';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';

type LoteMetricProps = {
  title: string;
  value: string | number;
  isValid: boolean;
};

type LotesModuleProps = {
  field: Field;
  openMap: () => void;
};

const LoteMetric = ({ title, value, isValid }: LoteMetricProps) => {
  let valueColor = colors.secondaryBase;

  if (isValid) {
    valueColor = colors.complementaryGreen;
  }

  return (
    <Stack alignItems="center" gap="4.8px" width="129px">
      <Box display={{ xs: 'none', sm: 'block' }}>
        <H6 color={colors.tertiaryMedium}>{title}</H6>
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <P4
          color={colors.tertiaryMedium}
          sx={{ fontSize: '12px', fontWeight: 500 }}
        >
          {title}
        </P4>
      </Box>

      <H5
        sx={{
          color: valueColor,
          fontSize: { xs: '1.125rem', sm: '1.3125rem' },
          lineHeight: { xs: '1.125rem', sm: '1.3125rem' },
          letterSpacing: '-0.02625rem',
        }}
      >
        {value}
      </H5>
    </Stack>
  );
};

const LotesModule = ({ field, openMap }: LotesModuleProps) => {
  const {
    sessionStore: { isExternal },
  } = useStores();
  const total = field.lotes?.length || 0;
  const active = field.lotes?.filter((lote) => lote.isPlanificado).length || 0;
  const notCompleted =
    field.lotes?.filter((lote) => !lote.hasPoligono).length || 0;
  const isValid = total !== 0 && notCompleted === 0;

  const textChip = () => {
    if (isExternal) {
      return `${total} lotes`;
    }

    return total === 0
      ? '0 lotes'
      : `${total - notCompleted}/${total} con polígono`;
  };

  return (
    <Stack px="16.5px" pb="18.5px" pt="15.97px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <P3 sx={{ fontWeight: '600', color: colors.tertiaryLow }}>Lotes</P3>
        <DataVerificationChip text={textChip()} isValid={isValid} />
        <Box width="48px">
          <Box display={{ sm: 'block' }}>
            <EventBtn
              onPress={openMap}
              text="Ver en mapa"
              primary={false}
              icon={
                <LayersIcon
                  sx={{
                    color: colors.primaryBase,
                    fontSize: 16,
                    marginRight: '4px',
                  }}
                />
              }
            />
          </Box>
        </Box>
      </Stack>
      {!isExternal ? (
        <Box display="flex" justifyContent="center" mt="20px">
          <LoteMetric title="Totales" value={total} isValid={isValid} />
          <Box
            borderLeft={`1px solid ${colors.tertiaryIntermediate}`}
            borderRight={`1px solid ${colors.tertiaryIntermediate}`}
          >
            <LoteMetric
              title="Totales Activos"
              value={active}
              isValid={isValid}
            />
          </Box>
          <LoteMetric
            title="Otros Lotes"
            value={total - active}
            isValid={isValid}
          />
        </Box>
      ) : null}
    </Stack>
  );
};

export default observer(LotesModule);
