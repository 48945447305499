import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import RequestAccountForm from 'components/common/forms/requestAccount';
import { H1, P3 } from 'components/common/typography/styles';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'store/root-store/root-store-context';
import { AuthContainer } from '../index.styled';
import LinkBtn from 'components/common/buttons/linkBtn';
import colors from 'theme/colors';

const RequestAccount: React.FC = observer(() => {
  const navigate = useNavigate();
  const { sessionStore } = useStores();

  useEffect(() => {
    if (sessionStore.token !== null) {
      navigate('/');
    }
  }, []);

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="auth-title">Solicitá tu cuenta Agrology</H1>
        <RequestAccountForm />
      </Box>
      <P3 sx={{ textAlign: 'center', fontSize: '15px', marginTop: '24px' }}>
        No sos usuario de Albor o Finnegans{' '}
        <LinkBtn inline onClick={() => navigate('/portal/crear-cuenta')}>
          <span
            style={{
              color: colors.primaryBase,
              fontFamily: 'Work Sans',
              textDecoration: 'underline',
              textUnderlineOffset: '1px',
              fontSize: '15px',
              fontWeight: '500',
            }}
          >
            Registrate acá
          </span>
        </LinkBtn>
      </P3>
      <P3 sx={{ textAlign: 'center', fontSize: '15px', marginTop: '16px' }}>
        ¿Ya tenés una cuenta Agrology?{' '}
        <LinkBtn inline onClick={() => navigate('/portal/iniciar-sesion')}>
          <span
            style={{
              color: colors.primaryBase,
              fontFamily: 'Work Sans',
              textDecoration: 'underline',
              textUnderlineOffset: '1px',
              fontSize: '15px',
              fontWeight: '500',
            }}
          >
            {'  '}
            Ingresá acá
          </span>
        </LinkBtn>
      </P3>
    </AuthContainer>
  );
});

export default RequestAccount;
