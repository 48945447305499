import React from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ChevronRight } from '@mui/icons-material';
import colors from 'theme/colors';
import { P3 } from 'components/common/typography/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { IconMenu, StyledListItem, StyledSubItem } from '../styles';
import { Menu } from 'store/session-store/generalModels';

type ItemMenuDespegableProps = {
  menuOption: Menu;
  openDropdown: {
    [codigo: string]: boolean;
  };
  setOpenDropdown: (codigo: string, state: boolean) => void;
};

export const ItemMenuDespegable = ({
  menuOption,
  openDropdown,
  setOpenDropdown,
}: ItemMenuDespegableProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { icono, seccion, codigo, items, urlFront } = menuOption;
  const current = location.pathname.startsWith(urlFront);

  return (
    <Box>
      <StyledListItem
        selected={current}
        button
        id={codigo}
        onClick={() => setOpenDropdown(codigo, !openDropdown[codigo])}
        secondaryAction={openDropdown[codigo] ? <ExpandLess /> : <ExpandMore />}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>
          <IconMenu current={current}>
            <img loading="lazy" src={icono} alt={codigo} />
          </IconMenu>
        </ListItemIcon>
        <P3 style={{ paddingLeft: 10 }} color={colors.black}>
          {seccion}
        </P3>
      </StyledListItem>
      <Collapse in={openDropdown[codigo]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <StyledSubItem
              key={item.codigo}
              selected={location.pathname === item.urlFront}
              button
              id={item.codigo}
              onClick={() => navigate(item.urlFront)}
              secondaryAction={
                <ChevronRight
                  sx={{
                    color:
                      location.pathname === item.urlFront
                        ? colors.primaryBase
                        : colors.black,
                  }}
                />
              }
            >
              <P3 style={{ paddingLeft: 33 }} color={colors.black}>
                {item.nombre}
              </P3>
            </StyledSubItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
