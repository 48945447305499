import React from 'react';
import {
  Box,
  List,
  ListItem,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Navigation, Pagination } from 'swiper';
import CloseIcon from '@mui/icons-material/Close';
import { P4, H3, P3 } from 'components/common/typography/styles';
import LaptopWindows from '@mui/icons-material/LaptopWindows';
import InitialDarumaCard from 'components/common/cards/initialDarumaCard';
import Icon from 'components/common/icon';
import { SwiperDaruma, SwiperSlideDaruma } from 'theme/carruselStyles';
import colors from 'theme/colors';
import { DialogBody, DialogContainer, DialogHeader } from './index.styled';
import buttonNext from 'assets/icons/arrowNext.svg';
import buttonPrev from 'assets/icons/arrowPrev.svg';
import AllariaPlusLogo from 'assets/Logos/allariaPlusLogo.png';
import ledesma from 'assets/ledesma.png';
import argenpymes from 'assets/argenpymes.png';
import AllariaAgroLogo from 'assets/Logos/allariaAgroLogo.svg';
import BannerImage from 'assets/banner.png';
import Button from '../buttons/button';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';

export interface InitialDarumaProps {
  open: boolean;
  isPageStep?: boolean;
  onClose: () => void;
  onJoin: () => void;
}

export interface IDarumaInfoCards {
  id: number;
  icon: string;
  iconWidth: string;
  secondText: string;
  text: {
    id: number;
    text: string;
  }[];
}

const optionsList = [
  {
    id: 0,
    text: 'Minimizar gastos comerciales y administrativos',
  },
  {
    id: 1,
    text: 'Vender tu producción con estrategias de precio y Hedge',
  },
  {
    id: 2,
    text: 'Acceso a mejoras tasas de mercado y límites',
  },
  {
    id: 3,
    text: 'Integración y beneficios del ecosistema Allaria, Argenpyme y Morgan Garcia Mansilla:',
  },
];

const darumaInfoCards = [
  {
    id: 0,
    icon: AllariaPlusLogo,
    iconWidth: '80px',
    secondText: '(*) sujeto a aprobación crediticia.',
    text: [
      {
        id: 0.0,
        text: 'Crear tu cuenta sin costo de apertura ni mantenimiento.',
      },
      {
        id: 0.1,
        text: 'Invertir el dinero de tu empresa generando interés todos los días con nuestros fondos Money Market.',
      },
      {
        id: 0.2,
        text: 'Descontar e-cheqs y pagarés a través de Epyme.',
      },
      {
        id: 0.3,
        text: 'Acceso a financiamiento con tasas preferenciales para PYMES (*)',
      },
      {
        id: 0.4,
        text: 'Acceder al mercado de capitales, vinculando la cuenta con cuenta Allaria.',
      },
    ],
  },
  {
    id: 1,
    icon: ledesma,
    iconWidth: '100px',
    secondText: '(*) sujeto a aprobación crediticia.',
    text: [
      {
        id: 1.0,
        text: 'Operar en la plataforma de Allaria y acceder al mercado de capitales, desde tu cuenta Daruma.',
      },
      {
        id: 1.1,
        text: 'Asesoramiento financiero personalizado por parte de un especialista.',
      },
      {
        id: 1.2,
        text: 'Acceso a Dólar Financieros.',
      },
    ],
  },
  {
    id: 2,
    icon: argenpymes,
    iconWidth: '90px',
    secondText: '',
    text: [
      {
        id: 2.0,
        text: 'Acceder al financiamiento a través del Aval de Argenpym con un análisis crediticio 100% digital y sin enviar información adicional.',
      },
    ],
  },
  {
    id: 3,
    icon: AllariaAgroLogo,
    iconWidth: '100px',
    secondText: '',
    text: [
      {
        id: 3.0,
        text: 'Vincular Agro finanzas para el mercado de granos y mercados financieros.',
      },
      {
        id: 3.1,
        text: 'Acceder y accionar en el Mercado de Futuros y Opciones.',
      },
      {
        id: 3.2,
        text: 'Acceder y accionar en el Corretaje de Granos.',
      },
      {
        id: 3.2,
        text: 'Acceder y accionar en el Mercado de Capitales.',
      },
      {
        id: 3.2,
        text: 'Obtener Coberturas de tipo de cambio.',
      },
    ],
  },
];

const InitialDaruma: React.FC<InitialDarumaProps> = ({
  open,
  isPageStep = false,
  onClose,
  onJoin,
}) => {
  // width <= 1024
  const minWidth = useMediaQuery('(max-width:1024px)');

  // width <= 767
  const width = useMediaQuery('(max-width:767px)');

  return (
    <DialogContainer open={open} onClose={onClose} scroll="body" daruma>
      <DialogHeader
        sx={{
          backgroundImage: `url(${BannerImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflow: 'hidden',
          height: '109px',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 16,
            right: 27,
            color: colors.white,
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <Box maxWidth="400px">
          <H3 color={colors.white}>
            Sumate al ecosistema de agrofinanzas Allaria
          </H3>
        </Box>
      </DialogHeader>
      <DialogBody>
        <Stack justifyContent="center" mt="28px">
          <P3 color={colors.tertiaryMedium}>
            Con Allaria y Agrology vas a poder revolucionar las finanzas de tu
            empresa agropecuaria de forma 100% digital. ¿Qué obtenés? Una vez
            dado de alta en Allaria accedes a los siguientes beneficios:
          </P3>
          <List>
            {optionsList.map((item) => {
              return (
                <ListItem key={item.id} style={{ padding: '8px 0px' }}>
                  <Stack direction="row" gap="12px" alignItems="center">
                    <div>
                      <Icon icon="bulletModal" />
                    </div>
                    <P4
                      color={colors.tertiaryBase}
                      style={{
                        fontSize: '14px',
                        lineHeight: '17.5px',
                      }}
                    >
                      {item.text}
                    </P4>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
          <Stack
            width="100%"
            maxWidth="610px"
            padding="24px 0px"
            alignSelf="center"
          >
            <SwiperDaruma
              navigation
              buttonPrev={buttonPrev}
              buttonNext={buttonNext}
              pagination={{ clickable: true }}
              loop
              spaceBetween={22}
              centeredSlides={width}
              modules={[Navigation, Pagination]}
              slidesPerView={width ? 1 : 2}
            >
              {darumaInfoCards.map((item: IDarumaInfoCards) => {
                return (
                  <SwiperSlideDaruma key={item.id}>
                    <InitialDarumaCard
                      icon={item.icon}
                      list={item.text}
                      secondText={item.secondText}
                      iconWidth={item.iconWidth}
                    />
                  </SwiperSlideDaruma>
                );
              })}
            </SwiperDaruma>
          </Stack>

          {isPageStep &&
            (minWidth ? (
              <Box
                display="flex"
                sx={{
                  gap: 2,
                  marginBottom: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <LaptopWindows style={{ color: colors.tertiaryMedium }} />
                <P4 color={colors.tertiaryMedium}>
                  Visitá app.agrology.com desde tu computadora para iniciar o
                  ver el detalle de los trámites.
                </P4>
              </Box>
            ) : (
              <Box
                style={{
                  alignSelf: 'center',
                }}
              >
                <Button
                  variant="contained"
                  preset="normal"
                  size="large"
                  onClick={onJoin}
                  text="Quiero sumarme"
                />
              </Box>
            ))}
        </Stack>
      </DialogBody>
    </DialogContainer>
  );
};

export default InitialDaruma;
