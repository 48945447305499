import React, { useState } from 'react';
import {
  Divider,
  List,
  ListItemIcon,
  Slide,
  useMediaQuery,
} from '@mui/material';
import { MailOutlineTwoTone } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import ContactModal from 'components/common/modals/contactModal';
import { H6, P3 } from 'components/common/typography/styles';
import AccountsDropdown from 'components/common/dropdowns/accounts';
import colors from 'theme/colors';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';

import { UserData } from 'utils/types';
import ConfirmMessageModal from 'components/common/modals/confirmMsgModal';
import { DrawerFooter, DrawerHeader, StyledListItem } from '../styles';
import { ItemMenu } from './item';
import { ItemMenuDespegable } from './desplegable';

type MenuDesktopProps = {
  userData: UserData;
  openMenu: boolean;
  setOpenMenu: (a: boolean) => void;
  openDropdown: {
    [codigo: string]: boolean;
  };
  setOpenDropdown: (codigo: string, state: boolean) => void;
};

const MenuDesktop = observer((props: MenuDesktopProps) => {
  const { openMenu, openDropdown, setOpenDropdown } = props;
  const {
    sessionStore: { currentUser, menuParsed },
  } = useStores();

  const location = useLocation();
  const [showContactAlert, setShowContactAlert] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  // width <= 1280
  const width = useMediaQuery('(max-width:1280px)');

  return (
    <Slide
      direction="right"
      in={openMenu}
      mountOnEnter
      unmountOnExit
      style={{
        height: width ? '100%' : 'calc(100vh - 48px)',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        className="item-scroll"
      >
        {!!currentUser && (
          <DrawerHeader>
            <H6
              color={colors.tertiaryMedium}
              style={{ margin: '16px 16px 0 16px' }}
            >
              Cuenta actual
            </H6>
            <AccountsDropdown />
          </DrawerHeader>
        )}
        <Divider />

        <List sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
          {Object.keys(menuParsed).map((categoria, key) => (
            <React.Fragment key={`${categoria}-${key + 1 - 1}`}>
              <H6
                color={colors.tertiaryMedium}
                style={{ margin: '16px 0 0 16px' }}
              >
                {categoria}
              </H6>
              {menuParsed[categoria].map((opt) => {
                if (opt.items.length === 0) {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { items, ...rest } = opt;
                  return <ItemMenu key={opt.codigo} {...rest} />;
                }
                return (
                  <ItemMenuDespegable
                    setOpenDropdown={setOpenDropdown}
                    openDropdown={openDropdown}
                    menuOption={opt}
                    key={opt.codigo}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </List>

        <ContactModal
          open={showContactAlert}
          onClose={() => setShowContactAlert(false)}
          openConfirmMessage={() => setMessageSent(true)}
          type="contacto"
        />
        <ConfirmMessageModal
          open={messageSent}
          onClose={() => setMessageSent(false)}
        />

        <DrawerFooter>
          <Divider sx={{ width: '100%' }} />
          <StyledListItem
            button
            key=""
            selected={location.pathname === '/ayuda'}
            onClick={() => setShowContactAlert(true)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <MailOutlineTwoTone sx={{ color: colors.tertiaryBase }} />
            </ListItemIcon>
            <P3
              style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}
              color={colors.tertiaryBase}
            >
              Contactanos
            </P3>
          </StyledListItem>
        </DrawerFooter>
      </div>
    </Slide>
  );
});

export default MenuDesktop;
