export const Strings = {
  SomethingWentWrong: 'Algo salió mal',
  notifications: {
    tokenNotFoundOrInvalid: 'La solicitud de recuperación de contraseña expiró',
    expiredTokenInvite: 'La invitación expiró',
    invalidToken:
      'Cerramos tu sesión ya que detectamos el uso de la aplicación desde otro dispositivo.',
    expiredToken:
      'Por tu seguridad, tu sesión ha expirado por inactividad, por favor accedé nuevamente.',
    invalidUser:
      'Ocurrió un problema al acceder a tu cuenta, si el error persiste comunícate con nosotros.',
    success: 'Se ha realizado con éxito la operacion solicitada',
    commonError:
      'Estamos teniendo inconvenientes para realizar esta operación.',
    resendedInviteOk: 'Reenviamos la invitación',
    resendedInviteError: 'No pudimos reenviar la invitación',
    validateFromErpSucces: 'Solicitud procesada con éxito...',
    errorCuit:
      'Error al intentar procesar el CUIT de la insumera. Revisalo y volvé a intentarlo.',
    fields: {
      createdLotes: 'Se han creado los lotes con éxito',
      updatedLotes: 'Se han modificado los lotes con éxito',
      deletedLote: 'Se ha eliminado el lote con éxito',
      createdLotesError: 'Error! No ha sido posible crear los lotes',
      updatedLotesError: 'Error! No ha sido posible modificar los lotes',
      deletedLoteError: 'Error! No ha sido posible eliminar el lote',
      createdField: 'Se ha creado el campo con éxito',
      updatedField: 'Se ha modificado el campo con éxito',
      deletedField: 'Se ha eliminado el campo con éxito',
      createdFieldError: 'Error! No ha sido posible crear el campo',
      updatedFieldError: 'Error! No ha sido posible modificar el campo',
      deletedFieldError: 'Error! No ha sido posible eliminar el campo',
      alreadyExists: 'Ya existe un campo con ese nombre para la cuenta',
    },
  },
  inputs: {
    requiredError: { error: 'Este campo es obligatorio' },
    phoneNumber: {
      helper: 'Ingresá tu número sin el 15 y con código de área (11)',
      error: 'Revisá el número telefónico',
    },
    password: {
      error: 'La contraseña no cumple con los requisitos mínimos',
      mismatch: 'Las contraseñas no coinciden.',
    },
    hectareas: {
      declaredCannotBeGreater:
        'Las hectáreas declaradas no pueden ser mayores que las aprobadas',
      declaredCannotLessThanOne:
        'Las hectáreas declaradas no pueden ser menores a 1',
      declaredCannotBeString: 'Debes ingresar un numero en este campo',
      declaredRequired: 'Este campo es obligatorio',
    },
    email: {
      error: 'El formato del email ingresado es inválido',
    },
    min3Error: {
      error: 'Debe tener al menos 3 caracteres',
    },
    min10Error: {
      error: 'Debe tener al menos 10 caracteres',
    },
    max5000Error: {
      error: 'No puede tener mas de 5000 caracteres',
    },
    max50Error: {
      error: 'No puede tener mas de 50 caracteres',
    },
    paísError: {
      error: 'Debe elegir un país',
    },
    nifError: {
      error: 'El NIF es necesario',
    },
  },
  forms: {
    fieldModification: {
      success: 'Se han guardado los datos con exito',
      error: 'Ocurrió un problema al guardar los archivos',
    },
    login: {
      error: 'Por favor verificá tu usuario o contraseña ingresados',
      success: 'Sesión iniciada con éxito',
    },
    finance: {
      error: {
        requiredError: 'Seleccioná una empresa',
        requiredCheck: 'Marcá las empresas que pertenecen al grupo',
        requiredRadio: 'Seleccioná la respuesta',
        atLeast1: 'Marcá al menos uno',
      },
    },
  },
  domainErp: {
    albor: 'Albor',
    finnegans: 'Finnegans',
  },
};
