import React, { useEffect, useRef, useState } from 'react';
import { uploadFile } from 'services/files';

interface ImageViewerProps {
  fileURL: string;
  styles?: React.CSSProperties;
  altText?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  fileURL,
  styles = {},
  altText = 'securedImage',
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const loadImage = async () => {
    const fileUrl = await uploadFile(fileURL, 'image/jpeg');
    setImageUrl(fileUrl);
  };

  useEffect(() => {
    if (!fileURL.includes('blob:') && !fileURL.includes('data:')) {
      loadImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURL]);

  if (fileURL.includes('blob:') || fileURL.includes('data:')) {
    return <img loading="lazy" src={fileURL} style={styles} alt={altText} />;
  }
  if (imageUrl && !fileURL.includes('blob:') && !fileURL.includes('data:')) {
    return (
      <img
        loading="lazy"
        src={imageUrl}
        style={styles}
        ref={imageRef}
        alt={altText}
      />
    );
  }
  return null;
};

export default ImageViewer;
