import { useCallback, useEffect, useContext } from 'react';
import {
  ArgenpymeDispatchContext,
  ArgenpymeStateContext,
} from 'contexts/Argenpymes';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { Box } from '@mui/material';
import {
  LazySolicitudEnviada,
  LazySolicitudFinalizada,
} from '../LazyArgenpyme';
import { ProcedureStatus } from 'models/procedure';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { useParams } from 'react-router-dom';

export const RoutesWithProcedure = () => {
  const { stateProcedure } = useContext(ArgenpymeStateContext);
  const dispatch = useContext(ArgenpymeDispatchContext);
  const { code, alias } = useParams();

  const { procedureHeaderStore } = useStores();
  const getProcedureInfo = useCallback(async () => {
    if (code && alias) {
      const response = await api.procedure.getProcedureDetail(
        'financiamiento',
        code,
      );
      dispatch({ type: 'set-stateProcedure', value: response.estado });

      const logos = [];

      if (response.producto.logo) {
        logos.push(response.producto.logo);
      }

      if (response.producto.entidad.logo) {
        logos.push(response.producto.entidad.logo);
      }

      const procedureInfo = {
        alias: response.producto.alias as string,
        productName: response.producto.nombre as string,
        markdownTemplate: response.producto.markdownTemplate as string,
        headerImg: response.producto.imgHeader as string,
        images: logos as string[],
        status: (response.estado === 'solicitud_enviada'
          ? 'finished'
          : 'rejected') as ProcedureStatus,
      };
      procedureHeaderStore.setProcedureInfo(procedureInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProcedureInfo();
  }, [getProcedureInfo]);

  if (stateProcedure === 'envio_solicitud') {
    return (
      <ProcedureStepSuspense>
        <LazySolicitudEnviada />
      </ProcedureStepSuspense>
    );
  }

  if (stateProcedure === 'solicitud_finalizada') {
    return (
      <ProcedureStepSuspense>
        <LazySolicitudFinalizada />
      </ProcedureStepSuspense>
    );
  }

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
