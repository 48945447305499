import React from 'react';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { ConfirmAbandonment } from 'components/common/modals/confirmAbandonment';
import ProcedureProgress from 'components/common/progress/procedureProgress';

import { useAgreeContext } from './agreeUseContext';
import { useNavigatingAway } from 'hooks/useNavigateBlocker';
import { RoutesWithoutProcedure } from './rutasSinTramiteIniciado';
import { RoutesWithProcedureInitiated } from './rutasConTramiteIniciado';

export const AgreeContainer: React.FC = observer(() => {
  const { state } = useAgreeContext();
  const {
    showDialogPrompt: showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation,
  } = useNavigatingAway(state.moveForward);

  return (
    <>
      <ConfirmAbandonment
        body="Al cerrar la solicitud se perderán los datos ingresados hasta el momento sin guardar."
        open={showDialogLeavingPage}
        onClose={cancelNavigation}
        onClick={cancelNavigation}
        onClickButtonRejection={confirmNavigation}
        title="¿Abandonar solicitud?"
        textBtnRejection="Abandonar"
        textBtnSuccess="Continuar solicitud"
      />

      <Box width="100%" mx="auto">
        <Box>
          <ProcedureProgress
            procedure="agree"
            state={state.stateProcedure}
            refused={state.refused}
          />
        </Box>
        <Routes>
          <Route path="/" element={<RoutesWithoutProcedure />} />
          <Route path="/:code" element={<RoutesWithProcedureInitiated />} />
        </Routes>
      </Box>
    </>
  );
});
