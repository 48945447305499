import React from 'react';
import { SvgProps } from '..';

const FilterIcon: React.FC<SvgProps> = ({ width, height, color }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '25'}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons:filter">
        <g id="Group">
          <path
            id="Vector"
            d="M5.00049 12.5547V4.55469M19.0005 20.5547V17.5547M5.00049 20.5547V16.5547M19.0005 13.5547V4.55469M12.0005 7.55469V4.55469M12.0005 20.5547V11.5547"
            stroke={color || '#928176'}
            strokeLinecap="round"
          />
          <path
            id="Vector_2"
            d="M5.00049 16.5547C6.10506 16.5547 7.00049 15.6593 7.00049 14.5547C7.00049 13.4501 6.10506 12.5547 5.00049 12.5547C3.89592 12.5547 3.00049 13.4501 3.00049 14.5547C3.00049 15.6593 3.89592 16.5547 5.00049 16.5547Z"
            stroke={color || '#928176'}
            strokeLinecap="round"
          />
          <path
            id="Vector_3"
            d="M12.0005 11.5547C13.1051 11.5547 14.0005 10.6593 14.0005 9.55469C14.0005 8.45012 13.1051 7.55469 12.0005 7.55469C10.8959 7.55469 10.0005 8.45012 10.0005 9.55469C10.0005 10.6593 10.8959 11.5547 12.0005 11.5547Z"
            stroke={color || '#928176'}
            strokeLinecap="round"
          />
          <path
            id="Vector_4"
            d="M19.0005 17.5547C20.1051 17.5547 21.0005 16.6593 21.0005 15.5547C21.0005 14.4501 20.1051 13.5547 19.0005 13.5547C17.8959 13.5547 17.0005 14.4501 17.0005 15.5547C17.0005 16.6593 17.8959 17.5547 19.0005 17.5547Z"
            stroke={color || '#928176'}
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default FilterIcon;
