import { Box, Button, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { RegisterExternalSchema } from 'utils/schema-validations';
import TextInput from 'components/common/inputs/text';
import { H1, P3 } from 'components/common/typography/styles';
import { ChekcListPass } from 'components/common/tooltipPassword/ChekcListPass';
import { TooltipPassword } from 'components/common/tooltipPassword/TooltipPassword';
import {
  pipe,
  capitalLetter,
  checks,
  hasNumber,
  moreEightChar,
  lowercaseLetter,
  specialChar,
} from 'utils/checklistPassUtils';
import { CheckItem } from 'utils/types';
import LinkBtn from 'components/common/buttons/linkBtn';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { ToSModal } from 'components/common/modals/tosModal';
import { AuthButton } from 'components/common/buttons/authButton';
import { StyledButton } from 'components/common/buttons/button/index.styled';
import { SpinnerSMW } from 'components/common/icon/icons/spinner';
import { useHandleSteps } from 'components/common/Containers/useHandleSteps';
import { externalLogin, registerExternal } from 'services/api/authApi';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { Strings } from 'constants/strings';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import AlborIcon from 'assets/icons/albor-icon.svg';
import FinnegansIcon from 'assets/icons/finnegans-icon.svg';

enum FieldNames {
  fieldEmail = 'email',
  fieldPassword = 'password',
  fieldRepeatPassword = 'passwordRepeat',
}

interface ISignUp {
  setUserCredentials: (crendentials: {
    email: string;
    password: string;
    token: string;
  }) => void;
}

export const SignUp: React.FC<ISignUp> = ({ setUserCredentials }) => {
  const { handleNextStep } = useHandleSteps();
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [checkListArr, setCheckListArr] = useState<CheckItem[]>(checks);
  const [progress, setProgress] = useState(39);
  const [valuePass, setValuePass] = useState<string>('');
  const [ToS, setToS] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    [FieldNames.fieldEmail]: '',
    [FieldNames.fieldPassword]: '',
    [FieldNames.fieldRepeatPassword]: '',
  };

  const isMobile = useMediaQuery('(max-width:1024px)');

  const signUpUser = () => {
    handleNextStep();
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      specialChar,
      hasNumber,
      lowercaseLetter,
      capitalLetter,
      moreEightChar,
    )(valuePass);

    const checksListMatched = checks
      .filter((check) => check.key !== 'lessEigthChar')
      .map((e) => {
        const matchingItem = iterable.find((v) => v.key === e.key);
        if (matchingItem) {
          e.checked = matchingItem.checked;
        }
        return { ...e };
      });

    return checksListMatched;
  };

  const valueProgress = () =>
    checkListArr.filter((e: CheckItem) => e.checked === true);

  const onExternalUserSuccess = (token: string | undefined) => {
    if (token) {
      sessionStore.setToken(token);
      api.api.setToken(token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: 'success',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      navigate('/');
    }
  };

  const handleOnSubmit = async ({
    email,
    password,
  }: Record<string, string>) => {
    const token =
      (await recaptchaRef?.current?.executeAsync()) ?? 'token-recaptcha';
    recaptchaRef?.current?.reset();
    setIsLoading(true);

    registerExternal({
      email,
      password,
      recaptchaToken: token,
      onSuccess: () => {
        signUpUser();
        setUserCredentials({ email, password, token });
        setIsLoading(false);
      },
      onFailure: handleResponseErrors,
    });
  };

  const handleResponseErrors = (message: string | undefined) => {
    setIsLoading(false);
    enqueueSnackbar(message ?? 'Algo salio mal', {
      variant: 'error',
      autoHideDuration: 1500,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  useEffect(() => {
    setCheckListArr(handleMatch());
    setProgress(() => valueProgress().length * 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePass, valueProgress().length]);

  return (
    <>
      <ToSModal onClose={() => setToS(false)} open={ToS} />
      <Box width="100%">
        <H1 className="auth-title" sx={{ fontWeight: 350 }}>
          Solicitá tu usuario de Agrology
        </H1>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={RegisterExternalSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ handleSubmit, values }) => {
            if ('password' in values) {
              setValuePass(values?.password);
            }
            return (
              <>
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                  ref={recaptchaRef}
                  size="invisible"
                  hl="es"
                />
                <Box width="100%">
                  <TextInput
                    id={FieldNames.fieldEmail}
                    label="Tu email"
                    labelWeight="500"
                    labelSize="16px"
                    disabled={false}
                    name={FieldNames.fieldEmail}
                    width="100%"
                    height="48px"
                    type="email"
                    handleSubmit={handleSubmit}
                    placeholder="Ingresá tu email"
                  />
                </Box>
                <TooltipPassword
                  open={openPopUp}
                  onClose={() => setOpenPopUp(false)}
                  onOpen={() => setOpenPopUp(true)}
                  title={
                    <>
                      <ChekcListPass
                        checksList={checkListArr}
                        progress={progress}
                      />
                    </>
                  }
                  placement={isMobile ? 'top' : 'left-start'}
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <Box marginTop="16px" width="100%">
                    <TextInput
                      id={FieldNames.fieldPassword}
                      label="Tu contraseña"
                      labelWeight="500"
                      labelSize="16px"
                      disabled={false}
                      name={FieldNames.fieldPassword}
                      width="100%"
                      height="48px"
                      type="password"
                      placeholder="Ingresá tu contraseña"
                      handleSubmit={handleSubmit}
                      handleFocus={handleFocus}
                      handleBlur={handleBlur}
                    />
                  </Box>
                </TooltipPassword>
                <Box marginTop="16px" width="100%">
                  <TextInput
                    id={FieldNames.fieldRepeatPassword}
                    label="Confirmar contraseña"
                    labelWeight="500"
                    labelSize="16px"
                    disabled={false}
                    name={FieldNames.fieldRepeatPassword}
                    width="100%"
                    height="48px"
                    type="password"
                    placeholder="Ingresá tu contraseña"
                    handleSubmit={handleSubmit}
                  />
                </Box>
                <P3
                  sx={{
                    marginTop: '16px',
                    marginBottom: '24px',
                    fontSize: '15px',
                    maxWidth: '442px',
                    lineHeight: '125%',
                  }}
                >
                  Al hacer click en “crear una cuenta”, confirmo que leí y
                  acepto las{' '}
                  <LinkBtn inline onClick={() => setToS(true)}>
                    <P3
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '15px',
                      }}
                    >
                      Condiciones, Políticas de datos y privacidad.
                    </P3>
                  </LinkBtn>
                </P3>
                <StyledButton
                  variant="contained"
                  size="large"
                  sx={{
                    padding: '12px 16px',
                    fontSize: '20px',
                  }}
                  fullWidth
                  disabled={isLoading}
                  onClick={() => handleSubmit()}
                >
                  {isLoading ? <SpinnerSMW /> : 'Crear Cuenta'}
                </StyledButton>

                <P3 sx={{ marginY: '16px', textAlign: 'center' }}>O</P3>
                <AuthButton
                  onSuccess={(tokenResponse) => {
                    externalLogin({
                      accessToken: tokenResponse.access_token,
                      onSuccess: onExternalUserSuccess,
                      onFailure: handleResponseErrors,
                    });
                  }}
                >
                  Registrarme con Google
                </AuthButton>
                <Box display="flex" gap={2} mt={2}>
                  <Button
                    fullWidth
                    onClick={() => navigate('/portal/solicitar-cuenta')}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontSize: '20px',
                      gap: '8px',
                      minHeight: '48px',
                      alignItems: 'center',
                      display: 'flex',
                      borderColor: colors.tertiaryMedium,
                      color: colors.tertiaryMedium,
                      '&:hover': {
                        borderColor: colors.tertiaryMedium,
                      },
                    }}
                  >
                    <img
                      src={AlborIcon}
                      alt="albor"
                      width="22.49px"
                      height="26.94px"
                    />
                    <P3
                      sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                        color: colors.tertiaryMedium,
                      }}
                    >
                      Albor
                    </P3>
                  </Button>
                  <Button
                    onClick={() => navigate('/portal/solicitar-cuenta')}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    sx={{
                      fontSize: '20px',
                      minHeight: '48px',
                      borderColor: colors.tertiaryMedium,
                      color: colors.tertiaryMedium,
                      '&:hover': {
                        borderColor: colors.tertiaryMedium,
                      },
                    }}
                  >
                    <img
                      src={FinnegansIcon}
                      alt="Finnegans"
                      width="22.49px"
                      height="26.94px"
                    />
                    <P3
                      sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                        color: colors.tertiaryMedium,
                      }}
                    >
                      Finnegans
                    </P3>
                  </Button>
                </Box>
              </>
            );
          }}
        </Formik>
        <P3 sx={{ textAlign: 'center', fontSize: '15px', marginTop: '16px' }}>
          ¿Ya tenés una cuenta Agrology?{' '}
          <LinkBtn inline onClick={() => navigate('/portal/iniciar-sesion')}>
            <span
              style={{
                color: colors.primaryBase,
                fontFamily: 'Work Sans',
                textDecoration: 'underline',
                textUnderlineOffset: '1px',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
              {'  '}
              Ingresá acá
            </span>
          </LinkBtn>
        </P3>
      </Box>
    </>
  );
};
