import React, { useState } from 'react';
import {
  Box,
  Divider,
  List,
  ListItemIcon,
  Slide,
  useMediaQuery,
} from '@mui/material';
import { MailOutlineTwoTone } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import { UserData } from 'utils/types';
import CustomTooltip from 'components/common/tooltips';

import ContactModal from 'components/common/modals/contactModal';
import ConfirmMessageModal from 'components/common/modals/confirmMsgModal';
import { DrawerFooter, StyledListItem } from '../styles';
import { ItemMenu, ItemMenuDesplegable } from './item';

type MenuMobileProps = {
  userData: UserData;
  openMenu: boolean;
  setOpenMenu: (a: boolean) => void;
  setOpenDropdown?: (codigo: string, state: boolean) => void;
};

const MenuMobile = observer((props: MenuMobileProps) => {
  const { openMenu, setOpenMenu, setOpenDropdown } = props;
  const {
    sessionStore: { menuParsed },
  } = useStores();

  const location = useLocation();
  const [showContactAlert, setShowContactAlert] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  // width <= 1023
  const width = useMediaQuery('(max-width:1023px)');

  return (
    <Slide
      direction="right"
      in={openMenu}
      mountOnEnter
      unmountOnExit
      style={{
        height: width ? '100%' : 'calc(100vh - 48px)',
        width: '59px',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <List
          sx={{
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '59px',
          }}
        >
          {Object.keys(menuParsed).map((categoria, key) => (
            <React.Fragment key={`${categoria}-${key + 1 - 1}`}>
              {menuParsed[categoria].map((opt) => {
                if (opt.items.length === 0) {
                  return (
                    <ItemMenu
                      key={opt.codigo}
                      text={opt.seccion}
                      to={opt.urlFront}
                      icon={opt.icono}
                      id={opt.codigo}
                    />
                  );
                }
                return (
                  <ItemMenuDesplegable
                    setOpenMenu={setOpenMenu}
                    setOpenDropdown={setOpenDropdown}
                    menuOption={opt}
                    key={opt.codigo}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </List>

        <ContactModal
          open={showContactAlert}
          onClose={() => setShowContactAlert(false)}
          openConfirmMessage={() => setMessageSent(true)}
          type="contacto"
        />
        <ConfirmMessageModal
          open={messageSent}
          onClose={() => setMessageSent(false)}
        />

        <DrawerFooter>
          <Divider sx={{ width: '100%' }} />
          <CustomTooltip title="Contactanos" arrowLocation="right">
            <StyledListItem
              button
              key=""
              selected={location.pathname === '/ayuda'}
              onClick={() => setShowContactAlert(true)}
            >
              <Box width="43px" borderRadius="4px" overflow="hidden" my="2px">
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <MailOutlineTwoTone sx={{ color: colors.tertiaryBase }} />
                </ListItemIcon>
              </Box>
            </StyledListItem>
          </CustomTooltip>
        </DrawerFooter>
      </Box>
    </Slide>
  );
});

export default MenuMobile;
