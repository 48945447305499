/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { LazySolicitudEnviada, LazySolicitudFinalizada } from '../lazyAgree';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { ProcedureInProcess, ProcedureStatus } from 'models/procedure';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { useAgreeContext } from '../agreeUseContext';

export const RoutesWithProcedureInitiated = () => {
  const {
    state: { routeState },
    dispatch,
  } = useAgreeContext();
  const { code, alias } = useParams();
  const [procedure, setProcedure] = useState<ProcedureInProcess | null>(null);
  const [loading, setLoading] = useState(true);
  const { procedureHeaderStore } = useStores();
  const getProcedureInfo = useCallback(async () => {
    if (code && alias) {
      const response = await api.procedure.getProcedureDetail(
        'financiamiento',
        code,
      );
      setProcedure(response);

      dispatch({ type: 'set-routeState', value: response.estado });
      dispatch({ type: 'set-stateProcedure', value: response.estado });

      const logos = [];

      if (response.producto.logo) {
        logos.push(response.producto.logo);
      }

      if (response.producto.entidad.logo) {
        logos.push(response.producto.entidad.logo);
      }

      const procedureInfo = {
        alias: response.producto.alias as string,
        productName: response.producto.nombre as string,
        markdownTemplate: response.producto.markdownTemplate as string,
        headerImg: response.producto.imgHeader as string,
        images: logos as string[],
        status:
          routeState === 'solicitud_enviada' || response.isAprobado === null
            ? ('ongoing' as ProcedureStatus)
            : response.isAprobado
            ? 'finished'
            : ('rejected' as ProcedureStatus),
      };
      procedureHeaderStore.setProcedureInfo(procedureInfo);

      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getProcedureInfo();
  }, [getProcedureInfo]);

  if (procedure && routeState) {
    if (routeState === 'solicitud_enviada')
      return (
        <ProcedureStepSuspense>
          <LazySolicitudEnviada procedure={procedure} />
        </ProcedureStepSuspense>
      );
    if (routeState === 'solicitud_finalizada')
      return (
        <ProcedureStepSuspense>
          <LazySolicitudFinalizada procedure={procedure} loading={loading} />
        </ProcedureStepSuspense>
      );
  }
  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
