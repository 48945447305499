/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut, cast } from 'mobx-state-tree';
import api from 'services/api/api';
import { CuitsRoot, CuitsRootTypes } from './cuitModel';

type Estado = CuitsRoot['estado'];

export const CuitsModel = types
  .model('Cuits')
  .props({
    cuits: types.array(CuitsRootTypes),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    cuitsByEstados(estadosAllowed: Estado[] = ['aprobado']) {
      return self.cuits.filter((cuit) => estadosAllowed.includes(cuit.estado));
    },
    contactByCUIT(cuit: string) {
      const cuitEntity = self.cuits.find((item) => item.cuit === cuit);
      return cuitEntity?.contacto ?? null;
    },
    cuitEntityByCuit(cuit: string) {
      const cuitEntity = self.cuits.find((item) => item.cuit === cuit);
      return cuitEntity ?? null;
    },
    cuitEntityById(id: number) {
      const cuitEntity = self.cuits.find((item) => item.id === id);
      return cuitEntity ?? null;
    },
    cuitsByTercero({
      estadosAllowed,
      tercero,
    }: {
      estadosAllowed?: Estado[];
      tercero: 'daruma' | 'argenpymes';
    }) {
      return self.cuits.filter(
        (cuit) =>
          tercero in cuit.terceros &&
          cuit.terceros[tercero] !== undefined &&
          (estadosAllowed || []).includes(cuit.estado),
      );
    },
    activeCuitsDaruma(estadosAllowed: Estado[] = ['aprobado']) {
      return self.cuits.filter(
        (cuit) =>
          cuit.terceros.daruma?.estado === 'activo' &&
          estadosAllowed.includes(cuit.estado),
      );
    },
    noActiveCuitsArgenpymes(estadosAllowed: Estado[] = ['aprobado']) {
      return self.cuits.filter(
        (cuit) =>
          cuit.terceros.argenpymes?.estado === 'no_activo' &&
          estadosAllowed.includes(cuit.estado),
      );
    },
    /**
     * Devuelve los cuits que no tienen daruma ni argenpymes
     * MobX lee un objeto vacío pero en realidad es un objeto con valores undefined
     * Esto es por el uso de `maybe` en el modelo.
     */
    cuitsWithoutTerceros(estadosAllowed: Estado[] = ['aprobado']) {
      return self.cuits.filter(
        (cuit) =>
          !cuit.terceros.daruma &&
          !cuit.terceros.argenpymes &&
          estadosAllowed.includes(cuit.estado),
      );
    },
  }))
  .actions((self) => {
    function setCuits(cuits: CuitsRoot[]) {
      self.cuits = cast(cuits);
    }
    function setLoading(isloading: boolean) {
      self.loading = isloading;
    }
    return {
      setCuits,
      setLoading,
    };
  })
  .actions((self) => ({
    getCuits: async () => {
      self.setLoading(true);
      const request = await api.me.getCurrentAccountCUITs(true);
      if (!request.kind) {
        self.setCuits(request.data);
      }
      self.setLoading(false);
    },
  }));

type CuitsModelType = Instance<typeof CuitsModel>;
export type Cuits = CuitsModelType;
type CuitsSnapshotType = SnapshotOut<typeof CuitsModel>;
export type CuitsSnapshot = CuitsSnapshotType;
export const createCuitsDefaultModel = () =>
  types.optional(CuitsModel, { loading: false });
