import StepProcedureContainer from 'components/common/cards/stepProcedure';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { LazyReportResult } from '../lazyHuellaDeCarbono';

export const ReportResult = () => {
  return (
    <ProcedureStepSuspense>
      <StepProcedureContainer>
        <LazyReportResult />
      </StepProcedureContainer>
    </ProcedureStepSuspense>
  );
};