const colors = {
  primaryBase: '#6CC72B',
  primaryMedium: '#89BB65',
  primaryHigh: '#E6F5DD',
  secondaryBase: '#F98A3C',
  secondaryMedium: '#E4A04E',
  secondaryHigh: '#FFF5DB',
  tertiaryBase: '#4B4846',
  tertiaryLow: '#282422',
  tertiaryMedium: '#928176',
  tertiaryIntermediate: '#CEC6C1',
  tertiaryHigh: '#F5F1ED',
  complementaryGreen: '#48BB88',
  complementaryYellow: '#FFBB4E',
  complementaryYellowLight: '#FFBB4E1A',
  complementaryError: '#FF3C00',
  complementaryErrorBg: '#FF3C001A',
  black: '#333333',
  white: '#FFFFFF',
  red: '#FF0000',
  transparent: 'transparent',
};
export default colors;
