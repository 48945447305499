import React from 'react';
import { Box } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import colors from 'theme/colors';

interface OptionsBtnProps {
  onOpen: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const OptionsBtn: React.FC<OptionsBtnProps> = (props) => {
  const { onOpen } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="100%"
      width="26px"
      height="26px"
      sx={{
        cursor: 'pointer',
        background: colors.tertiaryHigh,
      }}
      onClick={onOpen}
    >
      <ArrowDropDownRoundedIcon sx={{ color: colors.tertiaryLow }} />
    </Box>
  );
};

export default OptionsBtn;
