/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const CertificationStoreModel = types
  .model('CertificationStore')
  .props({
    path: types.string,
    name: types.string,
  })
  .actions((self) => ({
    setCertification: (path: string, name: string) => {
      self.path = path;
      self.name = name;
    },
    setPath: (path: string) => {
      self.path = path;
    },
  }));

type CertificationStoreModelType = Instance<typeof CertificationStoreModel>;
export type CertificationStore = CertificationStoreModelType;
type CertificationStoreSnapshotType = SnapshotOut<
  typeof CertificationStoreModel
>;
export type CertificationStoreSnapshot = CertificationStoreSnapshotType;
export const createCertificationStoreDefaultModel = () =>
  types.optional(CertificationStoreModel, { path: '', name: '' });
