/* eslint-disable @typescript-eslint/no-explicit-any */
const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ENVIRONMENT || 'development',
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
  ignoredMessages: ['ResizeObserver loop limit exceeded'],
  checkIgnore: (isUncaught: any, args: any, payload: any) =>
    payload?.err?.status === 401,
};

export default rollbarConfig;
