import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CardMedia, Stack } from '@mui/material';
import LinkBtn from 'components/common/buttons/linkBtn';
import { H6, P3, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import Button from 'components/common/buttons/button';
import { ProcedureAvailable } from 'models/procedure';
import { useStores } from 'store/root-store/root-store-context';
import {
  CardMediaHeaderStyle,
  CardMediaProductStyle,
  ProductCard,
  ProductCardContent,
} from './index.styled';
import UploadCompanyModal from 'components/common/modals/uploadCompanyModal';
import { isProcedureEqualToHuellaDeCarbono } from 'utils/common';

interface ProcedureAvailableCardProps {
  procedure: ProcedureAvailable;
  startProcedureURL: string;
  textBtn: string;
  callback: (comeFrom?: string) => void;
  setProcedureParams?: () => void;
  width: number;
}

const ProcedureAvailableCard = ({
  procedure,
  startProcedureURL,
  textBtn,
  callback,
  setProcedureParams,
  width,
}: ProcedureAvailableCardProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const images = [procedure.logo ?? procedure.entidad.logo];
  const {
    sessionStore: { isExternal },
    cuitsStore: { cuitsByEstados },
  } = useStores();
  const cuits = cuitsByEstados();
  return (
    <>
      {(isExternal || cuits?.length <= 0) && (
        <UploadCompanyModal
          imgHeader={procedure.imgHeader}
          redirectTo="/micuenta"
          logos={(images || ['']) as string[]}
          title={procedure.nombre || ''}
          subtitle={procedure.entidad.nombre}
          open={open}
          onClose={() => setOpen(false)}
          onlyRead={false}
          width={width}
        />
      )}
      <ProductCard
        elevation={1}
        onClick={() => {
          if (width < 1024) {
            callback('product_card');
            if (setProcedureParams) {
              setProcedureParams();
            }
          }
        }}
      >
        <ProductCardContent>
          <Box position="relative" width="100%" height="55.4px">
            <CardMedia
              sx={CardMediaHeaderStyle}
              component="img"
              alt="product-header"
              loading="lazy"
              image={`${import.meta.env.VITE_API_BASE_URL}${
                procedure.imgHeader
              }`}
            />
            <Stack
              direction="row"
              alignItems="center"
              height="55.4px"
              gap="6px"
              ml="16.5px"
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                width="36px"
                height="36px"
                zIndex={2}
              >
                <CardMedia
                  sx={CardMediaProductStyle}
                  component="img"
                  alt="product"
                  loading="lazy"
                  image={`${import.meta.env.VITE_API_BASE_URL}${
                    procedure.logo ?? procedure.entidad.logo
                  }`}
                />
              </Stack>
              <P3 style={{ color: colors.white, fontWeight: 700, zIndex: 2 }}>
                {procedure.entidad.nombre}
              </P3>
            </Stack>
          </Box>

          <Stack
            justifyContent="space-between"
            gap="8px"
            px="16px"
            py="10px"
            height="100%"
          >
            <Stack>
              <P3 style={{ lineHeight: '125%' }}>{procedure.nombre}</P3>
              <P4
                style={{
                  marginTop: '8px',
                  color: colors.tertiaryMedium,
                  lineHeight: '125%',
                }}
              >
                {procedure.leyenda}
              </P4>
            </Stack>

            <Stack
              direction={{
                xs: 'column',
                laptop: 'row',
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display={{
                  xs: 'none',
                  laptop: 'block',
                }}
              >
                <LinkBtn onClick={() => callback('conoce_mas')}>
                  <H6
                    style={{
                      color: colors.primaryBase,
                      lineHeight: '125%',
                      textDecoration: 'underline',
                    }}
                  >
                    Conocé más
                  </H6>
                </LinkBtn>
              </Box>
              <Box
                display={{
                  xs: 'none',
                  laptop: 'block',
                }}
                id={`iniciar_solicitud_${procedure.alias}`}
              >
                <Button
                  variant="contained"
                  preset="normal"
                  size="small"
                  onClick={() => {
                    if (cuits.length <= 0) {
                      setOpen(true);
                    } else if (
                      isProcedureEqualToHuellaDeCarbono(procedure) &&
                      isExternal
                    ) {
                      callback('iniciar_solicitud');
                    } else {
                      navigate(startProcedureURL);
                    }
                  }}
                  text={textBtn}
                  fullWidth
                />
              </Box>
            </Stack>
          </Stack>
        </ProductCardContent>
      </ProductCard>
    </>
  );
};

export default ProcedureAvailableCard;
