import React from 'react';
import { Box } from '@mui/material';
import { CapsMedium, P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { IDatosContacto } from 'models/models.daruma';

export const DatosDeContacto: React.FC<IDatosContacto> = ({
  nombre,
  apellido,
  telefono,
  email,
}) => {
  return (
    <Box width="50%">
      <CapsMedium color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
        DATOS DEL CONTACTO
      </CapsMedium>
      <P3 color={colors.tertiaryBase}>{`${nombre} ${apellido}`}</P3>
      <P3 color={colors.tertiaryBase}>Teléfono: {telefono}</P3>
      <P3 color={colors.tertiaryBase}>Email: {email}</P3>
    </Box>
  );
};
