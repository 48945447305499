import React, { useEffect, useState } from 'react';
import { P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { StyledTooltip } from './index.styled';
import { SxProps } from '@mui/material';

export interface ICustomTooltip {
  title: string;
  subtitle?: string;
  sx?: SxProps;
  controlled?: boolean;
  openControlled?: boolean;
  openStatus?: (value: boolean) => void;
  children: React.ReactElement;
  arrowLocation:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
}

const CustomTooltip: React.FC<ICustomTooltip> = ({
  title,
  subtitle,
  sx,
  controlled = false,
  openControlled = false,
  openStatus,
  arrowLocation,
  children,
}) => {
  const [open, setOpen] = useState<boolean | undefined>(
    controlled || undefined,
  );

  const handleMouseEnter = () => {
    setOpen(true);
    openStatus?.(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    openStatus?.(false);
  };

  useEffect(() => {
    if (controlled && openControlled !== undefined) {
      setOpen(openControlled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openControlled]);

  return (
    <StyledTooltip
      sx={sx}
      arrow
      onMouseEnter={() => controlled && handleMouseEnter()}
      onMouseLeave={() => controlled && handleMouseLeave()}
      placement={arrowLocation}
      open={open}
      title={
        subtitle ? (
          <>
            <P4 color={colors.white}>{title}</P4>
            <P4 color={colors.white}>{subtitle}</P4>
          </>
        ) : (
          <P4
            color={colors.white}
            style={{
              textAlign: 'center',
            }}
          >
            {title}
          </P4>
        )
      }
      PopperProps={{
        disablePortal: true,
      }}
    >
      {children}
    </StyledTooltip>
  );
};

export default CustomTooltip;
