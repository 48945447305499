import { useState } from 'react';
import { Box } from '@mui/material';
import { AuthContainer } from '../index.styled';
import { SignUp } from 'screens/portal/crear-cuenta/steps/signUp';
import { TransitionContainer } from 'components/common/Containers/TransitionContainer';
import { ConfirmacionEmail } from './steps/confirmacionEmail';
import { useHandleSteps } from 'components/common/Containers/useHandleSteps';

export const CrearCuenta = () => {
  const { activeStep, direction, handleNextStep, handlePrevStep } =
    useHandleSteps();

  const [userCredentials, setUserCredentials] = useState<{
    email: string;
    password: string;
    token: string;
  }>({
    email: '',
    password: '',
    token: '',
  });

  const handleUserCredentials = (credentials: {
    email: string;
    password: string;
    token: string;
  }) => {
    setUserCredentials({
      email: credentials.email,
      password: credentials.password,
      token: credentials.token,
    });

    handleNextStep();
  };

  const handlePrevItem = () => {
    handlePrevStep();
  };

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <TransitionContainer
          steps={[
            <SignUp setUserCredentials={handleUserCredentials} />,
            <ConfirmacionEmail userCredentials={userCredentials} />,
          ]}
          activeStep={activeStep}
          direction={direction}
          handlePrevItem={handlePrevItem}
        />
      </Box>
    </AuthContainer>
  );
};
