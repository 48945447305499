/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'apisauce';
import { State } from 'store/fields-store/fieldsModel';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

export class MeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMyInformation(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/me`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateMyInformation(userInformation: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/agrology/me`,
        userInformation,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyAccounts(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/me/cuentas`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyAccount(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/me/cuenta`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCurrentAccountCUITs(getAll?: boolean): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `agrology/me/cuenta/cuits${getAll ? '?status=all' : ''}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async setCurrentAccount(account: { cuentaId: number }): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/agrology/me/cuentas/switch`,
        account,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFields(): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.get(`/agrology/me/campos/`);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFieldsByCUIT(cuit: string): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.get(`/agrology/me/campos/?cuit=${cuit}`);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFieldsCompletedByCUIT(cuit: string): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.get(
          `/agrology/me/campos/?cuit=${cuit}&is_not_complete=0`,
        );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFieldsNotCompletedByCUIT(cuit: string): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.get(
          `/agrology/me/campos/?cuit=${cuit}&is_not_complete=1`,
        );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getFieldByCuitAndAlias(aliasProducto: string, cuit: string) {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/campos/?alias_producto=${aliasProducto}&cuit=${cuit}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getFieldByCuitAliasAndIdCrop(
    aliasProducto: string,
    cuit: string,
    idEspecie: string,
  ) {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/campos/?alias_producto=${aliasProducto}&cuit=${cuit}&especies_ids=${idEspecie}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateField(
    fieldId: number,
    values: Record<string, any>,
  ): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.patch(`/agrology/campos/${fieldId}`, values);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteField(fieldId: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `/agrology/campos/${fieldId}`,
      );
      if (
        !response.ok &&
        response.data.detail &&
        response.data.detail !== 'El establecimiento tiene tramites asociados'
      ) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyTraces(finished: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/certificacion?finalizadas=${finished}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteUser(id: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.delete(
        `/agrology/me/cuenta/usuarios/${id}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFieldsLots(
    alias: string,
    establecimientosId: number[],
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/cultivos/?producto_alias=${alias}&establecimientos_ids=${establecimientosId.join(
          ',',
        )}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getLotById(lotId: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/lotes/${lotId}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteLote(id: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `/agrology/lotes/${id}`,
      );
      if (
        !response.ok &&
        response.data.detail &&
        response.data.detail !== 'El lote tiene tramites asociados'
      ) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getLotsByFieldId(establecimientoId: number): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/lotes/?establecimiento_id=${establecimientoId}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * Get lots by field id for Huella de Carbono HuellaDeCarbono
   */
  async getLotsHCByFieldId(
    alias: string,
    id: number,
    especiesIds: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/lotes/?alias_producto=${alias}&establecimiento_id=${id}&especies_ids=${especiesIds}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async patchLots(lotes: { id: number; poligono: number[][] }[]): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/agrology/me/lotes/`,
        {
          lotes,
        },
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postLote(lote: { nombre: string; poligono: number[][] }): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/agrology/me/lotes/`,
        lote,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createField(field: any): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/agrology/campos/',
        field,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postNewCompany(form: any): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${this.api.token}`,
    };

    try {
      const response: ApiResponse<never> =
        await this.api.apisauce.axiosInstance.post(
          `/agrology/me/cuenta/cuit`,
          form,
          { headers },
        );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { error: e };
    }
  }

  async deleteCompany(idCuentaActual: number | undefined): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.delete(
        `/agrology/me/cuenta/cuit/${idCuentaActual}`,
      );
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateCompany(
    form: any,
    idCuentaActual: number | undefined,
  ): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${this.api.token}`,
    };

    try {
      const response: ApiResponse<never> =
        await this.api.apisauce.axiosInstance.patch(
          `/agrology/me/cuenta/cuit/${idCuentaActual}`,
          form,
          { headers },
        );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async patchEspacioDeTrabajo(dominio: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.patch(
        `/agrology/me/cuenta`,
        {
          dominio,
        },
      );
      return response;
    } catch (e) {
      return { error: e };
    }
  }

  /**
   * Get cultivos for data table in Cultivos screen
   */
  async cultivos(query: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/lista_cultivos/?${query}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * Get cultivos catalog for data table in Cultivos screen
   */
  async catalogoCultivos(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/catalogo_cultivos/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * Get labores catalog for data table in OTs screen
   */
  async catalogoLabores(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/catalogo_labores/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * Get current campaign
   */
  async campanaActual(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/campana_actual/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * Get labores for data table in Ordenes de Trabajo screen
   */
  async labores(query: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/me/labores/?${query}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return { data: [] };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postToGetOnesOilLink(): Promise<any> {
    return this.api.apisauce.post(`/onesoil/`, {});
  }
}
