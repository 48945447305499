import React, { useState } from 'react';
import { Box, Collapse, styled, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChevronRight, EditRounded, DeleteRounded } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { H6, P3, Caps } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { Perimeter } from 'utils/types';
import api from 'services/api/api';
import { useSnackbar } from 'notistack';
import MapDraw from 'assets/icons/extraIcons/map-draw.svg?react';
import { normalizeLong } from 'utils/mapUtils';
import TutorialGif from 'assets/Lotes.gif';
import Button from '../../buttons/button';
import { Strings } from 'constants/strings';
import ModifyFieldNameModal from 'components/common/modals/modifyFieldName';
import { useStores } from 'store/root-store/root-store-context';
import DeleteFieldModal from 'components/common/modals/deleteField';
import QuestionIcon from 'components/common/icon/icons/questionIcon';
import { sleep } from 'utils/common';
import { ErrorCannotDeleteModal } from '../../modals/deleteCompanyError';

interface LotesSelectionProps {
  fieldId: number;
  hiddenFileInput: React.RefObject<HTMLInputElement>;
  perimeters: Perimeter[];
  loadingKML: boolean;
  selectedPerimeter: any;
  setPerimeters: (a: any) => void;
  uploadKML: (e: any) => void;
  setZoomPolygon: (a: any) => void;
  handleDeletePerimeter: () => void;
  setSelectedPerimeter: (e: any) => void;
}

type PayloadPATCH = {
  id: number;
  nombre: string;
  poligono: number[][];
};

type PayloadPOST = {
  establecimiento: number;
  poligono: number[][];
  nombre: string;
};

const HiddenInput = styled('input')({
  display: 'none',
});

const LotesExternals = (props: LotesSelectionProps) => {
  const {
    fieldId,
    hiddenFileInput,
    perimeters,
    uploadKML,
    loadingKML,
    selectedPerimeter,
    setPerimeters,
    handleDeletePerimeter,
    setSelectedPerimeter,
  } = props;
  const {
    mapStore: { setIdPolygonToRemove, setRefreshPolygons },
    fieldsStore: { setChangesSaved, getFieldById, getMyLots },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [showTutorial, setShowTutorial] = useState(false);
  const [openModalModifyName, setOpenModalModifyName] = useState(false);
  const [isOpenDeleteLoteModal, setOpenDeleteLoteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorCompanyModal, setOpenErrorCompanyModal] = useState(false);
  const [entities, setEntities] = useState<any[]>([]);

  const handleModifyName = (newName: string) => {
    const perimeterModified = {
      ...selectedPerimeter,
      name: newName,
    };
    const perimetersModified = perimeters.map((p) =>
      p.id === perimeterModified.id ? perimeterModified : p,
    );
    setPerimeters(perimetersModified);
    setOpenModalModifyName(false);
  };

  const handleSelectPerimeter = (perimeter: Perimeter) => {
    setSelectedPerimeter(perimeter);
    setOpenModalModifyName(true);
  };

  const submitEditedLotes = async (payload: PayloadPATCH[]) => {
    const response = await api.me.patchLots(payload);

    if (!response.kind) {
      enqueueSnackbar(Strings.notifications.fields.updatedLotes, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(Strings.notifications.fields.updatedLotesError, {
        variant: 'error',
      });
    }
  };

  const submitCreatedLotes = async (payload: PayloadPOST[]) => {
    const promises = payload.map((p) => api.me.postLote(p));
    const response = await Promise.all(promises);

    if (response.every((r) => !r.kind)) {
      enqueueSnackbar(Strings.notifications.fields.createdLotes, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(Strings.notifications.fields.createdLotesError, {
        variant: 'error',
      });
    }
  };
  const handleOnSubmit = async () => {
    setIsLoading(true);
    const lotesPatch: PayloadPATCH[] = [];
    const lotesPost: PayloadPOST[] = [];

    perimeters.forEach((perimeter) => {
      if ('idOrigin' in perimeter) {
        const lote = {
          id: perimeter.idOrigin || 0,
          nombre: perimeter.name,
          poligono: perimeter.polygon.map((p) => [p.lat, normalizeLong(p.lng)]),
        };
        lotesPatch.push(lote);
      } else {
        const lote = {
          establecimiento: fieldId,
          poligono: perimeter.polygon.map((p) => [p.lat, normalizeLong(p.lng)]),
          nombre: perimeter.name,
        };
        lotesPost.push({
          ...lote,
          poligono: [...lote.poligono, lote.poligono[0]],
        });
      }
    });

    if (lotesPatch.length > 0) {
      await submitEditedLotes(lotesPatch);
    }

    if (lotesPost.length > 0) {
      await submitCreatedLotes(lotesPost);
    }

    await getMyLots(fieldId);
    await getFieldById(fieldId);
    setRefreshPolygons();
    await sleep(1000);
    setIsLoading(false);
    setChangesSaved(true);
  };

  const handleDeleteLote = async (polygonLeaflet?: Perimeter) => {
    if (selectedPerimeter && selectedPerimeter.idOrigin) {
      const response = await api.me.deleteLote(selectedPerimeter.idOrigin);
      if (!response.kind && !response.detail) {
        handleDeletePerimeter();
        setIdPolygonToRemove(selectedPerimeter.id);
        enqueueSnackbar(`El lote ${selectedPerimeter.name} fue eliminado.`, {
          variant: 'success',
        });
      }

      if (!response.kind && response.detail) {
        enqueueSnackbar(
          `El lote ${selectedPerimeter.name} no puede ser eliminada porque se encuentra registrada en tramites.`,
          {
            variant: 'error',
          },
        );
      }
      setOpenDeleteLoteModal(false);
    }
    if (polygonLeaflet) {
      setPerimeters((prev: Perimeter[]) =>
        prev.filter((p) => p.id !== polygonLeaflet.id),
      );
      setIdPolygonToRemove(polygonLeaflet.id);
      enqueueSnackbar(`El lote ${polygonLeaflet.name} fue eliminado.`, {
        variant: 'success',
      });
    }
  };

  const fetchEntities = async (idOrigin: number) => {
    const res = await api.procedure.getassociatedAccounts('lote', idOrigin);
    setEntities(res);
    return res;
  };

  const handleModal = async (idOrigin: number) => {
    const res = await fetchEntities(idOrigin);
    res.length > 0
      ? setOpenErrorCompanyModal(true)
      : setOpenDeleteLoteModal(true);
  };

  return (
    <Stack
      zIndex={10}
      sx={{
        width: {
          xs: '100%',
          md: '308px',
        },
        height: {
          xs: 'calc(100vh - 266px)',
          md: 'calc(100vh - 124px)',
        },
      }}
      justifyContent="space-between"
    >
      {selectedPerimeter && (
        <ModifyFieldNameModal
          open={openModalModifyName}
          name={selectedPerimeter.name || ''}
          handleClose={() => setOpenModalModifyName(false)}
          handleChangeName={(newName) => handleModifyName(newName)}
        />
      )}
      <DeleteFieldModal
        title="¿Eliminar Lote?"
        description="Estas por eliminar el lote"
        name={selectedPerimeter?.name || ''}
        open={isOpenDeleteLoteModal}
        handleClose={() => setOpenDeleteLoteModal(false)}
        handleDelete={() => handleDeleteLote()}
        isLoading={false}
      />
      {entities.length > 0 ? (
        <ErrorCannotDeleteModal
          title="Error al eliminar el lote"
          description={
            <Box width="300px">
              <P3 color={colors.tertiaryMedium}>
                El lote <b>{selectedPerimeter?.name}</b>, actualmente no puede
                ser eliminado porque se encuentra registrado en:
              </P3>
            </Box>
          }
          open={openErrorCompanyModal}
          associatedAccounts={entities}
          handleClose={() => setOpenErrorCompanyModal(false)}
        />
      ) : null}
      <Stack>
        <HiddenInput
          accept=".kml,.kmz"
          id="contained-button-file"
          multiple
          ref={hiddenFileInput}
          type="file"
          onChange={uploadKML}
        />
        <Collapse
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
          in={showTutorial || perimeters.length === 0}
        >
          <Box m="16px 12px 0px 16px">
            <img
              src={TutorialGif}
              alt="tutorial-lotes-draw"
              style={{
                width: '100%',
              }}
              loading="lazy"
            />
            <P3 color={colors.tertiaryBase} style={{ marginBottom: '16px' }}>
              Dibujá un polígono en el mapa y asignale un nombre
            </P3>
            <Box display={perimeters.length > 0 ? 'block' : 'none'}>
              <Button
                text="OK, entendido"
                variant="outlined"
                preset="normal"
                size="small"
                onClick={() => setShowTutorial(false)}
              />
            </Box>
          </Box>
        </Collapse>

        {perimeters.length === 0 && (
          <P3
            sx={{
              paddingX: '16px',
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          >
            Dibujá un polígono en el mapa y asignale un nombre
            <span style={{ position: 'relative', top: '3px', left: '3px' }}>
              <QuestionIcon />
            </span>
          </P3>
        )}

        <Collapse in={!showTutorial && perimeters.length > 0}>
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            <P3
              color={colors.tertiaryBase}
              style={{ margin: '16px 12px 0px 16px' }}
            >
              Delimitá en el mapa
              <MapDraw
                style={{
                  verticalAlign: 'middle',
                  margin: '0 5px 2px 5px',
                }}
              />
              o importá el polígono de cada lote de tu campo
            </P3>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
              }}
              m="9px 12px 0 16px"
              width="calc(100% - 12px - 16px)"
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="3px"
                onClick={() => setShowTutorial(true)}
                sx={{ cursor: 'pointer' }}
              >
                <Caps
                  color={colors.primaryBase}
                  style={{ textDecoration: 'underline' }}
                >
                  MÁS INFO
                </Caps>
                <InfoOutlinedIcon
                  sx={{
                    color: colors.primaryBase,
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" mx="16px" mb="8px" gap="8px">
            <H6 color={colors.tertiaryMedium}>Lotes totales</H6>
          </Box>
          <Stack
            alignItems="flex-start"
            width="calc(100% - 32px)"
            height="100%"
            maxHeight={
              perimeters.length === 0
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 328px - 16px)'
            }
            mt="16px"
            mx="16px"
            sx={{ overflowY: 'auto' }}
            className="item-lotes"
          >
            <Stack gap="8px" width="100%">
              {perimeters.map((lot) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    gap="4px"
                    key={lot.id}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      gap="8px"
                    >
                      <Box
                        onClick={() => handleSelectPerimeter(lot)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <EditRounded fontSize="small" />
                      </Box>
                      <Box
                        onClick={() => {
                          setSelectedPerimeter(lot);
                          if (lot.idOrigin) {
                            handleModal(lot.idOrigin);
                          } else {
                            handleDeleteLote(lot);
                          }
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <DeleteRounded
                          fontSize="small"
                          sx={{ color: colors.complementaryError }}
                        />
                      </Box>
                      <P3 color={colors.tertiaryLow}>{lot.name}</P3>
                    </Stack>
                    <Box
                      width="28px"
                      height="28px"
                      borderRadius="8px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      border={`1px solid ${lot.color}`}
                      bgcolor={`${lot.color}`}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Collapse>
      </Stack>

      <Stack
        width="calc(100% - 32px)"
        alignSelf="center"
        alignItems="center"
        my="16px"
        padding="0 16px"
        gap="10px"
      >
        <Button
          variant="outlined"
          preset="normal"
          size="small"
          fullWidth
          loading={loadingKML}
          onClick={() => {
            if (hiddenFileInput && hiddenFileInput.current && !loadingKML)
              hiddenFileInput.current.click();
          }}
          text="Importar polígonos"
        />
        <Button
          variant="contained"
          preset="normal"
          size="small"
          fullWidth
          onClick={handleOnSubmit}
          text="Guardar cambios"
          loading={isLoading}
          disabled={isLoading}
          endIcon={<ChevronRight />}
        />
      </Stack>
    </Stack>
  );
};

export default observer(LotesExternals);
