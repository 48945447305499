import { Paper, styled } from '@mui/material';

export const NoFieldsContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '8px',

  '.title-container': {
    fontWeight: 350,
    fontSize: '24px',
  },

  '.text-container': {
    fontWeight: 350,
    fontSize: '18px',
  },

  [theme.breakpoints.up('tabletS')]: {
    '.title-container': {
      fontWeight: 400,
      fontSize: '32px',
    },

    '.text-container': {
      fontWeight: 500,
      fontSize: '20px',
    },
  },
}));
