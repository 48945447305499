import { lazy } from 'react';

export const LazySeleccionarEmpresaScreen = lazy(
  () => import('./SeleccionarEmpresa'),
);
export const LazyCamposScreen = lazy(() => import('./SeleccionarCampos'));
export const LazyCultivosScreen = lazy(() => import('./RevisarCultivos'));
export const LazySolicitudEnviada = lazy(() => import('./EnvioSolicitud'));
export const LazySolicitudFinalizada = lazy(
  () => import('./SolicitudFinalizada'),
);
export const LazyReportResult = lazy(() => import('./ResultadoDelReporte'));