import { Box, Card, CardContent, styled } from '@mui/material';
import colors from 'theme/colors';

export const TraceCard = styled(Card)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
}));

export const TraceCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '10px 16px',
  gap: '10px',
  '&:last-child': {
    paddingBottom: '10px',
  },
}));

export const CardMediaProductStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: colors.white,
  zIndex: 2,
};

export const BadgeTime = styled(Box)(() => ({
  display: 'flex',
  padding: '4px 12px',
  alignItems: 'center',
  borderRadius: '100px',
  backgroundColor: colors.tertiaryHigh,
  width: 'fit-content',
}));
