/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import ContentCarousel from 'components/layouts/contentCarousel';
import { Carrusel } from 'components/common/banners/Carrousel';
import { ExternalUserScreen } from './ExternalScreen';
import { ModalForm } from '../../components/common/modals/ModalForm';
import { LayersTwo } from '../../components/common/icon/icons/layersTwo';
import colors from '../../theme/colors';
import { WorkSpaceForm } from 'components/common/forms/espacioDeTrabajoForm/index';
import EspacioTrabajoImage from 'assets/Menu.svg';
import { useStores } from '../../store/root-store/root-store-context';

const HomeScreen: React.FC = observer(() => {
  const {
    sessionStore: { currentUser },
  } = useStores();
  const isLtTablet = useMediaQuery('(max-width: 767px)');
  const [open, setOpen] = useState(false);
  const testEspacioDeTrabajo = /\.agrology\.com/;

  const isThereEspacioDeTrabjo = !testEspacioDeTrabajo.test(
    currentUser?.misCuentas?.[0].dominio as string,
  );

  const handleModal = () => {
    if (!isThereEspacioDeTrabjo) return setOpen(true);
    return setOpen(false);
  };

  useEffect(() => {
    handleModal();
  }, []);

  return (
    <ContentCarousel
      carousel={<Carrusel />}
      hOffset={isLtTablet ? '0px' : '48px'}
    >
      <ModalForm
        open={open}
        handleClose={() => {}}
        title="Cuenta Agrology"
        description="Unificá todas tus empresas en una sola cuenta para acceder fácilmente a una amplia gama de trámites y disfrutar de beneficios exclusivos."
        formChildren={
          <WorkSpaceForm
            openModalAddNewCompany={() => {}}
            closeModalEspacioTrabajo={() => setOpen(false)}
          />
        }
        image={EspacioTrabajoImage as string}
        iconChildren={
          <LayersTwo
            width="40px"
            height="40px"
            color={colors.complementaryGreen}
          />
        }
      />
      <Box width="100%" maxWidth="1440px" mx="16px">
        <ExternalUserScreen />
      </Box>
    </ContentCarousel>
  );
});

export default HomeScreen;
