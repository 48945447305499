const prohibitedProcedures = ['sancor_multiriesgo', 'capital_trabajo_2022'];

export const isProcessingAllowed = (aliasSelected: string) =>
  prohibitedProcedures.includes(aliasSelected);

export const aliasPermitidos = [
  'capital_trabajo_2022',
  'combo_argenpymes',
  'alta_agree',
  'huella_carbono',
  'huella_carbono_bulmar',
];