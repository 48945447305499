import { Box } from '@mui/material';
import StepProcedureContainer from 'components/common/cards/stepProcedure';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { useHuellaDeCarbonoContext } from 'contexts/HuellaDeCarbono/useHuellaDeCarbonoContext';
import {
  LazyCamposScreen,
  LazyCultivosScreen,
  LazySolicitudEnviada,
  LazySolicitudFinalizada,
} from '../lazyHuellaDeCarbono';
import api from 'services/api/api';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function RoutesWithProcedure() {
  const {
    state: { stateProcedure },
    dispatch,
  } = useHuellaDeCarbonoContext();
  const { code, alias } = useParams();

  const getProcedureTrace = async () => {
    if (code && alias) {
      const traceResponse = await api.traces.getTrace(code);
      dispatch({
        type: 'set-stateProcedure',
        value: traceResponse.estado,
      });
    }
  };

  useEffect(() => {
    getProcedureTrace();
  }, []);

  if (stateProcedure === 'seleccion_campos')
    return (
      <ProcedureStepSuspense>
        <LazyCamposScreen />
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'revision_cultivos')
    return (
      <ProcedureStepSuspense>
        <LazyCultivosScreen />
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'envio_solicitud')
    return (
      <ProcedureStepSuspense>
        <LazySolicitudEnviada />
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'solicitud_procesada')
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <LazySolicitudEnviada />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'solicitud_terminada')
    return (
      <ProcedureStepSuspense>
        <LazySolicitudFinalizada />
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
}