import styled from 'styled-components';
import { Box, Dialog, DialogContent, DialogTitle, Paper } from '@mui/material';
import colors from 'theme/colors';

export const ModalContainer = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 827px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1023px) {
    width: 94vw;
  }
`;

interface DialogContainerProps {
  daruma?: boolean;
}

export const DialogContainer = styled(Dialog)<DialogContainerProps>`
  .MuiDialog-paper {
    width: ${(props) =>
      props.daruma ? 'calc(100% - 32px) !important' : '100%'};
    max-width: ${(props) =>
      props.daruma ? '827px' : 'calc(100% - 32px) !important'};
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
    @media (min-width: 1024px) {
      width: 827px;
    }
  }

  .MuiDialog-paper {
    margin: 16px;
    border-radius: 8px;
  }
`;

export const DialogContainerUploadCompany = styled(
  Dialog,
)<DialogContainerProps>`
  .MuiDialog-paper {
    width: ${(props) =>
      props.daruma ? 'calc(100% - 32px) !important' : '100%'};
    max-width: ${(props) =>
      props.daruma ? '827px' : 'calc(100% - 32px) !important'};
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
    @media (min-width: 1024px) {
      width: 500px;
    }
  }

  .MuiDialog-paper {
    margin: 16px;
    border-radius: 8px;
  }
`;

export const DialogContainerCommonProcedureModal = styled(
  Dialog,
)<DialogContainerProps>`
  .MuiDialog-paper {
    width: ${(props) =>
      props.daruma ? 'calc(100% - 32px) !important' : '100%'};
    max-width: ${(props) =>
      props.daruma ? '827px' : 'calc(100% - 32px) !important'};
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
    @media (min-width: 1024px) {
      width: 564px;
    }
  }

  .MuiDialog-paper {
    margin: 16px;
    border-radius: 8px;
  }
`;

export const DialogContainerHuellaCard = styled(Dialog)<DialogContainerProps>`
  .MuiDialog-paper {
    width: ${(props) =>
      props.daruma ? 'calc(100% - 32px) !important' : '100%'};
    max-width: ${(props) =>
      props.daruma ? '827px' : 'calc(100% - 32px) !important'};
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
    @media (min-width: 1024px) {
      width: 1080px;
    }
  }

  .MuiDialog-paper {
    margin: 16px;
    border-radius: 8px;
  }
`;

export const ModalHeader = styled(Box)`
  background-color: ${colors.tertiaryHigh};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  text-align: center;
  padding: 24px 44px;
`;

export const ModalHeaderDaruma = styled(Box)`
  background-color: ${colors.tertiaryHigh};
  height: 24vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  text-align: center;

  @media (min-width: 768px) {
    min-height: 100px;
    height: 18vh;
  }
`;

export const DialogHeader = styled(DialogTitle)`
  background-color: ${colors.tertiaryHigh};
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  text-align: center;
`;

const ModalBodyBase = styled(Box)`
  background-color: #ffffff;
`;

const DialogBodyBase = styled(DialogContent)`
  background-color: #ffffff;
`;

export const ModalBody = styled(ModalBodyBase)`
  padding: 16px;
  max-height: calc(97vh - 24vh - 32px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    // Chrome, Safari, Opera
    display: none;
  }
  -ms-overflow-style: none; // IE, Edge
  scrollbar-width: none; // Firefox

  @media (min-width: 425px) {
    padding: 16px 30px;
  }

  @media (min-width: 500px) {
    padding: 16px 80px;
  }

  @media (min-width: 768px) {
    padding: 32px 120px;
    max-height: calc(97vh - 18vh - 64px);
  }

  @media (min-width: 1024px) {
    padding: 30px 163px;
    max-height: calc(57vh - 30px);
  }
`;

interface DialogBodyProps {
  daruma?: boolean;
  padding?: string;
}

export const DialogBody = styled(DialogBodyBase)<DialogBodyProps>`
  &.MuiDialogContent-root {
    padding: ${(props) =>
      props.daruma ? '20px 40px' : props.padding || '16px'} !important;

    @media (min-width: 425px) {
      padding: ${({ padding }) => padding || '16px 30px'} !important;
    }

    @media (min-width: 500px) {
      padding: ${({ padding }) => padding || '16px 80px'} !important;
    }

    @media (min-width: 768px) {
      padding: ${(props) =>
        props.daruma ? '24px 80px' : props.padding || '30px'} !important;
    }
  }
`;

export const ModalBodyWithScroll = styled(ModalBodyBase)`
  /* padding: 16px; */
  max-height: 60vh;
  overflow-y: auto;

  /* @media (min-width: 425px) {
    padding: 16px 30px;
  }

  @media (min-width: 500px) {
    padding: 16px 80px;
  }

  @media (min-width: 768px) {
    padding: 32px 120px;
    max-height: calc(97vh - 18vh - 64px);
  }

  @media (min-width: 1024px) {
    padding: 30px 163px;
    max-height: calc(57vh - 30px);
  } */
`;

/* Modals medianos */
export const ModalContainerMedium = styled(ModalContainer)`
  width: 524px;
  height: min-content;
`;

export const ModalHeaderMedium = styled(ModalHeader)`
  height: min-content;
`;

export const ModalBodyMedium = styled(ModalBody)`
  padding: 24px;
  height: min-content;
`;

/* Modals chicos */
export const ModalContainerSmall = styled(ModalContainer)`
  height: min-content;
`;

export const ModalHeaderSmall = styled(ModalHeader)`
  height: min-content;
`;

export const ModalBodySmall = styled(ModalBodyBase)`
  padding: 16px;
  height: min-content;

  @media screen and (min-width: 768px) {
    padding: 24px 80px 40px 80px;
  }
`;
