import React from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { H6, H4Ellipsis } from 'components/common/typography/styles';
import MapTabs, { MapTabsProps } from 'components/common/map/mapTabs';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'theme/colors';
import CustomTooltip from 'components/common/tooltips';
import { LotPolygon } from 'models/map';
import { StyledMapWrapper } from './index.styled';
import { ChevronLeft } from '@mui/icons-material';

interface DrawerProps extends MapTabsProps {
  children: React.ReactNode;
  open: boolean;
  fieldId: number;
  onClose: () => void;
  fieldName: string;
  lots: LotPolygon[];
  fieldNameLocation: string;
  setLots: (lots: any) => void;
  loadingKML: boolean;
  setZoomPolygon: (a: any) => void;
  setPerimeters: (a: any) => void;
  handleDeletePerimeter: () => void;
}

const MapWrapper: React.FC<DrawerProps> = (props) => {
  const {
    children,
    open,
    fieldId,
    onClose,
    fieldName,
    fieldNameLocation,
    hiddenFileInput,
    uploadKML,
    perimeters,
    selectedPerimeter,
    setSelectedPerimeter,
    isLoading,
    handleSubmit,
    closeDrawer,
    lots,
    loadingKML,
    setLots,
    setZoomPolygon,
    setPerimeters,
    handleDeletePerimeter,
  } = props;
  const width = useMediaQuery('(min-width:767px)');

  return (
    <StyledMapWrapper
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={24}
    >
      {width ? (
        <Box className="drawer-container">
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              right: 0,
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
          <Box width="calc(100% - 308px)">{children}</Box>
          <Box display="flex" flexDirection="column" width="308px">
            <Box className="drawer-header">
              <H6 className="drawer-title">Polígono del campo</H6>
              <CustomTooltip title={fieldName} arrowLocation="top">
                <H4Ellipsis color={colors.tertiaryBase}>{fieldName}</H4Ellipsis>
              </CustomTooltip>
            </Box>
            <MapTabs
              fieldId={fieldId}
              setZoomPolygon={setZoomPolygon}
              loadingKML={loadingKML}
              hiddenFileInput={hiddenFileInput}
              uploadKML={uploadKML}
              perimeters={perimeters}
              setPerimeters={setPerimeters}
              selectedPerimeter={selectedPerimeter}
              setSelectedPerimeter={setSelectedPerimeter}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              closeDrawer={closeDrawer}
              lots={lots}
              setLots={setLots}
              handleDeletePerimeter={handleDeletePerimeter}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box className="drawer-container">
            <Box display="flex" flexDirection="column">
              <Box className="drawer-header">
                <IconButton
                  sx={{
                    color: 'white',
                  }}
                  onClick={() => onClose()}
                >
                  <ChevronLeft />
                </IconButton>
                <H6 className="drawer-title">Polígono del campo</H6>
              </Box>
            </Box>
            <Box height="100vh" width="100%" zIndex={0}>
              {children}
            </Box>
            <MapTabs
              fieldId={fieldId}
              setZoomPolygon={setZoomPolygon}
              fieldName={fieldName}
              fieldNameLocation={fieldNameLocation}
              loadingKML={loadingKML}
              hiddenFileInput={hiddenFileInput}
              uploadKML={uploadKML}
              perimeters={perimeters}
              setPerimeters={setPerimeters}
              selectedPerimeter={selectedPerimeter}
              setSelectedPerimeter={setSelectedPerimeter}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              closeDrawer={closeDrawer}
              lots={lots}
              setLots={setLots}
              handleDeletePerimeter={handleDeletePerimeter}
            />
          </Box>
        </>
      )}
    </StyledMapWrapper>
  );
};

export default MapWrapper;
