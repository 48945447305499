import { styled } from '@mui/material/styles';
import colors from 'theme/colors';

export const Header1 = styled('h1')(() => ({
  fontFamily: 'Gotham Rounded',
  fontSize: '1.125rem',
  fontStyle: 'normal',
  fontWeight: 350,
  lineHeight: '1.125rem',
  letterSpacing: '-0.0225rem',
  color: colors.tertiaryBase,
}));

export const Header2 = styled('h2')(() => ({
  fontFamily: 'Gotham Rounded',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 350,
  lineHeight: '1.25rem',
  letterSpacing: '-0.0175rem',
  color: colors.tertiaryBase,
  marginTop: '20px',
}));

export const Body1 = styled('h3')(() => ({
  fontFamily: 'Work Sans',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.25rem',
  letterSpacing: '-0.01rem',
  color: colors.tertiaryMedium,
  marginBottom: '20px',
}));

export const Body2 = styled('p')(() => ({
  fontFamily: 'Work Sans',
  fontSize: '0.8125rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.01563rem',
  color: colors.tertiaryMedium,
}));

export const Bullet = styled('ul')(() => ({
  fontFamily: 'Work Sans',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.09375rem',
  letterSpacing: '-0.00875rem',
  listStyle: 'none',
  color: colors.tertiaryBase,

  '& li': {
    marginTop: '4px',
  },
}));

export const SmallBullet = styled('ol')(() => ({
  fontFamily: 'Work Sans',
  fontSize: '0.8125rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.01563rem',
  listStyle: 'none',
  color: colors.tertiaryBase,

  '& li > p': {
    margin: 0,
  },
}));
