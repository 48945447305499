import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import TextInput from '../inputs/text';
import Button from '../buttons/button';
import { CreateFieldSchema } from 'utils/schema-validations';
import { Perimeter } from 'utils/types';

enum FieldNames {
  fieldName = 'name',
}

type Props = {
  name: string;
  handleClose: () => void;
  handleChangeName: (name: string) => void;
};

const ModifyFieldNameForm = ({
  name,
  handleClose,
  handleChangeName,
}: Props) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const initialValues = {
    [FieldNames.fieldName]: name || '',
  };

  const putFieldName = async (values: { name: string }) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    handleChangeName(values.name);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={putFieldName}
      validationSchema={CreateFieldSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }

        return (
          <Stack gap="16px" mt="8px">
            <Box>
              <TextInput
                id={FieldNames.fieldName}
                label=""
                disabled={false}
                name={FieldNames.fieldName}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Nuevo Amanecer"
              />
            </Box>
            <Stack
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
              gap={1}
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                preset="normal"
                size="large"
                onClick={handleClose}
                text="Cancelar"
              />
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Modificar"
                disabled={isSubmitting && !!errors}
                loading={isSubmitting}
              />
            </Stack>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default ModifyFieldNameForm;
