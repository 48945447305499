import { styled } from '@mui/material';
import { ContentProps } from 'utils/types';

export const StyledContainer = styled('div')`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  justify-content: flex-end;

  .logo-agrology {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 150px;
    height: 40px;
    z-index: 2;
  }

  @media (min-width: 1024px) {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow-y: hidden;
    .logo-agrology {
      position: absolute;
      top: 24px;
      left: 24px;
      width: 150px;
      height: 40px;
      z-index: 2;
    }
  }

  .spinner-logo {
    position: absolute;
    top: 40%;
    right: calc(80% - 75px);
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    overflow-y: none;
    .logo-agrology {
      position: absolute;
      top: 24px;
      left: calc(50% - 75px);
      width: 150px;
      height: 40px;
      z-index: 2;
    }
    .spinner-logo {
      position: relative;
      width: 100%;
      height: 234px;
      display: flex;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
      right: calc(55% - 75px);
    }
  }
  @media (max-width: 320px) {
    .logo-agrology {
      position: absolute;
      top: 24px;
      left: calc(50% - 75px);
      width: 150px;
      height: 40px;
      z-index: 2;
    }
  }
`;

export const StyledSlider = styled('div')`
  .text-slider {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 82px;
    text-align: center;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-title {
    margin-bottom: 8px;
  }

  .text-parag {
    width: 500px;
  }

  .slider-img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    .slider-img {
      height: 50vh;
      width: 100%;
      object-fit: cover;
    }

    .text-slider {
      display: none;
    }
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  width: 50vw;
  padding: 25px 0;
  overflow-y: scroll;

  @media (max-width: 1023px) {
    width: 100vw;
    height: 100%;
    align-items: flex-start;
    padding: 35px 0;
    overflow-y: hidden;

    .children-wrapper {
      height: 100%;
    }
  }
`;

export const StyledWrapperContent = styled('div')<{
  hOffset: string;
  scroll?: string;
}>`
  display: flex;
  padding: 0 16px;
  justify-content: center;
  max-height: calc(100vh - 48px - ${(props) => props.hOffset});
  overflow-y: ${(props) => (props.scroll ? 'unset' : 'scroll')};
`;

export const StyledWrapperContentCarousel = styled('div')<{ hOffset: string }>(
  ({ theme, hOffset }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: `calc(100vh - 48px - ${hOffset})`,
    overflowY: 'auto',
    padding: '24px',

    [theme.breakpoints.down('tablet')]: {
      padding: 0,
    },
  }),
);

export const StyledContent = styled('div')<ContentProps>(
  ({ alignItems, justifyContent, flexDirection }) => ({
    display: 'flex',
    flexDirection: flexDirection || 'column',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'center',
    width: '100%',
    maxWidth: '1440px',
    margin: '24px 0',
    height: '100%',
  }),
);

export const StyledContentCarousel = styled(StyledContent)<ContentProps>(
  ({ theme }) => ({
    padding: 0,

    [theme.breakpoints.down('tablet')]: {
      width: 'calc(100% - 32px)',
      padding: '0 16px',
    },
  }),
);

export const StyledSliderLogin = styled('div')`
  .text-slider {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 82px;
    text-align: center;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .text-title {
    margin-bottom: 8px;
  }

  .text-parag {
    width: 500px;
  }

  .slider-img {
    height: 100vh;
    width: 50vw;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(75, 72, 70, 0.9) 0%,
        rgba(9, 9, 121, 0) 50%,
        rgba(75, 72, 70, 0.9) 100%
      );
    }
    .slider-img {
      height: 230px;
      width: 100%;
      object-fit: cover;
    }

    .text-slider {
      display: none;
    }
  }

  @media (max-width: 767px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: none;
    }
  }
`;