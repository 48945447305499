/* eslint-disable react/no-children-prop */
import { useEffect, useState } from 'react';
import { Box, Chip, Grid, Paper, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/buttons/button';
import { P3, P4 } from 'components/common/typography/styles';
import UploadCompanyModal from 'components/common/modals/uploadCompanyModal';
import colors from 'theme/colors';
import {
  changeDataByAlias,
  isProcedureEqualToHuellaDeCarbono,
} from 'utils/common';
import { useStores } from 'store/root-store/root-store-context';
import { CardListCommonProcedureModal } from 'screens/certificaciones/huellaDeCarbono/components/CardListCommonProcedureModal';

import CommomModalProcedure from 'components/common/modals/commonModalProcedures';
import { CuitsRoot } from 'store/cuit-store/cuitModel';

type TFeaturedProducts = {
  backgroundImage: string | null;
  imgLogo: string;
  nombre: string;
  nombreEntidad: string;
  alias: string;
  bgImage?: string;
  leyenda: string;
  setProcedure: () => void;
  procedure: any;
  aliasTipoTramite: string;
  companies: CuitsRoot[];
};

export const FeaturedProducts = ({
  backgroundImage,
  imgLogo,
  nombre,
  bgImage,
  nombreEntidad,
  alias,
  leyenda,
  companies,
  aliasTipoTramite,
  setProcedure,
  procedure,
}: TFeaturedProducts) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:425px)');

  const {
    cuitsStore: { cuitsByEstados },
  } = useStores();
  const companiesCuits = cuitsByEstados();

  const {
    sessionStore: { isExternal },
  } = useStores();

  const approvedCompanies = companiesCuits?.filter(
    (company: any) => company.estado === 'aprobado',
  );

  const redirectWithAliasProcedure = changeDataByAlias(
    aliasTipoTramite,
    'certificaciones',
  );

  const labelChipByAlias = changeDataByAlias(
    aliasTipoTramite,
    'sustentabilidad',
  );

  return (
    <>
      <UploadCompanyModal
        imgHeader={bgImage || ''}
        redirectTo="/micuenta"
        logos={[imgLogo]}
        title={nombre || ''}
        subtitle={nombreEntidad}
        open={open}
        onClose={() => setOpen(false)}
        onlyRead={false}
        width={1150}
      />
      {isExternal &&
        procedure?.alias &&
        approvedCompanies?.length > 0 &&
        isProcedureEqualToHuellaDeCarbono(procedure) && (
          <CommomModalProcedure
            imgHeader={procedure?.imgHeader || ''}
            redirectTo={`/certificaciones/${procedure.alias}`}
            logos={[procedure?.entidad.logo || '']}
            title={procedure?.nombre || ''}
            subtitle={procedure?.entidad.nombre || ''}
            open={openCommonModal}
            onClose={() => setOpenCommonModal(false)}
            onlyRead={false}
            children={<CardListCommonProcedureModal isMobile={isMobile} />}
            width={1150}
            textButton="Empecemos"
          />
        )}
      <Paper
        sx={{
          padding: '16px',
          borderRadius: '8px',
          width: 'calc(100% - 32px)',
          height: 'calc(256px - 32px)',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundSize: 'cover',
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.59) 50%, rgba(0, 0, 0, 0.00) 100%), url(${backgroundImage})`,
        }}
      >
        <Box>
          <Chip
            label={labelChipByAlias}
            color="primary"
            sx={{
              '&.MuiChip-root': {
                backgroundColor: colors.white,
                color: colors.tertiaryMedium,
                fontSize: '12px',
                fontFamily: 'Gotham Rounded',
                fontWeight: 350,
                textTransform: 'uppercase',
                lineHeight: '15px',
                wordWrap: 'break-word',
              },
            }}
          />
        </Box>

        <Grid container display="flex" direction="column" gap="14px">
          <Box display="flex" flexDirection="row" gap={1}>
            <img
              src={`${import.meta.env.VITE_API_BASE_URL}${imgLogo}`}
              width="36px"
              height="36px"
              style={{ borderRadius: '50%' }}
              alt="producto-detacado"
            />
            <Box display="flex" flexDirection="column">
              <P3 style={{ color: colors.white }}>{nombre}</P3>
              <P4 color={colors.white}>{nombreEntidad}</P4>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', laptop: 'row' }}
            gap={1}
            alignItems={{ xs: 'flex-start', laptop: 'center' }}
            justifyContent="space-between"
          >
            <P3 style={{ color: colors.white }}>
              {leyenda}&nbsp;&nbsp;
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => setProcedure()}
                onClick={() => setProcedure()}
                style={{
                  fontSize: '12px',
                  color: colors.primaryBase,
                  textDecoration: 'underline',
                }}
              >
                Conocé más
              </span>
            </P3>

            <Box
              width={{ xs: '100%', tabletS: '164px' }}
              minWidth={{ xs: '100%', tabletS: '164px' }}
              alignSelf={{ xs: 'flex-end', laptop: 'inherit' }}
            >
              <Button
                preset="normal"
                size="small"
                text="Iniciar solicitud"
                variant="contained"
                fullWidth
                onClick={() => {
                  if (approvedCompanies?.length <= 0) {
                    setOpen(true);
                  } else if (
                    isProcedureEqualToHuellaDeCarbono(procedure) &&
                    isExternal
                  ) {
                    setOpenCommonModal(true);
                  } else {
                    navigate(`/${redirectWithAliasProcedure}/${alias}`);
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Paper>
    </>
  );
};
