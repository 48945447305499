/* eslint-disable no-param-reassign */
import { LatLng } from 'leaflet';
import api from 'services/api/api';
import { Field } from 'models/fields';
import { LoteResponse, Lote } from 'models/map';
import { generateRandomColor } from './common';
import { Perimeter } from './types';

type Data = {
  polygons: Array<Polygon>;
};

type Polygon = Array<Array<number[] | string>>;

export const fileTypes = ['KMZ', 'KML'];

export const uploadKML = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const { files } = e.target;

  if (!files) {
    return [];
  }

  const uploadedPerimeters: any[] = []; // TODO: Fix type

  async function processFiles(
    filesArray: File[],
    index: number,
  ): Promise<void> {
    if (index >= filesArray.length) {
      return;
    }

    const file = filesArray[index];
    const form = new FormData();
    form.append('file', file);

    const upperExtension = file.name.split('.').pop()?.toUpperCase();
    if (!upperExtension || !fileTypes.includes(upperExtension)) {
      uploadedPerimeters.push({
        error: 'Formato de archivo no soportado.',
      });
    } else {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/agrology/parseKML/`,
          {
            method: 'POST',
            headers: {
              Accept:
                'application/json, application/xml, text/plain, text/html, *.*',
              Authorization: `Token ${api.api.token}`,
            },
            body: form,
          },
        );

        if (!response.ok) {
          throw new Error('Error en la carga del archivo.');
        }

        const { data }: { data: Data } = await response.json();
        if (data.polygons && data.polygons.length > 0) {
          const polygons = data.polygons.map((polygon) => {
            const parsedPoints = polygon[1].map((point) => {
              return { lat: point[1], lng: point[0] };
            });
            return {
              color: generateRandomColor(),
              name: `${polygon[0]}`,
              polygon: [...parsedPoints, parsedPoints[0]],
              original: false,
              load: true,
            };
          });
          uploadedPerimeters.push(...polygons);
        }

        // Limpia el valor del input para que se pueda subir el mismo archivo
        e.target.value = '';
      } catch (error) {
        uploadedPerimeters.push({
          error: `Error en la carga del archivo ${file.name}`,
        });
      }
    }

    await processFiles(filesArray, index + 1);
  }

  await processFiles(Array.from(files), 0);

  return uploadedPerimeters;
};

export const normalizeLong = (long: number) => {
  return (((long % 360) + 540) % 360) - 180;
};

export const getPointToZoom = (perimeters: Perimeter[]) => {
  const initialPoint = perimeters[0].polygon[0];
  const bounds = {
    min: { lat: initialPoint.lat, lng: initialPoint.lng },
    max: { lat: initialPoint.lat, lng: initialPoint.lng },
  };
  perimeters.forEach((perimeter) => {
    perimeter.polygon.forEach((point: LatLng) => {
      if (point.lat < bounds.min.lat) {
        bounds.min.lat = point.lat;
      }
      if (point.lat > bounds.max.lat) {
        bounds.max.lat = point.lat;
      }
      if (point.lng < bounds.min.lng) {
        bounds.min.lng = point.lng;
      }
      if (point.lng > bounds.max.lng) {
        bounds.max.lng = point.lng;
      }
    });
  });

  return [
    [bounds.min.lat, bounds.min.lng],
    [bounds.max.lat, bounds.max.lng],
  ];
};

export const controls = {
  drawMarker: false,
  drawCircleMarker: false,
  drawPolyline: false,
  drawRectangle: false,
  drawCircle: false,
  drawPolygon: true,
  editMode: false,
  dragMode: false,
  cutPolygon: false,
  drawText: false,
  removalMode: true,
};

export const fieldsSorter = (fields: Field[]) => ({
  get filterNulls() {
    return fields.filter(
      (field: Field) => field.poligono === null || field.localidad === null,
    );
  },
  get filterFields() {
    return fields.filter(
      (field: Field) => field.poligono !== null && field.localidad !== null,
    );
  },
});

export const concatArr = (arrNoNulls: Field[], arrNulls: Field[]) => {
  return [...arrNoNulls, ...arrNulls];
};

export const concatFieldsArr = (fields: Field[]) => {
  const arrNotNulls = fieldsSorter(fields).filterFields;
  const arrNulls = fieldsSorter(fields).filterNulls;

  return concatArr(arrNotNulls, arrNulls);
};

export const lotesSorter = (lotes: LoteResponse[]) => {
  let count = 0;

  const lotesParsed = lotes
    .sort((a, b) => {
      // Ordeno los lotes según si tienen o no polígono, por nombre y por id
      if (a.poligono == null && b.poligono !== null) {
        return 1;
      }
      if (a.poligono !== null && b.poligono == null) {
        return -1;
      }
      if (a.poligono == null && b.poligono == null && a.nombre && b.nombre) {
        return a.nombre.localeCompare(b.nombre);
      }
      return a.id - b.id;
    })
    .map((lot: Lote) => {
      lot.color = generateRandomColor();
      count += 1;
      if (!lot.poligono) {
        lot.poligono = [];
        lot.nombrePoligono = `Polígono ${count}`;
        lot.assigned = false;
      } else {
        lot.nombrePoligono = `Polígono ${count}`;
        lot.assigned = false;
      }
      return lot;
    });

  return lotesParsed;
};
