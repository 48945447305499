import { Link } from 'react-router-dom';
import { Box, CardMedia, Stack, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { P3Ellipsis, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import CustomTooltip from 'components/common/tooltips';
import { EstablecimientoEntity, ProcedureInProcess } from 'models/procedure';
import {
  BadgeStatus,
  CardMediaProductStyle,
  TraceCard,
  TraceCardContent,
} from './index.styled';
import { isProcessingAllowed } from 'utils/AllowedProcedures';

interface FinishedCardDetailProps {
  approved: boolean;
  trace: ProcedureInProcess;
}

interface FinishedCardProps extends FinishedCardDetailProps {
  procedure: 'certificaciones' | 'financiamiento' | 'seguros';
  handleOnAlert: () => void;
}

const fieldText = (states: EstablecimientoEntity[]) => {
  if (states.length === 1) {
    return states[0].establecimiento.nombre;
  }
  if (states.length > 1) {
    return `${states[0].establecimiento.nombre} y ${states.length - 1} campo${
      states.length - 1 > 1 ? 's' : ''
    } más`;
  }
  return '';
};

const ProcessCardDetail = (props: FinishedCardDetailProps) => {
  const { approved, trace } = props;
  const title =
    trace.cuitSolicitante?.razonSocial || fieldText(trace.establecimientos);

  const nombreEntidad = trace.producto.entidad.nombre;
  return (
    <TraceCard elevation={1}>
      <TraceCardContent>
        <Stack direction="row" alignItems="center" gap="5px">
          <CardMedia
            sx={CardMediaProductStyle}
            component="img"
            alt="product"
            loading="lazy"
            image={`${import.meta.env.VITE_API_BASE_URL}${
              trace.producto.logo || trace.producto.entidad.logo
            }`}
          />
          <P4
            style={{
              color: colors.tertiaryBase,
            }}
          >
            {nombreEntidad} | {trace.producto.nombre}
          </P4>
        </Stack>

        <Stack gap="8px">
          <CustomTooltip title={title} arrowLocation="top-start">
            <P3Ellipsis
              style={{
                color: colors.black,
              }}
            >
              {title}
            </P3Ellipsis>
          </CustomTooltip>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <BadgeStatus isApproved={approved}>
              <P4
                style={{
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: approved
                    ? colors.complementaryGreen
                    : colors.complementaryError,
                }}
              >
                {approved ? 'Aprobada' : 'Rechazada'}
              </P4>
            </BadgeStatus>
            {approved ? (
              <CheckCircleIcon
                sx={{ fontSize: 16, color: colors.complementaryGreen }}
              />
            ) : (
              <CancelIcon
                sx={{ fontSize: 16, color: colors.complementaryError }}
              />
            )}
          </Stack>
        </Stack>
      </TraceCardContent>
    </TraceCard>
  );
};

const ProcedureFinishedCard = (props: FinishedCardProps) => {
  const { procedure, trace, handleOnAlert } = props;
  const { codigo } = trace;
  const { alias } = trace.producto;
  const urlRedirect = `/${procedure}/${alias}/${codigo}`;
  const isNotDesktop = useMediaQuery('(max-width:768px)');

  return (
    <>
      {!isNotDesktop || !isProcessingAllowed(alias) ? (
        <Link to={urlRedirect} style={{ textDecoration: 'none' }}>
          <ProcessCardDetail {...props} />
        </Link>
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          onClick={handleOnAlert}
        >
          <ProcessCardDetail {...props} />
        </Box>
      )}
    </>
  );
};

export default ProcedureFinishedCard;
