import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import Alert from 'components/common/alerts';

interface AuthAlertProps {
  btnPrimText: string;
}

const AuthAlert: React.FC<AuthAlertProps> = observer((props) => {
  const { btnPrimText } = props;
  const { sessionStore } = useStores();

  const { showModal, reason, message } = sessionStore;

  const handleClose = () => {
    sessionStore.removeReasonTokenExpired();
  };

  return (
    <>
      {showModal && reason && message && (
        <Alert
          btnPrimText={btnPrimText}
          callbackPrim={handleClose}
          showModal={showModal}
          handleClose={handleClose}
          title={reason}
          message={message}
        />
      )}
    </>
  );
});

export default AuthAlert;
