import React from 'react';
import { Field } from 'models/fields';
import { Box, Divider, useMediaQuery } from '@mui/material';

import type { LatLngExpression } from 'leaflet';

import { StyledDrawer } from './index.styled';
import LotesModule from './Lotes';
import { TitleHeader } from './TitleHeader';
import CamposModule from './Campos';
import LocationModule from './Location';

type EstablecimientoDetailDrawerProps = {
  open: boolean;
  field: Field | null;
  onClose: () => void;
  openMapCampo: () => void;
  openMapLotes: () => void;
};

const EstablecimientoDetailDrawer: React.FC<
  EstablecimientoDetailDrawerProps
> = ({ open, field, onClose, openMapCampo, openMapLotes }) => {
  if (!field) return null;

  return (
    <StyledDrawer open={open} onClose={onClose} elevation={24} anchor="right">
      <TitleHeader
        id={field.id}
        poligonos={field.poligono as LatLngExpression[]}
        title={field.nombre}
        onClose={onClose}
      />
      <CamposModule openMap={openMapCampo} hectareas={field.hectareas} />
      <Divider />
      <LotesModule openMap={openMapLotes} field={field} />
      <Divider />
      <Box px="24px">
        <LocationModule field={field} onSubmit={onClose} />
      </Box>
    </StyledDrawer>
  );
};

export default EstablecimientoDetailDrawer;
