/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer } from 'react';
import agreeReducer from './index.reducer';
import { initialStateAgree } from './initialValues';
import { AgreeDispatch, InitialStateAgree } from './index.types';

export const AgreeStateContext =
  createContext<InitialStateAgree>(initialStateAgree);

export const AgreeDispatchContext = createContext<AgreeDispatch>(
  {} as AgreeDispatch,
);

interface IAgreeContext {
  children: React.ReactNode;
}

export const AgreeContext: React.FC<IAgreeContext> = ({ children }) => {
  const [agreeValues, dispatch] = useReducer(agreeReducer, initialStateAgree);
  return (
    <AgreeDispatchContext.Provider value={dispatch}>
      <AgreeStateContext.Provider value={agreeValues}>
        {children}
      </AgreeStateContext.Provider>
    </AgreeDispatchContext.Provider>
  );
};
