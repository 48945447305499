import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import RainyCloud from 'components/common/icon/icons/rainyCloud';
import EventBtn from 'components/common/buttons/eventBtn';
import colors from 'theme/colors';
import { useStores } from 'store/root-store/root-store-context';

interface IWeather {
  showWeather: boolean;
  hasPolygon: boolean;
  handleWeather: () => void;
}

const WeatherButton: React.FC<IWeather> = ({
  showWeather,
  hasPolygon,
  handleWeather,
}) => {
  const {
    sessionStore: { splitioClima },
  } = useStores();

  if (splitioClima && hasPolygon) {
    return (
      <Box
        display={{
          xs: 'none',
          tablet: 'block',
        }}
      >
        <EventBtn
          disabledBtn={showWeather}
          onPress={handleWeather}
          text="Conocé el clima de tu campo"
          primary={showWeather}
          icon={
            <RainyCloud
              color={!showWeather ? colors.primaryBase : colors.white}
              height="16px"
              width="16px"
            />
          }
        />
      </Box>
    );
  }

  return null;
};

export default observer(WeatherButton);
