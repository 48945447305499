import React from 'react';

import { CardMedia, IconButton, Box, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { H4, H6, P4 } from 'components/common/typography/styles';
import BtnModal from 'components/common/buttons/btnModal';
import ComputerIcon from '@mui/icons-material/Computer';
import colors from 'theme/colors';
import {
  DialogContainerUploadCompany,
  DialogHeader,
  DialogBody,
} from './index.styled';
import { useNavigate } from 'react-router-dom';

interface UploadCompanyModalProps {
  imgHeader: string;
  redirectTo: string;
  logos: string[] | null | undefined;
  title: string;
  subtitle: string;
  open: boolean;
  onClose: () => void;
  onlyRead: boolean;
  width?: number;
}

const UploadCompanyModal: React.FC<UploadCompanyModalProps> = ({
  imgHeader,
  redirectTo,
  logos,
  title,
  subtitle,
  open,
  onClose,
  onlyRead,
  width = 500,
}) => {
  const LOGO_RISKGROUP = '/media/entidad_logo/Logo_RiskGroupArg.png';
  const navigate = useNavigate();
  return (
    <DialogContainerUploadCompany
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '200px',
        },
      }}
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <Box>
        <DialogHeader
          sx={{
            backgroundImage: `url(${
              import.meta.env.VITE_API_BASE_URL
            }${imgHeader})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top-center',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 100%)',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 27,
              color: colors.white,
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{ zIndex: 1000 }}
          >
            <Stack direction="row" gap="14px">
              {logos?.map((logo) => (
                <CardMedia
                  sx={{
                    width: 'auto',
                    maxHeight: '32px',
                    marginTop: logo === LOGO_RISKGROUP ? '0px' : '8px',
                  }}
                  component="img"
                  image={`${import.meta.env.VITE_API_BASE_URL}${logo}` || ''}
                  key={logo}
                />
              ))}
            </Stack>
            <H6 style={{ color: colors.white, marginTop: '7px' }}>
              {subtitle}
            </H6>
          </Stack>
          <H4
            style={{
              color: colors.white,
              margin: '16px 0 8px 0',
              zIndex: 1000,
            }}
          >
            {title}
          </H4>
        </DialogHeader>
        <DialogBody>
          <Box my="16px" display="flex" justifyContent="center">
            <H6 sx={{ fontFamily: 'Work Sans', fontWeight: 500 }}>
              Este trámite requiere una <b>empresa validada</b> para solicitar
              <br />
              este producto
            </H6>
          </Box>
          <Box display="flex" justifyContent="center" mt="24px">
            {width && width >= 1024 && !onlyRead && (
              <Box
                onClick={() => {
                  navigate('/micuenta', { state: true });
                }}
              >
                <BtnModal
                  text="Agregar una nueva empresa"
                  onClick={onClose}
                  to=""
                  id="cert_iniciar"
                />
              </Box>
            )}
            {width && width < 1024 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                gap="8px"
                mt="12px"
              >
                <ComputerIcon sx={{ color: colors.tertiaryMedium }} />
                <P4
                  color={colors.tertiaryMedium}
                  style={{ maxWidth: '280px', marginTop: '2px' }}
                >
                  Visitá <b>app.agrology.com</b> desde tu computadora para
                  iniciar o ver el detalle de los trámites.
                </P4>
              </Box>
            )}
          </Box>
        </DialogBody>
      </Box>
    </DialogContainerUploadCompany>
  );
};

export default UploadCompanyModal;
