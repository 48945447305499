/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { externalLogin } from 'services/api/authApi';
import { Box } from '@mui/material';

const ValidateMobileToken: React.FC = observer(() => {
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onExternalUserSuccess = (token: string | undefined) => {
    if (token) {
      sessionStore.setToken(token);
      api.api.setToken(token);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      navigate('/', { replace: true });
    }
  };

  const onExternalUserFailure = (message: string | undefined) => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const getGoogleCookieAndLogin = async () => {
    const idToken = document.cookie
      .split(' ')
      .filter((e) => e.includes('idToken'))[0]
      .split('=')[1];
    externalLogin({
      accessToken: idToken,
      onSuccess: onExternalUserSuccess,
      onFailure: onExternalUserFailure,
    });
  };

  useEffect(() => {
    getGoogleCookieAndLogin();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <SpinnerXL />
    </Box>
  );
});

export default ValidateMobileToken;
