import React from 'react';
import Rain from 'assets/icons/icons-weather/nube-lluvia.svg';
import { P4 } from 'components/common/typography/styles';
import { ContainerRain } from './weather.styled';
import { IRain } from './typesWeather';

export const RainComponent: React.FC<IRain> = ({ rain }) => {
  return (
    <ContainerRain>
      <img
        style={{
          marginRight: '6px',
        }}
        src={Rain}
        alt=""
        width="15px"
        height="15px"
        loading="lazy"
      />
      <P4>{rain.toFixed(2)}mm</P4>
    </ContainerRain>
  );
};
