import { useContext } from 'react';
import { AgreeDispatchContext, AgreeStateContext } from 'contexts/Agree';

export function useAgreeContext() {
  const state = useContext(AgreeStateContext);
  const dispatch = useContext(AgreeDispatchContext);
  if (!state || !dispatch) {
    throw new Error('useAgreeContext debe ser usado con AgreeProvider');
  }
  return {
    state,
    dispatch,
  };
}
