import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import api from 'services/api/api';
import { ProcedureInProcess } from 'models/procedure';
import { Country } from 'models/custom';
import { DarumaDispatchContext } from 'contexts/Daruma';
import { useStores } from 'store/root-store/root-store-context';
import { LazyAboutBusiness, LazyRepresentantForm } from '../lazyDaruma';

const DarumaRepresentantScreen: React.FC<{ procedure: ProcedureInProcess }> = ({
  procedure,
}) => {
  const { cuitsStore } = useStores();
  const dispatch = useContext(DarumaDispatchContext);
  const [countries, setCountries] = useState<Country[]>([]);

  const { cuits } = cuitsStore;

  const getCountries = async () => {
    const response = await api.procedure.getCountryData();
    if (response.kind) {
      return;
    }
    if (response) {
      setCountries(response.data);
    }
  };

  const getProcedureInfo = async () => {
    const cuitSelected = cuits.find(
      (cuit) => cuit.cuit === procedure.cuitSolicitante.cuit,
    );
    if (cuitSelected) {
      dispatch({
        type: 'set-fromArgenpymes',
        value:
          procedure.fromArgenpymes || 'argenpymes' in cuitSelected?.terceros,
      });
    }
    dispatch({ type: 'set-productName', value: procedure.producto.nombre });
    dispatch({
      type: 'set-stateProcedure',
      value: procedure.estado,
    });
    dispatch({
      type: 'set-representante',
      value: null,
    });
    dispatch({
      type: 'set-empresaSolicitante',
      value: procedure.cuitSolicitante.razonSocial,
    });
  };

  useEffect(() => {
    cuitsStore.getCuits();
    getProcedureInfo();
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {procedure && (
        <LazyRepresentantForm procedure={procedure} countries={countries} />
      )}
      {procedure && <LazyAboutBusiness procedure={procedure} />}
    </>
  );
};

export default observer(DarumaRepresentantScreen);
