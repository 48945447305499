import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';

type Props = {
  title: string;
};

const SubHeader = ({ title }: Props) => {
  const navigate = useNavigate();

  const location = useLocation();

  const routeToRedirect = location.pathname.split('/')[1];

  const handleOnClose = () => {
    navigate(`/${routeToRedirect}`);
  };

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.tertiaryLow,
        height: 48,
        position: 'relative',
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
    >
      <Box>
        <IconButton
          onClick={handleOnClose}
          sx={{
            color: colors.tertiaryHigh,
            '&:hover': { color: colors.secondaryBase },
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <P3 color={colors.white}>{title}</P3>

      <Box width="24px" height="24px" />
    </AppBar>
  );
};

export default SubHeader;
