import React from 'react';

interface MapDrawProps {
  className: string;
}
const MapDraw: React.FC<MapDrawProps> = ({ className }) => {
  // const MapDraw = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      className="className"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333333"
        y="0.333333"
        width="15.3333"
        height="15.3333"
        rx="2.33333"
        stroke="#4B4846"
        strokeWidth="0.666667"
      />
      <circle
        cx="11.6875"
        cy="4.27152"
        r="1.56546"
        stroke="#4B4846"
        strokeWidth="0.666667"
      />
      <circle
        cx="11.6875"
        cy="11.7281"
        r="1.56546"
        stroke="#4B4846"
        strokeWidth="0.666667"
      />
      <circle
        cx="4.31351"
        cy="11.7281"
        r="1.56546"
        stroke="#4B4846"
        strokeWidth="0.666667"
      />
      <path
        d="M5.67311 11.7695H10.4108M11.6056 10.4512V5.54881M10.7404 5.21924L5.1377 10.7396"
        stroke="#4B4846"
        strokeWidth="0.666667"
      />
    </svg>
  );
};
export default MapDraw;
