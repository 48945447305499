import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

const shouldMock = false;

export class HomeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getSiembra(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/home/siembra/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCosecha(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/home/cosecha/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyBusiness(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/home/me/empresas/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyCertifications(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/home/me/certificaciones/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyFields(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/home/me/campos/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getHighlightedProcedures(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/productos/destacados/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCarrusel(): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve, reject) => {
          resolve({ pending: 'pending' });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/carrousel/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
