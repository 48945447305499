import React from 'react';
import { H3, P2 } from 'components/common/typography/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import useWindowDimensions from 'hooks/useWindowsSize';
import { StyledDrawer } from './index.styled';

interface DrawerProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  marginTitle?: string;
  marginSubTitle?: string;
}

const WrapperDrawer = (props: DrawerProps) => {
  const {
    children,
    open,
    onClose,
    title,
    description,
    marginTitle,
    marginSubTitle,
  } = props;
  const { width } = useWindowDimensions();

  return (
    <StyledDrawer
      windowswidth={width}
      anchor="right"
      open={open}
      onClose={onClose}
      height="100vh"
      elevation={24}
      marginTitle={marginTitle}
      marginSubTitle={marginSubTitle}
    >
      <Box className="drawer-container">
        <Box padding="0 16px">
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
          <H3 className="drawer-title">{title}</H3>
          {!!description && (
            <P2 className="drawer-description">{description}</P2>
          )}

          {children}
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default WrapperDrawer;
