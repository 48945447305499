import { useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { solicitudTokenAccesoExterno } from 'services/api/authApi';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { H5 } from 'components/common/typography/styles';

export const ExternalLoginValidate = () => {
  const { sessionStore } = useStores();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [responseError, setResponseError] = useState<{
    error: boolean;
    message: string | undefined;
  }>({
    error: false,
    message: '',
  });

  (() => {
    if (!responseError.error) {
      solicitudTokenAccesoExterno({
        uuid: uuid ?? '',
        onSuccess: (token) => {
          sessionStore.setToken(token ?? '');
          api.api.setToken(token ?? '');

          navigate('/', { replace: true });
        },
        onFailure: (message) => {
          setResponseError({ error: true, message });
        },
      });
    }
  })();

  return (
    <Container>
      <H5 sx={{ textAlign: 'center', whiteSpace: 'initial' }}>
        {responseError.message}
      </H5>
    </Container>
  );
};
