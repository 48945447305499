import React, { createContext, useReducer } from 'react';
import viterraReducer from './index.reducer';
import { initialStateViterra } from './initialValues';
import { InitialStateViterra, ViterraDispatch } from './index.types';

export const ViterraStateContext =
  createContext<InitialStateViterra>(initialStateViterra);
export const ViterraDispatchContext = createContext<ViterraDispatch>(
  {} as ViterraDispatch,
);

export const ViterraContext = ({ children }: { children: React.ReactNode }) => {
  const [viterraValues, dispatch] = useReducer(
    viterraReducer,
    initialStateViterra,
  );
  return (
    <ViterraDispatchContext.Provider value={dispatch}>
      <ViterraStateContext.Provider value={viterraValues}>
        {children}
      </ViterraStateContext.Provider>
    </ViterraDispatchContext.Provider>
  );
};
