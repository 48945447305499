import React from 'react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import CustomTooltip from 'components/common/tooltips';
import { StyleBackToTop } from './index.styled';

const BackToTop: React.FC = () => {
  const scrollToTopView = () => {
    const element = document.getElementById('content-scroll');
    if (element) {
      element.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <StyleBackToTop>
      <CustomTooltip title="Volver arriba" arrowLocation="bottom">
        <IconButton
          onClick={scrollToTopView}
          aria-label="add to shopping cart"
          className="button"
        >
          <FirstPageIcon sx={{ fontSize: 32, color: 'white' }} />
        </IconButton>
      </CustomTooltip>
    </StyleBackToTop>
  );
};

export default BackToTop;
