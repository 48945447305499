/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { SpinnerXL } from 'components/common/icon/icons/spinner';

const ValidateTokenFromErp: React.FC = observer(() => {
  const [searchParams] = useSearchParams();
  const tknValidate = searchParams.get('remote-token');
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const redirectToHome = async () => {
    if (tknValidate) {
      api.api.removeToken();
      const { sessionToken, domainName, origin } =
        await api.invite.validateTokenFromErp(tknValidate);

      if (sessionToken) {
        api.api.setToken(sessionToken);
        enqueueSnackbar(Strings.forms.login.success, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        sessionStore.setToken(sessionToken);
        navigate('/', { replace: true });
      } else {
        navigate('/portal/activar-cuenta', {
          state: { tokenFromErp: tknValidate, domainName, origin },
        });
      }
    }
  };

  useEffect(() => {
    redirectToHome();
  }, []);

  return (
    <>
      <SpinnerXL />
    </>
  );
});

export default ValidateTokenFromErp;
