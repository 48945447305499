/* eslint-disable @typescript-eslint/ban-types */
export const trazabilidadEstado = (state: null | string | boolean) => {
  if (state === null) return 'Pendiente';
  if (state) return 'Aprobado';
  return 'Rechazado';
};

export const filterByCuit = (cuits: Array<any>, cuitSolicitante: string) =>
  cuits.filter((el: any) => el.cuit === cuitSolicitante);

export const SUCCESSFULY_MESSAGE_DARUMA =
  'Bienvenido al ecosistema de finanzas Allaria +.';
export const SUCCESSFULY_MESSAGE_ARGENPYMES = '¡Tu solicitud fue aprobada!';

export const SUCCESSFULY_MESSAGE_PUMA =
  'Bienvenido al programa Huella de Carbono';

export const RequestComponent = (
  state: boolean | null | string | undefined,
  obj: any,
  fn: Function,
) => {
  if (state) return componentByKey(obj, 'successful')();
  return componentByKey(obj, 'denied')(fn);
};

const componentByKey = (obj: any, key: any) => {
  return (fn?: Function) => obj[key](fn);
};
