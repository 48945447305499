import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { FieldDataButtonProps } from 'utils/types';
import colors from 'theme/colors';
import EventBtn from './eventBtn';

const FieldDataButton: React.FC<FieldDataButtonProps> = ({
  hasError,
  onPress,
}) => {
  return !hasError ? (
    <EventBtn
      onPress={onPress}
      text="Modificar datos"
      primary={false}
      icon={
        <EditIcon
          sx={{
            color: colors.primaryBase,
            fontSize: 16,
            marginRight: '4px',
          }}
        />
      }
    />
  ) : (
    <EventBtn
      onPress={onPress}
      text="Completar datos"
      primary
      icon={
        <EditIcon
          sx={{
            color: '#FFFFFF',
            fontSize: 16,
            marginRight: '4px',
          }}
        />
      }
    />
  );
};

export default FieldDataButton;
