/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useFormikContext } from 'formik';

export const addFormikFieldProps = (name: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { errors, values, handleBlur, setFieldValue } = useFormikContext();
  // @ts-ignore
  const error = errors[name]; // if name have a dot, enter in the object
  // @ts-ignore
  const value = values[name];
  return {
    error,
    value,
    onBlur: handleBlur,
    setValue: (newValue: any) => {
      setFieldValue(name, newValue);
    },
    onChange: (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | null,
      keyboardInputValue?: string | undefined,
    ) => {
      const v = e;
      if (!e) {
        setFieldValue(name, keyboardInputValue);
        return;
      }
      if (typeof e === 'object' && 'target' in e) {
        setFieldValue(name, e.target.value);
        return;
      }
      setFieldValue(name, v);
    },
  };
};
