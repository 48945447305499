import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { createCertificationStoreDefaultModel } from 'store/certifications-store/certification-store';
import { createProcedureHeaderStoreDefaultModel } from 'store/procedure-store/procedure-store';
import { createCuitsDefaultModel } from 'store/cuit-store/cuit-store';
import { createMapStoreDefaultModel } from 'store/map-store/map-store';
import { createSessionDefaultModel } from 'store/session-store/session-store';
import { createFieldsStoreDefaultModel } from 'store/fields-store/fields-store';

export const resetStore = (self: any) => {
  let initialState: any;
  return {
    afterCreate() {
      initialState = getSnapshot(self);
    },
    resetStore() {
      applySnapshot(self, initialState);
    },
    clearStorage() {
      localStorage.clear();
    },
  };
};

export const RootStoreModel = types
  .model('RootStore')
  .props({
    sessionStore: createSessionDefaultModel(),
    fieldsStore: createFieldsStoreDefaultModel(),
    certificationStore: createCertificationStoreDefaultModel(),
    procedureHeaderStore: createProcedureHeaderStoreDefaultModel(),
    cuitsStore: createCuitsDefaultModel(),
    mapStore: createMapStoreDefaultModel(),
  })
  .actions(resetStore);

export const rootStore = RootStoreModel.create();

export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
