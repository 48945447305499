import React from 'react';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import Alert from 'components/common/alerts';

interface IPEDarumaModal {
  open: boolean;
  onClose: () => void;
}

export const DarumaAlert: React.FC<IPEDarumaModal> = ({ open, onClose }) => {
  return (
    <Alert
      showModal={open}
      handleClose={onClose}
      title="No hay empresas"
      message={
        <P3 color={colors.tertiaryMedium}>
          Todas las empresas de la cuenta están dadas de alta en Allaria o
          tienen un tramite en progreso.
        </P3>
      }
    />
  );
};
