import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import colors from 'theme/colors';
import { P3, P4 } from '../../typography/styles';
import { Title } from '../../alerts/index.styled';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

type Props = {
  title: string;
  description: React.ReactNode;
  open: boolean;
  associatedAccounts: associatedAccounts[];
  handleClose: () => void;
};

interface associatedAccounts {
  entidad: Entidad;
  nombre: string;
}

interface Entidad {
  nombre: string;
  logo: string;
}

export const ErrorCannotDeleteModal = ({
  open,
  title,
  associatedAccounts,
  description,
  handleClose,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{ elevation: 24 }}
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}
      >
        <Box display="flex" alignItems="end" justifyContent="flex-start">
          <WarningIcon
            sx={{ color: colors.complementaryError, marginRight: '8px' }}
          />
          <Title sx={{ color: colors.complementaryError }}>{title}</Title>
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: colors.tertiaryLow }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingX: '34px',
        }}
      >
        <Stack gap="24px" mb="20px">
          {description}
        </Stack>

        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
          justifyContent="space-between"
          gap="13px"
        >
          {associatedAccounts.map((e: any) => (
            <Stack direction="row" justifyContent="space-between" gap="16px">
              <Stack direction="row" gap="8px" marginRight="12px">
                <Box>
                  <Avatar
                    alt={e.entidad.nombre}
                    src={`${import.meta.env.VITE_API_BASE_URL}${
                      e.entidad.logo
                    }`}
                  />
                </Box>
                <Box>
                  <P3>{e.nombre}</P3>
                  <P4 color={colors.tertiaryMedium}>{e.entidad.nombre}</P4>
                </Box>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};