import React from 'react';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { ConfirmAbandonment } from 'components/common/modals/confirmAbandonment';
import ProcedureProgress from 'components/common/progress/procedureProgress';
import { useNavigatingAway } from 'hooks/useNavigateBlocker';
import { useHuellaDeCarbonoContext } from 'contexts/HuellaDeCarbono/useHuellaDeCarbonoContext';
import { Box } from '@mui/material';
import RoutesWithoutProcedure from './rutasSinTrazabilidad';
import RoutesWithProcedure from './rutasConTrazabilidad';
import { ReportResult } from './ResultadoDelReporte/lazyWithWrapper';

export const HuellaDeCarbonoContainer: React.FC = observer(() => {
  const { state } = useHuellaDeCarbonoContext();
  const {
    showDialogPrompt: showDialogLeavingPage,
    confirmNavigation,
    cancelNavigation,
  } = useNavigatingAway(state.moveForward);

  return (
    <>
      <ConfirmAbandonment
        body="Al cerrar la solicitud se perderán los datos ingresados hasta el momento sin guardar."
        open={showDialogLeavingPage as boolean}
        onClose={cancelNavigation}
        onClick={cancelNavigation}
        onClickButtonRejection={confirmNavigation}
        title="¿Abandonar solicitud?"
        textBtnRejection="Abandonar"
        textBtnSuccess="Continuar solicitud"
      />
      <Box width="100%" mx="auto">
        <Box px={{ xs: '16px', tablet: '24px' }}>
          <ProcedureProgress
            procedure="puma"
            state={state.stateProcedure}
            refused={state.refused}
          />
        </Box>
        <Box width="100%">
          <Routes>
            <Route path="/" element={<RoutesWithoutProcedure />} />
            <Route path="/:code" element={<RoutesWithProcedure />} />
            <Route path="/:code/resultado" element={<ReportResult />} />
          </Routes>
        </Box>
      </Box>
    </>
  );
});
