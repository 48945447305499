import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { H1, P2, P3 } from 'components/common/typography/styles';
import LinkBtn from 'components/common/buttons/linkBtn';
import colors from 'theme/colors';
import { AuthContainer } from '../index.styled';

const RecoverAccountDone: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  // TODO: Obtener email desde MobX
  const email = searchParams.get('email');

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="auth-title">Solicitud recibida</H1>
        <P2 className="auth-parag">
          Si el mail <b>{email}</b> corresponde a una cuenta válida, dentro de
          los próximos minutos recibirás un email de Agrology para recuperar el
          acceso.
        </P2>
        <LinkBtn onClick={() => navigate('/portal/iniciar-sesion')}>
          <P3
            style={{
              color: colors.primaryBase,
              textDecoration: 'underline',
              textUnderlineOffset: '1px',
              marginTop: '23px',
            }}
          >
            Volver al inicio
          </P3>
        </LinkBtn>
      </Box>
    </AuthContainer>
  );
};

export default RecoverAccountDone;
