import { Button, Stack } from '@mui/material';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import colors from 'theme/colors';
import { GoogleIcon } from '../icon/icons/GoogleIcon';

interface AuthButtonProps {
  children: React.ReactNode;
  onSuccess: (tokenResponse: TokenResponse) => void;
}
declare const window: Window &
  typeof globalThis & {
    ReactNativeWebView?: any;
  };
export const AuthButton = ({ children, onSuccess }: AuthButtonProps) => {
  const login = useGoogleLogin({
    onSuccess,
  });
  const handleLogin = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('signin');
    } else {
      login();
    }
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{
        fontSize: '20px',
        minHeight: '48px',
        borderColor: colors.tertiaryMedium,
        color: colors.tertiaryMedium,
        '&:hover': {
          borderColor: colors.tertiaryMedium,
        },
      }}
      fullWidth
      onClick={() => handleLogin()}
    >
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
        gap="16px"
      >
        <GoogleIcon />
        {children}
      </Stack>
    </Button>
  );
};
