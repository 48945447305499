/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { UserData, UserDataType } from './userModel';
import { RolesType, MenuType, Menu } from './generalModels';

export const SessionModel = types
  .model('Session')
  .props({
    currentUser: types.maybe(UserDataType),
    token: types.maybeNull(types.string),
    showModal: types.maybeNull(types.boolean),
    reason: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    splitioArgenpymes: types.optional(types.boolean, false),
    splitioClima: types.optional(types.boolean, false),
    splitioBannerDaruma: types.optional(types.boolean, false),
    splitioOneSoilBanner: types.optional(types.boolean, false),
    menu: types.optional(types.array(MenuType), []),
    roles: types.optional(types.array(RolesType), []),
  })
  .views((self) => ({
    get isUserAdmin() {
      if (!self.currentUser) {
        return false;
      }
      if (self.currentUser && self.currentUser.cuentaActual) {
        const currentCuenta = self.currentUser.misCuentas?.find(
          (cuenta) => cuenta.id === self.currentUser?.cuentaActual,
        );
        return currentCuenta?.rol === 'admin';
      }
      return true;
    },

    canAccessUrl(path: string) {
      const itemMenu = self.menu.find((opt) => opt.urlFront === path);
      return !!itemMenu;
    },

    get rolesOptionsSelect() {
      const roles = self.roles.map((rol) => ({
        label: rol.nombre,
        value: rol.codigo,
      }));

      if (!this.isUserAdmin) {
        return roles.filter((rol) => rol.value !== 'admin');
      }

      return roles;
    },

    canShowElement(rol: string) {
      if (!self.currentUser) {
        return false;
      }

      if (self.currentUser && self.currentUser.cuentaActual) {
        const currentCuenta = self.currentUser.misCuentas?.find(
          (cuenta) => cuenta.id === self.currentUser?.cuentaActual,
        );
        const roles = self.roles.map((role) => role.nombre);
        return currentCuenta?.rol === rol && roles.includes(rol);
      }

      return false;
    },

    get menuParsed() {
      const categoriasObjeto: { [key: string]: Menu[] } = {};

      self.menu.forEach((obj) => {
        const { categoria } = obj;

        if (!(categoria in categoriasObjeto)) {
          categoriasObjeto[categoria] = [];
        }

        categoriasObjeto[categoria].push(obj);
      });
      return categoriasObjeto;
    },

    get menuDesplegablesCodigos() {
      const desplegables: { [codigo: string]: boolean } = {};

      self.menu.forEach((obj) => {
        if (obj.items.length > 0) {
          desplegables[obj.codigo] = false;
        }
      });

      return desplegables;
    },

    get reasonTokenExpired() {
      return self.showModal ? self.message : null;
    },

    getMenuIconByUrl(url: string) {
      return self.menu.find(({ urlFront }) => urlFront === url)?.icono;
    },

    getMenuByFullUrl(url: string | undefined) {
      if (!url) {
        return null;
      }

      const urlSplitted = url.split('/').filter((u) => u);
      const item = self.menu.find(({ urlFront }) => {
        return urlSplitted.includes(urlFront.replace('/', ''));
      });

      return item || null;
    },
    getMenuItemByCode(code: string) {
      return self.menu.find(({ codigo }) => codigo === code);
    },

    getTableroByUrl(url: string) {
      const itemMenu = self.menu
        .filter((opt) => opt.items.length > 0)
        .map((opt) => opt.items.map((item) => item))
        .flat()
        .find((item) => item.urlFront === url);

      if (itemMenu) {
        return itemMenu.tablero;
      }

      return null;
    },

    get isExternal() {
      const current = self.currentUser?.cuentaActual || 0;

      const currentCuenta = self.currentUser?.misCuentas?.find(
        (cuenta) => cuenta.id === current,
      );

      if (currentCuenta) {
        return currentCuenta.origen === 'Externo';
      }

      return false;
    },
  }))
  .actions((self) => {
    function setToken(token: string | null) {
      self.token = token;
    }

    function setCurrentUserOffline(user: UserData | undefined) {
      self.currentUser = user;
    }

    function logOut() {
      setCurrentUserOffline(undefined);
      setToken(null);
    }

    function setReasonTokenExpired(
      showModal: boolean,
      reason: string,
      message: string,
    ) {
      self.showModal = showModal;
      self.reason = reason;
      self.message = message;
    }

    function removeReasonTokenExpired() {
      self.showModal = null;
      self.message = null;
    }

    function setSplitioArgenpymes(splitioArgenpymes: boolean) {
      self.splitioArgenpymes = splitioArgenpymes;
    }

    function setSplitioClima(splitioClima: boolean) {
      self.splitioClima = splitioClima;
    }

    function setSplitioBannerDaruma(splitioBannerDaruma: boolean) {
      self.splitioBannerDaruma = splitioBannerDaruma;
    }

    function setSplitioOneSoilBanner(splitioOneSoilBanner: boolean) {
      self.splitioOneSoilBanner = splitioOneSoilBanner;
    }

    function setMenu(data: any) {
      self.menu = data;
    }

    function setRoles(roles: any) {
      self.roles = roles;
    }

    return {
      setToken,
      setCurrentUserOffline,
      logOut,
      setReasonTokenExpired,
      removeReasonTokenExpired,
      setSplitioArgenpymes,
      setSplitioClima,
      setSplitioBannerDaruma,
      setSplitioOneSoilBanner,
      setMenu,
      setRoles,
    };
  })

  .actions((self) => ({
    getRoles: async () => {
      const response = await api.common.getRoles();
      if (!response.kind) {
        self.setRoles(response.data);
      }
    },
  }))
  .actions((self) => ({
    getMenu: async () => {
      const response = await api.common.getMenu();
      if (!response.kind) {
        self.setMenu(response);
      }
    },
  }))
  .actions((self) => ({
    getMeUser: async () => {
      const request = await api.me.getMyInformation();
      if (!request.kind) {
        self.setCurrentUserOffline(request.data);
        self.getRoles();
        self.getMenu();
      }
    },
  }))
  .actions((self) => ({
    login: async (username: string, password: string) => {
      const response = await api.auth.getToken(username, password);
      if (!response.kind) {
        self.setToken(response.token);
        api.api.setToken(response.token);
        self.getMeUser();
      }
    },
  }))
  .actions((self) => ({
    setCurrentAccount: async (accountId: number) => {
      const response = await api.me.setCurrentAccount({ cuentaId: accountId });
      if (!response.kind) {
        self.getMeUser();
      }
    },
  }))
  .actions((self) => ({
    updateMyInformation: async (userInformation: any) => {
      const response = await api.me.updateMyInformation(userInformation);
      if (response.kind) {
        return response;
      }
      self.getMeUser();
      return response;
    },
  }));

type SessionModelType = Instance<typeof SessionModel>;
export type Session = SessionModelType;
type SessionSnapshotType = SnapshotOut<typeof SessionModel>;
export type SessionSnapshot = SessionSnapshotType;
export const createSessionDefaultModel = () => types.optional(SessionModel, {});
