import React from 'react';
import { P4 } from 'components/common/typography/styles';
import TemperatureSvg from 'assets/icons/icons-weather/termometro.svg';
import {
  ContainerTemperature,
  ContainerTemperatureAnSdvg,
} from './weather.styled';
import { ITemperature } from './typesWeather';

export const TemperatureComponent: React.FC<ITemperature> = ({
  maxTemp,
  minTemp,
}) => {
  return (
    <ContainerTemperatureAnSdvg>
      <img
        style={{
          marginRight: '6px',
        }}
        src={TemperatureSvg}
        alt=""
        width="12px"
        height="12px"
        loading="lazy"
      />
      <ContainerTemperature>
        <P4>{`${Math.round(maxTemp)}°`}</P4>
        <P4>{`${Math.round(minTemp)}°`}</P4>
      </ContainerTemperature>
    </ContainerTemperatureAnSdvg>
  );
};
