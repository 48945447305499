import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const CuentaType = types.model('Cuenta').props({
  id: types.number,
  nombre: types.string,
  rol: types.string,
  estado: types.string,
  createdAt: types.maybeNull(types.string),
});
type CuentaModelType = Instance<typeof CuentaType>;
export type Cuenta = CuentaModelType;

export const UserDataType = types.model('UserData').props({
  id: types.number,
  cuentaActual: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  telefono: types.maybeNull(types.string),
  misCuentas: types.maybeNull(types.array(CuentaType)),
});
type UserDataModelType = Instance<typeof UserDataType>;
export type UserData = UserDataModelType;

export const UserType = types.model('UserType').props({
  id: types.number,
  modifiedAt: types.maybeNull(types.string),
  state: types.string,
  description: types.maybeNull(types.string),
});

export const UserModel = types.model('User').props({
  id: types.maybeNull(types.string),
});

type UserModelType = Instance<typeof UserModel>;
export type User = UserModelType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;
export const createUserDefaultModel = () => types.optional(UserModel, {});

export enum StateTraceStatus {
  SELECCIONCAMPOS = 'seleccion_campos',
  VALIDACIONCAMPOS = 'validacion_campos',
  ASIGNACIONHECTAREAS = 'asignacion_hectareas',
  REVISIONFIRMAS = 'revision_firmas',
  VALIDACIONDATOS = 'validacion_datos',
  FINALIZADO = 'finalizado',
}

export const CertificationEntityType = types
  .model('CertificationEntity')
  .props({
    id: types.number,
    nombre: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
  });
type CertificationEntityModelType = Instance<typeof CertificationEntityType>;
export type CertificationEntity = CertificationEntityModelType;

export const CertificationTypeType = types.model('CertificationType').props({
  id: types.number,
  nombre: types.maybeNull(types.string),
});
type CertificationTypeModelType = Instance<typeof CertificationTypeType>;
export type CertificationType = CertificationTypeModelType;

export const CampaignType = types.model('Campaign').props({
  id: types.number,
  nombre: types.string,
  certificacionDesde: types.string,
  certificacionHasta: types.string,
  descripcion: types.string,
  fin: types.number,
  inicio: types.string,
});
type CampaignModelType = Instance<typeof CampaignType>;
export type Campaign = CampaignModelType;

export const CertificationType = types.model('Certification').props({
  id: types.number,
  urlBase: types.maybeNull(types.string),
  leyenda: types.maybeNull(types.string),
  entidad: CertificationEntityType,
  tipo: CertificationTypeType,
  certificadora: CertificationEntityType,
  campaign: CampaignType,
  campana: CampaignType,
});
type CertificationModelType = Instance<typeof CertificationType>;
export type Certification = CertificationModelType;

export const ProvinceType = types.model('Province').props({
  id: types.number,
  nombre: types.string,
});
type ProvinceModelType = Instance<typeof ProvinceType>;
export type Province = ProvinceModelType;

export const DepartmentType = types.model('Department').props({
  id: types.number,
  nombre: types.string,
  provincia: ProvinceType,
});
type DepartmentModelType = Instance<typeof DepartmentType>;
export type Department = DepartmentModelType;

export const LocalityType = types.model('Locality').props({
  codigoPostal: types.string,
  dwId: types.number,
  id: types.number,
  nombre: types.string,
  partido: DepartmentType,
});
type LocalityModelType = Instance<typeof LocalityType>;
export type Locality = LocalityModelType;

const CampaignModel = types.model('Campaign').props({
  id: types.number,
  nombre: types.string,
  descripcion: types.string,
});

const LotTypeModel = types.model('LotType').props({
  id: types.number,
  nombre: types.string,
  isPlanificado: types.maybeNull(types.boolean),
  hasPoligono: types.maybeNull(types.boolean),
});

export const LoteModel = types.model('LotPolygon').props({
  hectareas: types.maybeNull(types.number),
  id: types.number,
  isPlanificado: types.maybeNull(types.boolean),
  nombre: types.string,
  poligono: types.optional(types.array(types.array(types.number)), []),
  especies: types.maybeNull(types.array(types.string)),
  color: types.maybeNull(types.string),
  nombrePoligono: types.maybeNull(types.string),
  assigned: types.maybeNull(types.boolean),
});

const CultivationModel = types.model('Cultivation').props({
  campana: types.maybeNull(CampaignModel),
  tipo: types.maybeNull(LotTypeModel),
  superficie: types.maybeNull(types.string),
  rinde: types.maybeNull(types.string),
});

const LotModel = types.model('Lot').props({
  id: types.number,
  nombre: types.string,
  cultivos: types.optional(types.array(CultivationModel), []),
});

export const EstablishmentModel = types.model('Establishment').props({
  id: types.number,
  nombre: types.string,
  lotes: types.optional(types.array(LotModel), []),
});

export const StateType = types.model('State').props({
  especies: types.maybeNull(types.array(types.string)),
  id: types.number,
  nombre: types.string,
  razonSocial: types.maybeNull(types.string),
  hectareas: types.maybeNull(types.number),
  poligono: types.maybeNull(types.array(types.array(types.number))),
  localidad: types.maybeNull(LocalityType),
  tsLocalidad: types.maybeNull(types.string),
  tsHectareas: types.maybeNull(types.string),
  tsPoligono: types.maybeNull(types.string),
  lotes: types.maybeNull(types.array(LotTypeModel)),
});
type StateModelType = Instance<typeof StateType>;
export type State = StateModelType;

export const StateTraceType = types.model('StateTrace').props({
  id: types.number,
  estado: types.string,
  hectareasTotales: types.maybeNull(types.string),
  hectareasAprobadas: types.maybeNull(types.string),
  hectareasDeclaradas: types.maybeNull(types.string),
  establecimiento: StateType,
});
type StateTraceModelType = Instance<typeof StateTraceType>;
export type StateTrace = StateTraceModelType;

export const TraceType = types.model('Trace').props({
  id: types.number,
  fechaCreacion: types.string,
  estado: types.string,
  establecimientos: types.array(StateTraceType),
  certificacion: CertificationType,
  fechaDdjj: types.string,
  archivosDdjj: types.string,
});
type TraceModelType = Instance<typeof TraceType>;
export type Trace = TraceModelType;

export const FirmanteType = types.model('Firmante').props({
  apellido: types.string,
  cuenta: types.number,
  dniDorso: types.string,
  dniFrente: types.string,
  domicilio: types.string,
  email: types.string,
  estadoCivil: types.string,
  firma: types.string,
  id: types.number,
  nombre: types.string,
  paisOrigen: types.maybeNull(types.string),
  politicamenteExpuesto: types.string,
  telefono: types.string,
});
type FirmanteModelType = Instance<typeof FirmanteType>;
export type Firmante = FirmanteModelType;

export const ContactDataType = types.model('Contact').props({
  apellido: types.string,
  cargo: types.string,
  email: types.string,
  cuit: types.string,
  nombre: types.string,
  telefono: types.string,
});
type ContactDataModelType = Instance<typeof ContactDataType>;
export type Contact = ContactDataModelType;

export const CuitType = types.model('Cuit').props({
  id: types.number,
  razonSocial: types.string,
  cuit: types.string,
  firmante: FirmanteType,
  contacto: ContactDataType,
});
type CuitModelType = Instance<typeof CuitType>;
export type Cuit = CuitModelType;
