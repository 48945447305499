import React, { useEffect, useState } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { Grass } from '@mui/icons-material';
import FieldsTable from 'components/common/tables/Fields';
import { P2, H3, H6 } from 'components/common/typography/styles';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import Content from 'components/layouts/content';
import SearchInput from 'components/common/inputs/search';
import ErrorMsgScreen from 'components/sections/errorMsgScreen';
import BackToTop from 'components/common/buttons/backToTop';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import { concatFieldsArr } from 'utils/mapUtils';
import { Field } from 'models/fields';
import NoFields from 'components/sections/fields/noFields';
import { ModalForm } from 'components/common/modals/ModalForm';
import AgregarCampo from 'assets/agregar-campo.svg';
import CreateFieldForm from 'components/common/forms/createField';
import Button from 'components/common/buttons/button';
import { Helmet } from 'react-helmet';
import { BannerOnesOil } from './BannerOnesOil';
import { observer } from 'mobx-react';
import api from 'services/api/api';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { OnesOilModal } from 'components/common/modals/onesOilModal';
import Loader from 'assets/loader-lupa.gif';
import Search from 'assets/search-success.svg';

const MyFieldsScreen: React.FC = () => {
  const {
    fieldsStore,
    fieldsStore: { fields, loadingFields },
    sessionStore: { isExternal, splitioOneSoilBanner },
  } = useStores();
  const [searchedField, setSearchedField] = useState<Field[]>(fields);
  const [isLoading, setLoading] = useState(true);
  const [isOpenCreateModal, setOpenCreateModal] = useState(false);
  const [openOnesOilModal, setOpenOnesOilModal] = useState(false);
  const [urlOnesOil, setUrlOnesOil] = useState<string | null>(null);
  const width = useMediaQuery('(min-width:768px)');
  const isMobile = useMediaQuery('(max-width: 425px)');
  const isTbalet = useMediaQuery('(max-width: 768px)');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackbarText = isTbalet
    ? 'Tu monitoreo satelital listo!'
    : 'Tu monitoreo satelital está listo!';

  const handleBannerClick = async () => {
    if (urlOnesOil) {
      closeSnackbar();
      setUrlOnesOil(null);
    }
    setOpenOnesOilModal(true);
    const result = await api.me.postToGetOnesOilLink();

    if (result.status === 409 && 'detail' in result.data) {
      enqueueSnackbar(result.data.detail, {
        variant: 'error',
      });
      setOpenOnesOilModal(false);
    } else if (result.status !== 200) {
      enqueueSnackbar(Strings.SomethingWentWrong, {
        variant: 'error',
      });
      setOpenOnesOilModal(false);
    } else {
      setUrlOnesOil(result.data.url);
      enqueueSnackbar(snackbarText, {
        autoHideDuration: 10000,
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        variant: 'onesOil',
        url: result.data.url,
      });
    }
    return null;
  };

  const getFields = async (onUpdate = false) => {
    if (!onUpdate) {
      setLoading(true);
    }
    fieldsStore.getMyFields();
    if (!onUpdate) {
      setLoading(false);
    }
  };

  const handleCreateFieldOnClose = () => {
    setOpenCreateModal(false);
    getFields(true);
  };

  useEffect(() => {
    getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const description = urlOnesOil
    ? 'Ahora podrás aprovechar nuestra nueva herramienta agrícola'
    : 'Estamos analizando tus datos para que puedas gestionar tus lotes de forma eficiente, esto puede demorar unos segundos. Gracias por tu paciencia.';

  const imgModal = urlOnesOil ? Search : Loader;
  const titleModalOnesOil = urlOnesOil
    ? 'Tu monitoreo satelital está listo'
    : null;

  if (isLoading || loadingFields)
    return (
      <Box
        width="100%"
        height="88vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="14px"
      >
        <SpinnerXL />
      </Box>
    );

  if (fields.length === 0) {
    if (isExternal) {
      return (
        <>
          <Box
            width="100%"
            display="flex"
            mt="24px"
            flexDirection="column"
            justifyContent="center"
            alignItems={{
              xs: 'center',
              md: 'start',
            }}
          >
            <Box mx="24px">
              <H3 color={colors.tertiaryLow} style={{ marginBottom: '16px' }}>
                Mis Campos
              </H3>
              <P2
                style={{
                  width: '100%',
                }}
              >
                En esta sección encontrarás toda la información relacionada a
                tus establecimientos.
              </P2>
            </Box>
            <NoFields openModal={() => setOpenCreateModal(true)} />
          </Box>
          <ModalForm
            open={isOpenCreateModal}
            handleClose={() => setOpenCreateModal(false)}
            title="Agregar campo"
            description="Completar tus campos te permite acceder a mejores productos y ofertas en Agrology."
            formChildren={
              <CreateFieldForm onClose={handleCreateFieldOnClose} />
            }
            image={AgregarCampo as string}
            iconChildren={
              <Grass
                sx={{ color: colors.complementaryGreen }}
                fontSize="large"
              />
            }
          />
        </>
      );
    }
    return (
      <Box display="flex" justifyContent="center" marginTop="25vh">
        <ErrorMsgScreen
          icon={
            <Grass sx={{ color: colors.tertiaryMedium, fontSize: '120px' }} />
          }
          titleError="No encontramos campos en tu sistema de gestión."
          subtitleError="Por favor, validá que estén cargados correctamente."
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflowY: 'scroll',
      }}
    >
      {isMobile && (
        <Box width="100%">
          <BannerOnesOil redirectTo={handleBannerClick} />
        </Box>
      )}
      <Content justifyContent="flex-start" scroll="unset">
        <Helmet>
          <title>Agrology | Mis campos</title>
        </Helmet>
        <OnesOilModal
          description={description}
          open={openOnesOilModal}
          handleClose={() => setOpenOnesOilModal(false)}
          withButton={!!urlOnesOil}
          icon={
            <img
              src={imgModal as string}
              width="150px"
              height="100px"
              alt="buscador"
            />
          }
          url={urlOnesOil || ''}
          title={titleModalOnesOil}
        />
        <ModalForm
          open={isOpenCreateModal}
          handleClose={() => setOpenCreateModal(false)}
          title="Agregar campo"
          description="Completar tus campos te permite acceder a mejores productos y ofertas en Agrology."
          formChildren={<CreateFieldForm onClose={handleCreateFieldOnClose} />}
          image={AgregarCampo as string}
          iconChildren={<Grass />}
        />
        {concatFieldsArr(searchedField).length >= 5 && <BackToTop />}
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          gap={1}
          alignItems={{ xs: 'start', tablet: 'start', lg: 'flex-end' }}
          flexDirection={{
            xs: 'column-reverse',
            tablet: 'column',
            lg: 'column',
          }}
          marginBottom="27px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap={1}
            alignItems={{ xs: 'start', tablet: 'start', lg: 'flex-end' }}
            flexDirection={{ xs: 'column', tablet: 'row', lg: 'row' }}
          >
            <Box>
              <H3 color={colors.tertiaryLow} style={{ marginBottom: '16px' }}>
                Mis Campos
              </H3>
              <P2
                style={{
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                En esta sección encontrarás toda la información relacionada a
                tus establecimientos.
              </P2>
            </Box>
            {isExternal && (
              <Box
                width="100%"
                maxWidth={{
                  xs: '100%',
                  tabletS: 'max-content',
                }}
                marginBottom="30px"
                marginTop="16px"
              >
                <Button
                  startIcon={<Grass />}
                  variant="contained"
                  preset="normal"
                  size="large"
                  text="Agregar campo"
                  onClick={() => setOpenCreateModal(true)}
                />
              </Box>
            )}
          </Box>
          {!isMobile && splitioOneSoilBanner && (
            <Box width="100%">
              <BannerOnesOil redirectTo={handleBannerClick} />
            </Box>
          )}
        </Box>
        <Box paddingBottom="24px" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom="8px"
            justifyContent="space-between"
          >
            {width && (
              <H6 color={colors.tertiaryMedium}>
                {concatFieldsArr(searchedField).length} campos listados
              </H6>
            )}
            <SearchInput
              id="searchInput"
              name="searchInput"
              placeholder="Buscar por nombre"
              fields={searchedField}
              setSearchedField={setSearchedField}
            />
          </Box>
          <Divider style={{ marginBottom: '16px' }} />
          {searchedField.length !== 0 ? (
            <FieldsTable
              searchedField={concatFieldsArr(searchedField)}
              gridStyle="misCampos"
              screen="MIS_CAMPOS"
              withWeather
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <ErrorMsgScreen
                hOffset="171px"
                icon={
                  <Grass
                    sx={{ color: colors.tertiaryMedium, fontSize: '120px' }}
                  />
                }
                titleError="No hay resultados"
                subtitleError="Intentá con otro nombre"
              />
            </Box>
          )}
        </Box>
      </Content>
    </Box>
  );
};

export default observer(MyFieldsScreen);
