export type ProcedureStateAttrs = {
  name: string;
  color: string;
  percentage: number;
  path: string;
  refusedView: boolean;
};

export type ProcedureStateType = {
  [key: string]: ProcedureStateAttrs;
};

type ProcedureStatesType = {
  [key: string]: ProcedureStateType;
};

export const procedureStates: ProcedureStatesType = {
  // Insurance
  seguro: {
    seleccion_campos: {
      name: 'Selección de campos',
      color: '#48BB88',
      percentage: 10,
      path: '',
      refusedView: true,
    },
    revision_informe: {
      name: 'Revisión de informe',
      color: '#48BB88',
      percentage: 23,
      path: '',
      refusedView: true,
    },
    monto_asegurar: {
      name: 'Monto a asegurar',
      color: '#48BB88',
      percentage: 41,
      path: '/monto',
      refusedView: true,
    },
    envio_solicitud: {
      name: 'Envío de solicitud',
      color: '#FFBB4E',
      percentage: 59,
      path: '',
      refusedView: false,
    },
    chequeo_agrology: {
      name: 'Envío de solicitud',
      color: '#FFBB4E',
      percentage: 64,
      path: '',
      refusedView: false,
    },
    cotizacion: {
      name: 'Cotización',
      color: '#48BB88',
      percentage: 76,
      path: '/cotizacion',
      refusedView: false,
    },
    solicitud_enviada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/finalizada',
      refusedView: false,
    },
  },
  // Sancor Seguros
  sancor: {
    seleccion_campos: {
      name: 'Selección de campos',
      color: '#48BB88',
      percentage: 10,
      path: '',
      refusedView: true,
    },
    revision_informe: {
      name: 'Revisión de informe',
      color: '#48BB88',
      percentage: 23,
      path: '',
      refusedView: true,
    },
    monto_asegurar: {
      name: 'Monto a asegurar',
      color: '#48BB88',
      percentage: 41,
      path: '/monto',
      refusedView: true,
    },
    envio_solicitud: {
      name: 'Envío de solicitud',
      color: '#FFBB4E',
      percentage: 59,
      path: '',
      refusedView: false,
    },
    chequeo_agrology: {
      name: 'Envío de solicitud',
      color: '#FFBB4E',
      percentage: 64,
      path: '',
      refusedView: false,
    },
    cotizacion: {
      name: 'Cotización',
      color: '#48BB88',
      percentage: 76,
      path: '/cotizacion',
      refusedView: false,
    },
    solicitud_enviada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/finalizada',
      refusedView: false,
    },
  },
  // Financing
  financiamiento: {
    seleccion_empresa: {
      name: 'Selección de empresa',
      color: '#48BB88',
      percentage: 10,
      path: '',
      refusedView: true,
    },
    generacion_informe: {
      name: 'Generación de informe',
      color: '#FFBB4E',
      percentage: 35,
      path: '',
      refusedView: true,
    },
    revision_informe: {
      name: 'Revisión de informe',
      color: '#48BB88',
      percentage: 65,
      path: '/revision',
      refusedView: false,
    },
    solicitud_enviada: {
      name: 'Solicitud enviada',
      color: '#48BB88',
      percentage: 100,
      path: '/solicitud',
      refusedView: false,
    },
    fin_rechazado: {
      name: 'Financiamiento rechazado',
      color: '#FF3C00',
      percentage: 100,
      path: '',
      refusedView: true,
    },
  },
  puma: {
    seleccion_empresa: {
      name: 'Seleccionar empresa',
      color: '#FFBB4E',
      percentage: 4,
      path: '/seleccion',
      refusedView: true,
    },
    seleccion_campos: {
      name: 'Seleccionar campos',
      color: '#48BB88',
      percentage: 22.5,
      path: '/campos',
      refusedView: true,
    },
    revision_cultivos: {
      name: 'Revisión de cultivos',
      color: '#48BB88',
      percentage: 40.8,
      path: '',
      refusedView: false,
    },
    envio_solicitud: {
      name: 'Solicitud enviada',
      color: '#FFBB4E',
      percentage: 59.2,
      path: '',
      refusedView: false,
    },
    solicitud_procesada: {
      name: 'Solicitud Procesada',
      color: '#48BB88',
      percentage: 100,
      path: '/finalizada',
      refusedView: false,
    },
    solicitud_terminada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/finalizado',
      refusedView: false,
    },
  },
  // Certifications
  certificaciones: {
    seleccionar_campos: {
      name: 'Seleccionar campos',
      color: '#FFBB4E',
      percentage: 8,
      path: '/solicitud',
      refusedView: true,
    },
    validacion_campos: {
      name: 'Campos en validación',
      color: '#FFBB4E',
      percentage: 27,
      path: '/solicitud',
      refusedView: true,
    },
    asignacion_hectareas: {
      name: 'Confirmar hectáreas',
      color: '#48BB88',
      percentage: 48,
      path: '/solicitud',
      refusedView: false,
    },
    revision_firmas: {
      name: 'Declaración jurada',
      color: '#48BB88',
      percentage: 71,
      path: '/solicitud',
      refusedView: false,
    },
    finalizado: {
      name: 'Certificación finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/solicitud',
      refusedView: false,
    },
    cert_rechazado: {
      name: 'Financiamiento rechazado',
      color: '#FF3C00',
      percentage: 100,
      path: '',
      refusedView: true,
    },
  },
  viterra: {
    seleccionar_campos: {
      name: 'Seleccionar campos',
      color: '#FFBB4E',
      percentage: 8,
      path: '/solicitud',
      refusedView: true,
    },
    validacion_campos: {
      name: 'Campos en validación',
      color: '#FFBB4E',
      percentage: 27,
      path: '',
      refusedView: true,
    },
    asignacion_hectareas: {
      name: 'Confirmar hectáreas',
      color: '#48BB88',
      percentage: 48,
      path: '/hectareas',
      refusedView: false,
    },
    revision_firmas: {
      name: 'Declaración jurada',
      color: '#48BB88',
      percentage: 71,
      path: '/firmantes',
      refusedView: false,
    },
    finalizado: {
      name: 'Certificación finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/finalizada',
      refusedView: false,
    },
  },
  agree: {
    seleccion_empresa: {
      name: 'Seleccionar empresa',
      color: '#48BB88',
      percentage: 4,
      path: '/seleccion',
      refusedView: true,
    },
    seleccion_productos: {
      name: 'Seleccionar productos',
      color: '#48BB88',
      percentage: 35,
      path: '/productos',
      refusedView: true,
    },
    solicitud_enviada: {
      name: 'Solicitud enviada',
      color: '#FFBB4E',
      percentage: 66,
      path: '',
      refusedView: false,
    },
    solicitud_finalizada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/solicitud',
      refusedView: false,
    },
  },
  // daruma
  daruma: {
    seleccion_empresa: {
      name: 'Selección de empresa',
      color: '#48BB88',
      percentage: 4,
      path: '',
      refusedView: true,
    },
    representante: {
      name: 'Representante',
      color: '#48BB88',
      percentage: 23.5,
      path: '/representante',
      refusedView: true,
    },
    info_adicional: {
      name: 'Información adicional',
      color: '#48BB88',
      percentage: 41,
      path: '/informacion-adicional',
      refusedView: true,
    },
    productos_adicionales: {
      name: 'Selección de productos',
      color: '#48BB88',
      percentage: 59,
      path: '/productos',
      refusedView: true,
    },
    envio_solicitud: {
      name: 'Solicitud enviada',
      color: '#FFBB4E',
      percentage: 77,
      path: '',
      refusedView: true,
    },
    solicitud_finalizada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100,
      path: '/solicitud',
      refusedView: true,
    },
  },

  // argenpymes
  argenpymes: {
    seleccion_empresa: {
      name: 'Selección de empresa',
      color: '#48BB88',
      percentage: 3, // queda para determinar
      path: '',
      refusedView: true,
    },
    envio_solicitud: {
      name: 'Solicitud enviada',
      color: '#FFBB4E',
      percentage: 50.05, // queda para determinar
      path: '/',
      refusedView: true,
    },
    solicitud_finalizada: {
      name: 'Solicitud finalizada',
      color: '#48BB88',
      percentage: 100, // queda para determinar
      path: '/solicitud',
      refusedView: true,
    },
  },
};

export const darumaWithoutArgenypmes = {
  seleccion_empresa: {
    name: 'Selección de empresa',
    color: '#48BB88',
    percentage: 4,
    path: '',
    refusedView: true,
  },
  representante: {
    name: 'Representante',
    color: '#48BB88',
    percentage: 27,
    path: '/representante',
    refusedView: true,
  },
  info_adicional: {
    name: 'Información adicional',
    color: '#48BB88',
    percentage: 50,
    path: '/informacion-adicional',
    refusedView: true,
  },
  envio_solicitud: {
    name: 'Solicitud enviada',
    color: '#FFBB4E',
    percentage: 73,
    path: '',
    refusedView: true,
  },
  solicitud_finalizada: {
    name: 'Solicitud finalizada',
    color: '#48BB88',
    percentage: 100,
    path: '/solicitud',
    refusedView: true,
  },
};

export const proceduresStatesMultiplexorClassMap: ProcedureStatesType = {
  // Certificaciones
  'viterra_2bsvs.Trazabilidad': { ...procedureStates.viterra },
  'puma_huella_carbono.Trazabilidad': { ...procedureStates.puma },
  // Financiamiento
  'financiacion_std.Trazabilidad': { ...procedureStates.financiamiento },
  'daruma.Trazabilidad': { ...procedureStates.daruma },
  'daruma.TrazabilidadArgenpymes': { ...procedureStates.argenpymes },
  'agree.Trazabilidad': { ...procedureStates.agree },
  // Seguros
  'seguro_multiriesgo.Trazabilidad': { ...procedureStates.sancor },
};
