import { types, Instance } from 'mobx-state-tree';

export const RolesType = types.model('Roles').props({
  codigo: types.string,
  id: types.number,
  nombre: types.string,
});
type RolesModelType = Instance<typeof RolesType>;
export type Roles = RolesModelType;

const TableroType = types.model('Tablero').props({
  descripcion: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  nombre: types.maybeNull(types.string),
});

const ItemType = types.model('Item').props({
  codigo: types.string,
  icono: types.string,
  id: types.number,
  nombre: types.string,
  tablero: TableroType,
  urlFront: types.string,
});

export const MenuType = types.model('Menu').props({
  categoria: types.string,
  codigo: types.string,
  icono: types.string,
  items: types.optional(types.array(ItemType), []),
  seccion: types.string,
  urlFront: types.string,
});
type MenuModelType = Instance<typeof MenuType>;
export type Menu = MenuModelType;
