import React from 'react';
import { SvgProps } from '..';

const BulletModalIcon: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '25'}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check-circle">
        <path
          id="Icon"
          d="M12 22.6055C17.5228 22.6055 22 18.1283 22 12.6055C22 7.08262 17.5228 2.60547 12 2.60547C6.47715 2.60547 2 7.08262 2 12.6055C2 18.1283 6.47715 22.6055 12 22.6055Z"
          stroke={color || '#48BB88'}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0056 6.4004C11.8775 6.39965 11.7514 6.43257 11.6401 6.49587C11.5288 6.55916 11.4361 6.65061 11.3713 6.76101L9.57112 9.84258L6.08618 10.5923C5.96089 10.6187 5.84476 10.6776 5.74944 10.7631C5.65412 10.8485 5.58296 10.9575 5.5431 11.079C5.50325 11.2006 5.4961 11.3305 5.52237 11.4558C5.54864 11.581 5.60741 11.6971 5.69278 11.7924L8.07166 14.455L7.71106 17.9998C7.69793 18.1268 7.7184 18.255 7.77042 18.3716C7.82244 18.4883 7.90419 18.5892 8.0075 18.6644C8.11081 18.7396 8.23205 18.7863 8.35912 18.8C8.48618 18.8137 8.61462 18.7938 8.73159 18.7424L11.9985 17.3085L15.2639 18.7495C15.3807 18.8003 15.5089 18.8196 15.6355 18.8056C15.7621 18.7916 15.8829 18.7447 15.9858 18.6697C16.0887 18.5946 16.1701 18.4939 16.222 18.3776C16.2739 18.2614 16.2945 18.1336 16.2816 18.0069L15.9238 14.4592L18.307 11.801C18.3924 11.7058 18.4512 11.5899 18.4776 11.4649C18.5041 11.3399 18.4972 11.2101 18.4576 11.0886C18.4181 10.967 18.3473 10.858 18.2523 10.7724C18.1573 10.6868 18.0415 10.6277 17.9164 10.6009L14.4272 9.84401L12.6342 6.76386C12.5702 6.65375 12.4786 6.56228 12.3683 6.49852C12.258 6.43475 12.133 6.40093 12.0056 6.4004ZM12.0041 8.57974L13.3297 10.8546C13.3805 10.942 13.4489 11.0179 13.5306 11.0776C13.6123 11.1372 13.7055 11.1792 13.8044 11.2009L16.3814 11.7625L14.6254 13.7266C14.5577 13.8015 14.5063 13.8895 14.4744 13.9852C14.4425 14.0808 14.4308 14.1821 14.4401 14.2825L14.7037 16.9094L12.2907 15.8447C12.1979 15.8032 12.0975 15.7816 11.9959 15.7811C11.8943 15.7806 11.7937 15.8013 11.7006 15.8419L9.29031 16.9066L9.55686 14.2812C9.56671 14.1804 9.55544 14.0787 9.52379 13.9825C9.49213 13.8863 9.44078 13.7977 9.373 13.7224L7.61557 11.7568L10.1954 11.1995C10.2948 11.1787 10.3887 11.1373 10.4711 11.0782C10.5536 11.019 10.6228 10.9434 10.6743 10.856L12.0041 8.57974Z"
            fill={color || '#48BB88'}
          />
        </g>
      </g>
    </svg>
  );
};

export default BulletModalIcon;
