/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

const SearchField = () => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider({
      params: {
        countrycodes: 'ar',
      },
    });

    // @ts-ignore (la doc sugiere este ignore)
    const searchControl = new GeoSearchControl({
      provider,
      style: 'bar',
      showMarker: false,
      searchLabel: 'Buscar ubicación...',
      countrycodes: 'ar',
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl) as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

export default SearchField;
