import { Box, Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import colors from 'theme/colors';
import { H6, P3 } from 'components/common/typography/styles';
import { HomeCardCounter } from 'components/sections/homeCounter';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type TCommonCardHome = {
  icon: React.ReactNode;
  title: string;
  description: string;
  complete: number;
  incomplete: number;
  isThereData: boolean;
  navigate: () => void;
};

export const CommonCardHome = ({
  icon,
  title,
  description,
  complete,
  incomplete,
  isThereData,
  navigate,
}: TCommonCardHome) => {
  return (
    <Paper
      sx={{
        width: '100%',
        flexGrow: 1,
        borderRadius: '8px',
        maxWidth: '100%',
        height: { xs: '150px', md: '100px' },
        cursor: 'pointer',
      }}
      onClick={navigate}
    >
      <Grid container alignItems="center" flexWrap="nowrap" height="100%">
        <Grid item height="100%">
          <div
            style={{
              width: '6px',
              height: '100%',
              background: !isThereData
                ? colors.tertiaryMedium
                : 'linear-gradient(90deg, #6CC72B 0%, #48BB88 100%)',
              borderTopLeftRadius: '15.53px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '15.53px',
              borderBottomRightRadius: '0px',
            }}
          />
        </Grid>
        <Grid
          container
          display="flex"
          direction={{
            xs: 'column',
            md: 'row',
          }}
          width="100%"
          alignItems="center"
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          gap="8px"
          padding="0 12px"
          flexWrap="nowrap"
        >
          <Stack
            gap="8px"
            direction={{
              xs: 'column',
              md: 'row',
            }}
            alignItems="center"
          >
            {icon}
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{
                xs: 'center',
                md: 'start',
              }}
              justifyContent={{
                xs: 'center',
                md: 'start',
              }}
            >
              <H6 sx={{ fontSize: '17px', textAlign: 'center' }}>{title}</H6>
              <P3
                sx={{
                  fontWeight: 500,
                  color: colors.tertiaryMedium,
                  textAlign: { xs: 'center', md: 'left' },
                  display: {
                    xs: !isThereData ? 'block' : 'none',
                    md: 'block',
                  },
                }}
              >
                {!isThereData
                  ? description
                  : `Totales: ${complete + incomplete}`}
              </P3>
            </Box>
          </Stack>
          {isThereData && (
            <Grid item xs={6}>
              <HomeCardCounter complete={complete} incomplete={incomplete} />
            </Grid>
          )}
          <Grid
            item
            xs="auto"
            display={{
              xs: 'none',
              md: 'flex',
            }}
            justifyContent="center"
          >
            <KeyboardArrowRightIcon sx={{ color: colors.tertiaryBase }} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
