import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import api from 'services/api/api';

export default function AgrologyIAIndexRoute() {
  const GROWKETING_URL = import.meta.env.VITE_CHAT_AI_URL;
  const [loading, setLoading] = useState<boolean>(true);
  const [chatToken, setChatToken] = useState<string>('');

  const getChatToken = async () => {
    const token = await api.chat.getChatAiToken();
    setChatToken(token.token);
    setLoading(false);
  };

  useEffect(() => {
    getChatToken();
  }, []);

  return (
    <>
      <Helmet>
        <title>Agrology | Agrology Chat AI</title>
      </Helmet>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="calc(100vh - 48px)"
        >
          <SpinnerXL />
        </Box>
      ) : (
        <Box width="100%">
          <Box position="relative" width="100%" height="calc(100vh - 48px)">
            <iframe
              style={{
                border: 'none',
                backgroundColor: 'white',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%',
              }}
              id="chat"
              src={`${GROWKETING_URL}?token=${chatToken}`}
              title="Groketing"
            />
          </Box>
        </Box>
      )}
    </>
  );
}
