/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'apisauce';
import { State } from 'store/fields-store/fieldsModel';
import { Api } from './api';
import { GeneralApiProblem, getGeneralApiProblem } from './api-problem';
import { ApiListResponse } from './meApi';
import { CultivosHCFinal } from 'models/certification';
import type {
  InsumosNoStandardResponse,
  InsumosPumaResponse,
  ResponseError,
  InsumoPuma,
  InsumoMatch,
  InsumoNoStandard,
  MatchInsumosResponse,
} from 'types/InsumosType';
import type {
  LaboresNoStandard,
  LaboresStandard,
  LaboresMatch,
} from 'types/LaboresType';

const shouldMock = false;
export class TracesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getCertifications(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/disponibles/certificacion/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async validateField(fieldId: number, alias: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/viterra_2bsvs/${alias}/campo/${fieldId}/validar`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data', error: 'Campos Invalidos' };
    }
  }

  async getFieldsAvailableForCertification(alias: string): Promise<any> {
    try {
      const response: ApiResponse<ApiListResponse<State>> =
        await this.api.apisauce.get(
          `/tramites/certificacion/viterra_2bsvs/${alias}/campos/`,
        );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCertification(id: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/disponibles/certificacion/${id}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createTrace(values: any, alias: string): Promise<any> {
    try {
      if (shouldMock) {
        return new Promise((resolve) => {
          resolve({ id: 12 });
        });
      }

      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/${alias}/iniciar/`,
        values,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getTrace(codigo: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/certificacion/${codigo}/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async assignHas(body: any, codigoTrazabilidad: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `tramites/certificacion/viterra_2bsvs/${codigoTrazabilidad}/asignar_hectareas/`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendDDJJ(body: any, codigoTrazabilidad: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/viterra_2bsvs/${codigoTrazabilidad}/enviar_ddjj/`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async saveHas(
    body: any,
    codigoTrazabilidad: string,
    idEstablecimiento: number,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `tramites/certificacion/viterra_2bsvs/${codigoTrazabilidad}/${idEstablecimiento}/guardar_hectareas/`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createHuellaCarbonoTrace(
    values: any,
    alias: string,
    approved: boolean,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/${alias}/iniciar/`,
        { ...values, acepto_puma: approved },
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createHuellaCarbonoFieldsToCertificate(
    values: { establecimientos: number[] },
    codigo: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/cert_emisiones/${codigo}/confirmar_campos/`,
        values,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async revisarCultivos(code: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/certificacion/cert_emisiones/${code}/revisar_cultivos/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async revisarCultivosId(code: string, idCultivo: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/certificacion/cert_emisiones/${code}/revisar_cultivos/${idCultivo}/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async enviarSolicitudHC(code: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/cert_emisiones/${code}/enviar_solicitud/`,
      );

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getHuellaCarbonoInfo() {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        '/tramites/disponibles/producto/cert_emisiones',
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getHuella(
    code: string,
  ): Promise<CultivosHCFinal[] | GeneralApiProblem> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/certificacion/cert_emisiones/${code}/obtener_huella/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;

      if (data) {
        return data;
      }

      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getInsumosNoStandard(
    traceCode: string,
  ): Promise<InsumoNoStandard[] | ResponseError> {
    try {
      const response: InsumosNoStandardResponse = await this.api.apisauce.get(
        `/tramites/certificacion/cert_emisiones/${traceCode}/insumos/no_standard`,
      );

      if (response.status === 200) {
        return response.data ?? [];
      }

      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getListaInsumosPuma(
    slug?: string,
  ): Promise<InsumoPuma[] | ResponseError> {
    try {
      const response: InsumosPumaResponse = await this.api.apisauce.get(
        `/tramites/certificacion/cert_emisiones/insumos/?slug=${slug}`,
      );

      if (response.status === 200) {
        return response.data ?? [];
      }

      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postMatchInsumos(
    body: {
      ids: InsumoMatch[];
    },
    code: string,
  ): Promise<ResponseError | []> {
    try {
      const response: MatchInsumosResponse = await this.api.apisauce.post(
        `/tramites/certificacion/cert_emisiones/${code}/insumos/create/`,
        body,
      );

      if (response.status === 201) {
        return response.data ?? [];
      }

      return { kind: 'bad-data' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getLaboresNoStandard(
    traceCode: string,
  ): Promise<LaboresNoStandard[] | ResponseError> {
    try {
      const response = await this.api.apisauce.get<
        LaboresNoStandard[],
        ResponseError
      >(
        `/tramites/certificacion/cert_emisiones/${traceCode}/labores/no_standard`,
      );

      if (response.status === 200) {
        return response.data ?? [];
      }

      return { kind: 'bad-data' };
    } catch (error) {
      return { kind: 'bad-data' };
    }
  }

  async getLaboresStandard(): Promise<LaboresStandard[] | ResponseError> {
    try {
      const response = await this.api.apisauce.get<
        LaboresStandard[],
        ResponseError
      >(`/tramites/certificacion/cert_emisiones/labores`);

      if (response.status === 200) {
        return response.data ?? [];
      }

      return { kind: 'bad-data' };
    } catch (error) {
      return { kind: 'bad-data' };
    }
  }

  async postLaboresMatch(
    traceCode: string,
    body: {
      ids: LaboresMatch[];
    },
    onLoading: (loading: boolean) => void,
  ) {
    try {
      const response = await this.api.apisauce.post<void, ResponseError>(
        `/tramites/certificacion/cert_emisiones/${traceCode}/labores/create`,
        body,
        {
          onDownloadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;

            if (!total) return;

            onLoading(loaded < total);
          },
        },
      );

      if (response.status === 201) {
        return response;
      }

      return { kind: 'bad-data' };
    } catch (error) {
      return { kind: 'bad-data' };
    }
  }
}
