import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import { P4 } from 'components/common/typography/styles';
import Icon from 'components/common/icon';
import colors from 'theme/colors';

export interface InitialDarumaCardProps {
  icon: string;
  list: Array<{ id: number; text: string }>;
  secondText?: string;
  iconWidth: string;
}
const InitialDarumaCard = ({
  icon,
  list,
  secondText = '',
  iconWidth,
}: InitialDarumaCardProps) => {
  const maxWidth = useMediaQuery('(max-width:767px)');
  const width = useMediaQuery('(max-width:400px)');

  return (
    <Card
      elevation={1}
      style={{
        width: '100%',
        maxWidth: maxWidth ? '280px' : '310px',
        height: width ? '350px' : '370px',
        borderRadius: '12px',
        border: '1px solid #E4E7EC',
      }}
    >
      <CardContent>
        <List
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={icon}
            alt="logo"
            style={{ width: iconWidth, marginBottom: '16px' }}
            loading="lazy"
          />
          {list.map((ele) => (
            <ListItem
              key={ele.id}
              style={{
                padding: '5px 0px 0px 0px',
                alignItems: 'flex-start',
              }}
            >
              <Box pr="12px">
                <Icon icon="smallBulletModal" />
              </Box>
              <P4
                style={{
                  marginTop: '3px',
                }}
              >
                {ele.text}
              </P4>
            </ListItem>
          ))}
          <P4
            color={colors.tertiaryMedium}
            style={{
              padding: '10px 0px 10px 0px',
              alignSelf: 'flex-start',
            }}
          >
            {secondText}
          </P4>
        </List>
      </CardContent>
    </Card>
  );
};

export default InitialDarumaCard;
