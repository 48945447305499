import React, { useState } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import ArgenpymesAlert from 'components/common/alerts/argenpymesAlert';
import { ICarrusel } from 'store/home-model/types';
import { useNavigate } from 'react-router-dom';

const BannerArgenpymes: React.FC<ICarrusel> = ({
  linkBoton,
  imagen,
  imagenMobile,
}) => {
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  const handleContactArgenpymes = async () => {
    if (linkBoton.startsWith('http')) {
      window.open(linkBoton, '_blank');
    } else {
      navigate(linkBoton);
    }
  };

  const imgDesktop = `${import.meta.env.VITE_API_BASE_URL}${imagen}`;
  const imgMobile = `${import.meta.env.VITE_API_BASE_URL}${imagenMobile}`;

  return (
    <CarouselContainer>
      <ArgenpymesAlert
        showModal={openAlert}
        handleClose={() => setOpenAlert(false)}
      />
      <Box className="carousel-mask" onClick={handleContactArgenpymes}>
        <picture>
          <source media="(max-width: 660px)" srcSet={imgMobile} />
          <source media="(min-width: 600px)" srcSet={imgDesktop} />
          <img
            src={imgDesktop}
            alt="carousel-img"
            loading="lazy"
            className="carousel-img"
          />
        </picture>
      </Box>
    </CarouselContainer>
  );
};

export default observer(BannerArgenpymes);

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 375px) {
    height: 320px;
  }

  @media (max-width: 768px) {
    height: 260px;
  }

  .carousel-mask {
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: 8px;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    @media (max-width: 375px) {
      height: 320px;
    }

    @media (max-width: 767px) {
      height: 260px;

      &::after {
        background: none;
      }
    }
  }

  .carousel-content {
    position: absolute;
    top: 76px;
    left: 15px;
    text-align: left;
    width: calc(100% - 30px);

    @media (min-width: 768px) {
      left: 60px;
    }

    @media (min-width: 860px) {
      width: 680px;
    }
  }

  .carousel-img {
    object-fit: cover;
    width: 100%;
    height: 260px;
    object-position: center;

    @media (min-width: 375px) {
      height: 260px;
    }

    @media (min-width: 768px) {
      height: 260px;
      object-position: center;
    }

    @media (min-width: 860px) {
      height: 270px;
    }

    @media (min-width: 1024px) {
      height: 290px;
    }
  }

  .carousel-daruma {
    width: calc(120px * 0.75);

    @media (min-width: 425px) {
      width: 120px;
    }
  }

  .carousel-argenpymes {
    width: calc(140px * 0.75);

    @media (min-width: 425px) {
      width: 140px;
    }
  }
`;
