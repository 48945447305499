import { useState } from 'react';

export interface useHandleStepsReturn {
  activeStep: number;
  direction: 'left' | 'right';
  handleNextStep: () => void;
  handlePrevStep: () => void;
  setNextStep: (step: number) => void;
}

export const useHandleSteps: () => useHandleStepsReturn = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [direction, setDirection] = useState<'left' | 'right'>('right');

  const handleNextStep = () => {
    setDirection('right');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevStep = () => {
    if (activeStep === 0) return;
    setDirection('left');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setNextStep = (step: number) => {
    setActiveStep(step);
  };

  return { activeStep, direction, handleNextStep, handlePrevStep, setNextStep };
};
