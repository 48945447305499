import { Box, Grid, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { H3, H4, P3 } from 'components/common/typography/styles';
import { lowerCase } from 'lodash';
import colors from 'theme/colors';
import { Grass } from '@mui/icons-material';
import FieldOptions from 'components/common/dropdowns/fieldOptions';
import React, { useEffect, useState } from 'react';
import ModifyFieldNameModal from 'components/common/modals/modifyFieldName';
import DeleteFieldModal from 'components/common/modals/deleteField';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { ErrorCannotDeleteModal } from '../../../modals/deleteCompanyError';
import api from 'services/api/api';

export type TitleProps = {
  title: string;
  id: number;
  onClose: () => void;
};

const Title = ({ title, id, onClose }: TitleProps) => {
  const { sessionStore, fieldsStore } = useStores();
  const [isOpenModalName, setOpenModalName] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openErrorCompanyModal, setOpenErrorCompanyModal] = useState(false);
  const [entities, setEntities] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeName = async (newName: string) => {
    const payload = {
      nombre: newName,
    };
    const result = await fieldsStore.updateField(id, payload);

    if (!result.kind) {
      enqueueSnackbar(Strings.notifications.fields.updatedField, {
        variant: 'success',
      });
      setOpenModalName(false);
    } else {
      enqueueSnackbar(Strings.notifications.fields.updatedFieldError, {
        variant: 'error',
      });
    }
  };

  const handleDeleteField = async () => {
    setLoading(true);
    const response = await fieldsStore.deleteField(id);

    if (response.ok) {
      enqueueSnackbar(Strings.notifications.fields.deletedField, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(
        `El campo ${title} no puede ser eliminada porque se encuentra registrada en tramites.`,
        {
          variant: 'error',
        },
      );
    }
    setOpenDeleteModal(false);
    setLoading(false);
  };

  const fetchEntities = async () => {
    const res = await api.procedure.getassociatedAccounts('campo', id);
    setEntities(res);
  };

  const isThereassociatedAccounts = entities.length > 0;

  const handleModal = () =>
    isThereassociatedAccounts
      ? setOpenErrorCompanyModal(true)
      : setOpenDeleteModal(true);

  useEffect(() => {
    fetchEntities();
  }, [id]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      margin={{ xs: '16px', sm: '20px' }}
    >
      <DeleteFieldModal
        title="¿Eliminar campo?"
        description="Estas por eliminar el campo"
        name={title}
        open={isOpenDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={() => handleDeleteField()}
        isLoading={isLoading}
      />
      <ModifyFieldNameModal
        open={isOpenModalName}
        name={title}
        handleChangeName={handleChangeName}
        handleClose={() => setOpenModalName(false)}
      />

      {entities.length > 0 ? (
        <ErrorCannotDeleteModal
          title="Error al eliminar el campo"
          description={
            <Box width="300px">
              <P3 color={colors.tertiaryMedium}>
                El campo <b>{title}</b>, actualmente no puede ser eliminado
                porque se encuentra registrado en:
              </P3>
            </Box>
          }
          open={openErrorCompanyModal}
          associatedAccounts={entities}
          handleClose={() => setOpenErrorCompanyModal(false)}
        />
      ) : null}

      <Grid
        container
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs="auto"
          sm
          order={{
            xs: 2,
            sm: 1,
          }}
        >
          <Stack direction="row" gap="12px" alignItems="center">
            <Box>
              <Grass
                sx={{
                  height: { xs: '24px', sm: '40px' },
                  width: { xs: '24px', sm: '40px' },
                  color: colors.tertiaryBase,
                }}
              />
            </Box>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <H3
                color={colors.tertiaryBase}
                sx={{
                  textTransform: 'capitalize',
                  maxWidth: sessionStore.isExternal ? '230px' : '250px',
                  wordBreak: 'break-word',
                }}
              >
                {lowerCase(title)}
              </H3>
            </Box>
            <Box display={{ xs: 'block', sm: 'none' }}>
              <H4
                color={colors.tertiaryBase}
                sx={{ textTransform: 'capitalize' }}
              >
                {lowerCase(title)}
              </H4>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs="auto"
          order={{
            xs: 3,
            sm: 2,
          }}
        >
          <Box>
            {sessionStore.isExternal && (
              <FieldOptions
                setNewFieldName={() => setOpenModalName(true)}
                setDeleteField={handleModal}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          order={{
            xs: 1,
            sm: 3,
          }}
        >
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: colors.tertiaryLow }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default observer(Title);
