import React from 'react';
import { StyledRadio } from '../index.styled';

interface RadioProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  error?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  name?: string;
  id?: string;
  value?: string;
}

/**
 * InputRadio component must be used with the RadioGroup
 * and FormControlLabel components (MUI)
 */
const InputRadio: React.FC<RadioProps> = (props) => {
  return <StyledRadio {...props} disableRipple />;
};

export default InputRadio;
