import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { useRollbar, Provider as RollbarProvider } from '@rollbar/react';
import rollbarConfig from 'utils/rollbar-config';

export default {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  unhandledError(msg?: string | Error) {
    this.toast(
      'Estamos teniendo inconvenientes para realizar esta operación.',
      'error',
      msg,
    );
  },

  toast(msg: string, variant: string, originalError?: string | Error) {
    const Display = ({ message }: { message: string }) => {
      const rollbar = useRollbar();

      if (variant === 'error') {
        if (typeof originalError === 'string') {
          rollbar.error(originalError);
        } else if (originalError instanceof Error) {
          rollbar.error(originalError.message);
        }
      }

      const { enqueueSnackbar } = useSnackbar(); // Import useSnackbar from 'notistack'
      enqueueSnackbar(message, { variant: variant as any }); // ToDO reemplazar el any por VariantType

      return null;
    };
    const mountPoint = document.getElementById('snackbarhelper');
    ReactDOM.render(
      <RollbarProvider config={rollbarConfig}>
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Display message={msg} />
        </SnackbarProvider>
      </RollbarProvider>,
      mountPoint,
    );
  },
};