import { lazy } from 'react';

export const LazyDarumaSelection = lazy(
  () => import('./SeleccionEmpresa/DarumaSelection'),
); // seleccion
export const LazyJuricalInversor = lazy(() => import('./PerfilInversor')); // informacion-adicional => juridico
export const LazyPhysicalInvestor = lazy(
  () => import('./PerfilInversor/PhisicalInvestor'),
);
export const LazySolicitudEnviada = lazy(
  () => import('./EnvioSolicitud/index'),
); // solicitud
export const LazyDarumaProducts = lazy(() => import('./Productos')); // productos
export const LazyRepresentantForm = lazy(
  () => import('components/common/forms/representantForm'),
);
export const LazyAboutBusiness = lazy(
  () => import('components/sections/AboutBusiness'),
);
