import { CommonProcedureModalCard } from 'components/sections/cards/commonProcedureModalCard';
import { Stack } from '@mui/material';
import colors from 'theme/colors';
import { P3 } from 'components/common/typography/styles';
import { useNavigate } from 'react-router-dom';
import { cardListCommonProcedureModal } from './componenArray';

export const CardListCommonProcedureModal = ({
  isMobile,
}: {
  isMobile: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Stack direction="column" gap="16px">
      <P3 color={colors.tertiaryMedium} style={{ textAlign: 'center' }}>
        Te recomendamos que revises y completes estos datos requeridos para la
        solicitud:
      </P3>
      {cardListCommonProcedureModal(isMobile).map((e: any, i: number) => (
        <CommonProcedureModalCard
          textButton={e.textButton}
          numberList={i + 1}
          title={e.title}
          descriptionChildren={e.description}
          actionButton={() => navigate(e.redirectTo)}
          startIcon={e.startIcon}
          isMobile={isMobile}
        />
      ))}
    </Stack>
  );
};
