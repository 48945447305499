import styled from 'styled-components';
import { Drawer } from '@mui/material';
import colors from 'theme/colors';

interface StyledDrawerProps {
  width?: string;
  height?: string;
  backgroundcolor?: string;
  windowswidth?: number;
  marginTitle?: string;
  marginSubTitle?: string;
}
interface StyledDrawerCuitProps {
  width?: string;
  height?: string;
  backgroundcolor?: string;
}

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
  .drawer-container {
    position: relative;
    height: 100vh;
    width: ${(props) => props.width ?? '430px'};

    @media (max-width: 767px) {
      width: 100vw;
      padding: 0px;
    }
  }

  .drawer-container-cuit {
    position: relative;
    width: ${(props) => props.width ?? '800px'};
    padding: ${(props) => (props.width ? '0px' : '0px 25px')};
    overflow-y: auto;
    background-color: ${(props) => props.backgroundcolor};

    @media (max-width: 767px) {
      width: calc(100vw - 32px);
      padding: 0 16px 16px 16px;
    }
  }

  .drawer-title {
    margin: ${(props) =>
      props.marginTitle ? props.marginTitle : '24px 0 6px 0'};
    color: ${colors.tertiaryBase};
  }

  .drawer-description {
    color: ${colors.tertiaryMedium};
    margin: ${(props) => (props.marginSubTitle ? props.marginSubTitle : '0px')};
  }
`;

export const StyledDrawerCuit = styled(Drawer)<StyledDrawerCuitProps>`
  .drawer-container {
    position: relative;
    height: 100vh;
    width: 90vw;
    padding: ${(props) => (props.width ? '0px' : '0px 25px')};

    @media (max-width: 767px) {
      width: 100%;
      padding: 0px 16px;
    }
  }

  .drawer-container-cuit {
    position: relative;
    width: ${(props) => props.width ?? '800px'};
    overflow-y: auto;
    background-color: ${(props) => props.backgroundcolor};

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .drawer-title {
    margin: 24px 0 6px 0;
    color: ${colors.tertiaryBase};
  }

  .drawer-description {
    color: ${colors.tertiaryMedium};
  }
`;
export const StyledMenuDrawer = styled(StyledDrawer)`
  .drawer-container {
    @media (max-width: 767px) {
      width: calc(85vw);
      padding: 0px;
    }
  }
`;

export const StyledMapDrawer = styled(Drawer)`
  .drawer-container {
    width: 90vw;
    display: flex;

    @media (min-width: 1750px) {
      min-width: 1440px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      overflow-y: hidden;
      width: 100vw;
    }

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .drawer-title {
    margin-bottom: 8px;
    color: ${colors.tertiaryMedium};
  }

  .drawer-description {
    color: ${colors.tertiaryMedium};
    max-width: 1060px;
  }

  .drawer-header {
    height: 80px;
    padding: 22px 19px;
    background-color: ${colors.tertiaryHigh};
    @media (max-width: 767px) {
      height: 50px;
    }
  }
`;
