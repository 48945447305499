import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { NewEmailIcon } from 'components/common/icon/icons/newEmailIcon';
import { H1, P3 } from 'components/common/typography/styles';
import { StyledButton } from 'components/common/buttons/button/index.styled';
import { registerExternal } from 'services/api/authApi';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

export interface ConfirmacionEmail {
  userCredentials: {
    email: string;
    password: string;
    token: string;
  };
}

export const ConfirmacionEmail = ({ userCredentials }: ConfirmacionEmail) => {
  const { enqueueSnackbar } = useSnackbar();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [waitTime, setWaitTime] = useState<number>(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (waitTime > 0) {
      timeout = setTimeout(() => setWaitTime(waitTime - 1), 1000);
    }

    return () => clearTimeout(timeout);
  }, [waitTime]);

  return (
    <Box width="100%">
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        size="invisible"
        hl="es"
      />
      <H1 className="auth-title" sx={{ fontWeight: 350 }}>
        Verificá tu dirección de email
      </H1>
      <Box my="20px" display="flex" justifyContent="center">
        <NewEmailIcon />
      </Box>
      <P3 sx={{ marginBottom: '24px' }}>
        Revisá la bandeja de entrada y correo no deseado del mail:{' '}
        <b>{userCredentials.email}</b> y confirma tu registro para iniciar
        sesion
      </P3>

      <StyledButton
        variant="contained"
        size="large"
        disabled={waitTime > 0}
        sx={{
          padding: '12px 16px',
          fontSize: '20px',
        }}
        fullWidth
        onClick={async () => {
          if (waitTime > 0) return;

          const token =
            (await recaptchaRef?.current?.executeAsync()) || 'token-recaptcha';

          setWaitTime(30);

          registerExternal({
            email: userCredentials.email,
            password: userCredentials.password,
            recaptchaToken: token,
            onSuccess: () => {
              enqueueSnackbar('Mail reenviado', {
                variant: 'success',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            },
            onFailure: () => {
              enqueueSnackbar('No se pudo reenviar el mail', {
                variant: 'error',
                autoHideDuration: 1500,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            },
          });
          recaptchaRef?.current?.reset();
        }}
      >
        Reenviar email
      </StyledButton>

      {waitTime >= 1 && (
        <Box mt="16px" width="95%">
          <P3>
            Si no has recibido nuestro correo, te pedimos esperar{' '}
            <TimerComponent time={waitTime} /> antes de solicitar su reenvío.
          </P3>
        </Box>
      )}
    </Box>
  );
};

const TimerComponent = ({ time }: { time: number }) => (
  <span>
    <strong>{time} segundos</strong>
  </span>
);

