import { TabsList } from '@mui/base/TabsList';
import { useMediaQuery } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { CustomMapTabOutline, CustomTabOutline } from '../tabs/CustomTab';
import colors from 'theme/colors';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';
import { tabModes } from 'utils/common';

type WarnTabIcon = {
  error: string;
  alias: string;
};

type Props = {
  fieldId: number;
  alias: string | undefined;
};

const WarningTabIcon = ({ error, alias }: WarnTabIcon) => {
  if (Boolean(error) && alias === 'huella_carbono') {
    return <WarningRoundedIcon sx={{ color: colors.complementaryYellow }} />;
  }

  return null;
};

const TabsOutline = ({ alias, fieldId }: Props) => {
  const maxWidth = useMediaQuery('(max-width:767px)');
  const { fieldsStore } = useStores();

  if (maxWidth) {
    return (
      <TabsList
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CustomMapTabOutline
          icon={
            <WarningTabIcon
              error={fieldsStore.isTabValid(fieldId, 'CAMPO', 'HC_PUMA').error}
              alias={alias || ''}
            />
          }
          value={tabModes.CAMPO}
          disabled={false}
          border={fieldsStore.splitioTabMode ? '8px 0px 0px 8px' : '8px'}
        >
          Campo
        </CustomMapTabOutline>
        {fieldsStore.splitioTabMode && (
          <CustomMapTabOutline
            icon={
              <WarningTabIcon
                error={
                  fieldsStore.isTabValid(fieldId, 'LOTES', 'HC_PUMA').error
                }
                alias={alias || ''}
              />
            }
            value={tabModes.LOTES}
            disabled={false}
            border="0px 8px 8px 0px"
          >
            Lotes
          </CustomMapTabOutline>
        )}
      </TabsList>
    );
  }

  return (
    <TabsList
      style={{
        display: 'flex',
        justifyContent: fieldsStore.splitioTabMode ? 'center' : 'flex-start',
        width: 'calc(100% - 16px)',
        margin: '0 8px',
      }}
    >
      <CustomMapTabOutline
        icon={
          <WarningTabIcon
            error={fieldsStore.isTabValid(fieldId, 'CAMPO', 'HC_PUMA').error}
            alias={alias || ''}
          />
        }
        disabled={false}
        value={tabModes.CAMPO}
        border="8px 0px 0px 8px"
      >
        Campo
      </CustomMapTabOutline>
      {fieldsStore.splitioTabMode && (
        <CustomMapTabOutline
          icon={
            <WarningTabIcon
              error={fieldsStore.isTabValid(fieldId, 'LOTES', 'HC_PUMA').error}
              alias={alias || ''}
            />
          }
          disabled={false}
          value={tabModes.LOTES}
          border="0px 8px 8px 0px"
        >
          Lotes
        </CustomMapTabOutline>
      )}
    </TabsList>
  );
};

export default observer(TabsOutline);
