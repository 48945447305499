import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import TextInput from 'components/common/inputs/text';
import { P3 } from 'components/common/typography/styles';
import LinkBtn from 'components/common/buttons/linkBtn';
import colors from 'theme/colors';
import api from 'services/api/api';
import { RecoverAccountSchema } from 'utils/schema-validations';
import Button from '../buttons/button';

enum FieldNames {
  fieldEmail = 'email',
}

const RecoverAccountForm = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);

  const initialValues = {
    [FieldNames.fieldEmail]: '',
  };

  const sendRecoverEmail = async (values: { email: string }) => {
    const response = await api.passwords.recoverAccount(values);
    if (response.result && response.result === 'ok') {
      navigate({
        pathname: '/portal/solicitud-recibida',
        search: `?email=${values[FieldNames.fieldEmail]}`,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={sendRecoverEmail}
      validationSchema={RecoverAccountSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }
        return (
          <>
            <TextInput
              id={FieldNames.fieldEmail}
              label="Tu email"
              disabled={false}
              maxLength={254}
              name={FieldNames.fieldEmail}
              width="421px"
              height="48px"
              type="email"
              handleSubmit={handleSubmit}
              placeholder="Ingresá tu email"
            />
            <Box
              display="flex"
              flexDirection={{
                xs: 'column',
                laptop: 'row',
              }}
              alignItems={{
                xs: 'center',
                laptop: 'center',
              }}
              justifyContent={{
                xs: 'center',
                laptop: 'flex-start',
              }}
              gap="15px"
              mt="24px"
              width="100%"
            >
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={() => handleSubmit()}
                text="Recuperar cuenta"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              />
              <LinkBtn
                onClick={() =>
                  navigate('/portal/iniciar-sesion', { replace: true })
                }
              >
                <P3
                  style={{
                    color: colors.complementaryGreen,
                    textDecoration: 'underline',
                    textUnderlineOffset: '1px',
                  }}
                >
                  Volver al inicio
                </P3>
              </LinkBtn>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default RecoverAccountForm;
