import React, { useState } from 'react';
import { Box, Collapse, Stack } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { P3, P4 } from 'components/common/typography/styles';
import FieldDataForm from 'components/common/forms/fieldData';
import { Field } from 'models/fields';
import colors from 'theme/colors';
import Resume from './resume';

type LocationModuleProps = {
  onSubmit: () => void;
  field: Field;
};

type ArrowCollapseProps = {
  isCollapsed: boolean;
};

const ArrowCollapse: React.FC<ArrowCollapseProps> = ({ isCollapsed }) => {
  return (
    <Box
      sx={{
        transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <KeyboardArrowDownRoundedIcon
        sx={{ fontSize: '16px', color: colors.tertiaryLow }}
      />
    </Box>
  );
};

const LocationModule: React.FC<LocationModuleProps> = (props) => {
  const { onSubmit, field } = props;
  const [isCollapsed, setCollapse] = useState(false);

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt="16px"
        mb="12px"
      >
        <P3 sx={{ fontWeight: '600', color: colors.tertiaryLow }}>Ubicación</P3>
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => setCollapse(!isCollapsed)}
          sx={{ cursor: 'pointer' }}
        >
          <P4 sx={{ fontWeight: '600', color: colors.tertiaryLow }}>
            Completar datos
          </P4>
          <ArrowCollapse isCollapsed={isCollapsed} />
        </Stack>
      </Stack>
      <Collapse in={!isCollapsed}>
        <Resume localidad={field.localidad} />
      </Collapse>
      <Collapse in={isCollapsed}>
        <FieldDataForm field={field} onSubmit={onSubmit} />
      </Collapse>
    </Stack>
  );
};

export default LocationModule;
