import { useLocation, useNavigate } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { IconMenu, StyledListItem } from '../styles';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';

type ItemMenuProps = {
  seccion: string;
  icono: string;
  codigo: string;
  urlFront: string;
};

export const ItemMenu = ({
  seccion,
  icono,
  codigo,
  urlFront,
}: ItemMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const current =
    location.pathname === urlFront ||
    (location.pathname.startsWith(urlFront) && urlFront !== '/');

  return (
    <StyledListItem
      selected={current}
      button
      id={codigo}
      onClick={() => navigate(urlFront)}
      secondaryAction={<ChevronRight />}
    >
      {icono && (
        <ListItemIcon sx={{ minWidth: 0 }}>
          <IconMenu current={current}>
            <img loading="lazy" src={icono} alt={codigo} />
          </IconMenu>
        </ListItemIcon>
      )}
      <P3 style={{ paddingLeft: 10 }} color={colors.black}>
        {seccion}
      </P3>
    </StyledListItem>
  );
};
