import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import TextInput from '../inputs/text';
import Button from '../buttons/button';
import { CreateFieldSchema } from 'utils/schema-validations';
import api from 'services/api/api';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';

enum FieldNames {
  fieldName = 'name',
}

type Props = {
  onClose: () => void;
};

const CreateFieldForm = ({ onClose }: Props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    [FieldNames.fieldName]: '',
  };

  const postField = async (values: { name: string }) => {
    const response = await api.me.createField({
      nombre: values[FieldNames.fieldName],
    });
    if (response.id) {
      enqueueSnackbar(Strings.notifications.success, {
        variant: 'success',
      });
      onClose();
    }

    if (response.kind === 'bad-data') {
      enqueueSnackbar(Strings.notifications.fields.alreadyExists, {
        variant: 'error',
        persist: false,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={postField}
      validationSchema={CreateFieldSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }

        return (
          <Stack gap="32px">
            <Box width="100%">
              <TextInput
                id={FieldNames.fieldName}
                label="Nombre del campo"
                disabled={false}
                name={FieldNames.fieldName}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Nuestro amanecer"
              />
            </Box>
            <Stack direction="row">
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Confirmar campo"
                disabled={isSubmitting && !!errors}
                loading={isSubmitting}
              />
            </Stack>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default CreateFieldForm;
