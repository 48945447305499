import React from 'react';
import { Box } from '@mui/material';
import NewPasswordForm from 'components/common/forms/newPassword';
import { H1, P2 } from 'components/common/typography/styles';
import { AuthContainer } from '../index.styled';

const RecoverVerify: React.FC = () => {
  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="auth-title">Nueva contraseña</H1>
        <P2 className="auth-parag" style={{ marginBottom: '24px' }}>
          Creá una nueva contraseña para acceder a tu cuenta nuevamente.
        </P2>
        <NewPasswordForm />
      </Box>
    </AuthContainer>
  );
};

export default RecoverVerify;
