import React, { useContext } from 'react';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import StepProcedureContainer from 'components/common/cards/stepProcedure';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { DarumaStateContext } from 'contexts/Daruma';
import { LazyDarumaSelection } from '../lazyDaruma';

export const RoutesWithoutProcedure = () => {
  const { stateProcedure } = useContext(DarumaStateContext);
  if (stateProcedure === 'seleccion_empresa')
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <LazyDarumaSelection />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );
  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
