
import { Box } from '@mui/material';
import BannerOnesOilImg from 'assets/ones-oil-banner.png';
import BannerOnesOilImgMobile from 'assets/onesoil-banner-mobile.png';


export const BannerOnesOil = ({ redirectTo }: { redirectTo: () => void }) => {
  return (
      <Box style={{ cursor: 'pointer' }} onClick={redirectTo}>
        <picture>
          <source media="(min-width: 426px)" srcSet={BannerOnesOilImg as string} />
          <source media="(max-width: 425px)" srcSet={BannerOnesOilImgMobile as string} />
          <img
            src={BannerOnesOilImg as string}
            alt="onesoil"
            loading="lazy"
            width="100%"
            height="auto"
          />
        </picture>
      </Box>
  );
};