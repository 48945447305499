import { ApiResponse } from 'apisauce';
import { PayloadFinishInsurace } from 'models/insurance';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';
import { Strings } from 'constants/strings';

export interface ApiListResponse<T> {
  data: T[];
}
export class ProcedureApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAvailableProcedures(procedure: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/disponibles/${procedure}/`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getSelectedProcedure(aliasProducto: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/tramites/disponibles/producto/${aliasProducto}/`,
      );

      if (response.status !== 200) {
        if (response && response.data && response.data.detail) {
          return response.data;
        }
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response.data;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getProcedures(procedure: string, finished = 0): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/${procedure}/?finalizadas=${finished}`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async startProcedure(
    procedure: string,
    product: string,
    body: any,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/${procedure}/${product}/iniciar/`,
        body,
      );

      if (response.status !== 201) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getProcedureDetail(procedure: string, code: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/${procedure}/${code}/`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendProcedureRequest(
    procedure: string,
    product: string,
    code: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/tramites/${procedure}/${product}/${code}/`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendProductToProcedure(url: string, ids: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(url, {
        productosIds: ids.arg,
      });

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getContactData(cuit: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/contacto/${cuit}`,
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async putContactData(form: any, cuit: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/tramites/contacto/${cuit}`,
        form,
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postContactData(form: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/contacto/`,
        form,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postInsuranceFinish(
    payload: PayloadFinishInsurace,
    producto: string,
    code: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/seguro/${producto}/${code}/finalizar/`,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postCultivoAsegurar(
    product: string,
    code: string,
    payload: any,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `tramites/seguro/${product}/${code}/monto_asegurar/`,
        payload,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCountryData(): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agrology/paises`,
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getAgreeProducts(
    cuitSolicitante: string | number,
    cuitInsumos?: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agree/productos?cuit_solicitante=${cuitSolicitante}&cuit_insumos=${cuitInsumos}`,
      );
      return response;
    } catch (e: any) {
      return { error: e.message };
    }
  }

  async getAgreeLink(idTramite: string): Promise<any> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/agree/external_link/${idTramite}`,
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  async getassociatedAccounts(
    screen: 'empresa' | 'lote' | 'campo',
    id: number,
  ): Promise<any> {
    const urlToFetch = () => {
      if (screen === 'empresa')
        return `tramites/productos/asociados/?empresa_id=${id}`;
      if (screen === 'lote')
        return `tramites/productos/asociados/?lote_id=${id}`;
      return `tramites/productos/asociados/?establecimiento_id=${id}`;
    };
    try {
      const response: ApiResponse<any> = await this.api.apisauce.get(
        urlToFetch(),
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}