import { useCallback, useEffect, useState } from 'react';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { Box } from '@mui/material';
import { ProcedureInProcess, ProcedureStatus } from 'models/procedure';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { useParams } from 'react-router-dom';
import { useViterraContext } from '../useViterraContext';
import {
  LazyNewCertificationAssignScreen,
  LazyNewCertificationReviewScreen,
  LazyNewCertificationValidationScreen,
  LazyFinishedCertificationScreen,
} from '../lazyRoutes';

export const RoutesWithProcedure = () => {
  const { state, dispatch } = useViterraContext();
  const { code, alias } = useParams();
  const [procedure, setProcedure] = useState<ProcedureInProcess | null>(null);
  const { procedureHeaderStore } = useStores();
  const getProcedureInfo = useCallback(async () => {
    if (code && alias) {
      const response = await api.traces.getTrace(code);
      dispatch({ type: 'set-stateProcedure', value: response.estado });
      setProcedure(response);
      const logos = [];

      if (response.producto.logo) {
        logos.push(response.producto.logo);
      }

      if (response.producto.entidad.logo) {
        logos.push(response.producto.entidad.logo);
      }
      const isAprove = response?.establecimientos.map(
        (plant: any) => plant.estado !== 'rechazado',
      );
      const status = () => {
        if (isAprove.includes(true) && state.stateProcedure === 'finalizado')
          return 'finished';
        if (isAprove.includes(false) && state.stateProcedure === 'finalizado')
          return 'rejected';
        return 'ongoing';
      };
      const procedureInfo = {
        alias: response.producto.alias as string,
        productName: response.producto.nombre as string,
        markdownTemplate: response.producto.markdownTemplate as string,
        headerImg: response.imgHeader as string,
        images: logos as string[],
        status: status() as ProcedureStatus,
      };

      procedureHeaderStore.setProcedureInfo(procedureInfo);
      procedureHeaderStore.setCampana(response.producto.campana);
    }
  }, [state.stateProcedure]);

  useEffect(() => {
    getProcedureInfo();
  }, [getProcedureInfo]);

  if (state.stateProcedure === 'validacion_campos' && procedure)
    return (
      <ProcedureStepSuspense>
        <LazyNewCertificationValidationScreen procedure={procedure} />
      </ProcedureStepSuspense>
    );

  if (state.stateProcedure === 'asignacion_hectareas' && procedure)
    return (
      <ProcedureStepSuspense>
        <LazyNewCertificationReviewScreen trace={procedure} />
      </ProcedureStepSuspense>
    );

  if (state.stateProcedure === 'revision_firmas' && procedure)
    return (
      <ProcedureStepSuspense>
        <LazyNewCertificationAssignScreen trace={procedure} />
      </ProcedureStepSuspense>
    );

  if (state.stateProcedure === 'finalizado' && procedure)
    return (
      <ProcedureStepSuspense>
        <LazyFinishedCertificationScreen trace={procedure} />
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
