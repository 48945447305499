import React from 'react';
import { P4 } from 'components/common/typography/styles';
import { Grass } from '@mui/icons-material';
import colors from '../../../../../../theme/colors';

type TDescriptionByResolution = {
  text: string;
};

const TextWithUnderline = ({ text }: TDescriptionByResolution) => {
  return (
    <P4>
      Más info en <span style={{ textDecoration: 'underline' }}>{text}</span>
    </P4>
  );
};

export const cardListCommonProcedureModal = (isMobile: boolean) => [
  {
    title: 'Campos y Lotes',
    description: !isMobile ? (
      <P4>
        Mantené actualizado la ubicación y polígonos de tus{' '}
        <span style={{ textDecoration: 'underline' }}>Campos</span> y{' '}
        <span style={{ textDecoration: 'underline' }}>Lotes</span>
      </P4>
    ) : (
      <TextWithUnderline text="Mis datos > Campos" />
    ),
    textButton: 'Agregar campo',
    startIcon: (
      <Grass sx={{ color: colors.complementaryGreen, fontSize: '120px' }} />
    ),
    redirectTo: '/misdatos/campos',
  },
  {
    title: 'Cultivos y Ordenes de Trabajo',
    description: !isMobile ? (
      <P4>
        Mantené actualizados tus{' '}
        <span style={{ textDecoration: 'underline' }}>Cultivos</span> y{' '}
        <span style={{ textDecoration: 'underline' }}>Ordenes de Trabajo</span>.
        La importación puede llevar un tiempo, hacelo cuanto antes.
      </P4>
    ) : (
      <TextWithUnderline text="Mis datos > Órdenes de trabajo" />
    ),
    textButton: 'Importar datos',
    startIcon: null,
    redirectTo: '/misdatos/ordentrabajo',
  },
];