import React from 'react';
import { Box, Grid } from '@mui/material';
import { Caps } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { IGridHeader } from 'models/fields';
import InputCheckbox from 'components/common/inputs/checkbox';
import { useStores } from 'store/root-store/root-store-context';

const GridHeader: React.FC<IGridHeader> = ({
  gridPattern,
  selectedLength,
  fieldsLength,
  onSelectAllClick,
}) => {
  const { xs } = gridPattern;
  const { procedureHeaderStore } = useStores();

  return (
    // 18px por los bordes de 2px en las cards
    <Box px="18px" mt="20px">
      <Grid container>
        {xs.checkbox && (
          <Grid item xs={xs.checkbox}>
            <InputCheckbox
              defaultChecked={false}
              disabled={false}
              checked={selectedLength > 0}
              indeterminate={
                selectedLength !== fieldsLength && selectedLength > 0
              }
              name="field"
              error={false}
              onChange={onSelectAllClick}
            />
          </Grid>
        )}
        {xs.icon && (
          <Grid item sx={{ width: '60px' }}>
            &nbsp;
          </Grid>
        )}
        <Grid item xs={xs.name}>
          <Box display="flex" alignItems="center" height="100%">
            <Caps color={colors.tertiaryMedium} sx={{ fontWeight: 350 }}>
              Nombre
            </Caps>
          </Box>
        </Grid>
        {xs.grows && (
          <Grid item xs={xs.grows}>
            <Box display="flex" alignItems="center" height="100%">
              <Caps color={colors.tertiaryMedium} sx={{ fontWeight: 350 }}>
                Cultivos {procedureHeaderStore.campana?.nombre}
              </Caps>
            </Box>
          </Grid>
        )}
        <Grid item xs>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  );
};

export default GridHeader;
