import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { H1, H5, P3 } from 'components/common/typography/styles';
import ValidateErpForm from 'components/common/forms/ValidateErpForm';
import colors from 'theme/colors';
import { Strings } from 'constants/strings';
import { AuthContainer } from '../index.styled';
import AlborLogo from '../../../assets/icons/albor3x.png';
import FinnegansLogo from '../../../assets/icons/finnegans_logo_x2.png';
import { useValidateTokenErp } from './useValidateTokenErp';

const ValidateErpContainer: React.FC = observer(() => {
  const {
    state: { tokenFromErp, domainName, origin },
  }: any = useLocation();
  const { navigate } = useValidateTokenErp(origin);

  const isAlbor = origin === Strings.domainErp.albor;

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="activate-title">
          Terminá de activar tu cuenta Agrology
        </H1>
        <div className="container-url-erp">
          <div className="url-erp">
            <P3 style={{ marginBottom: '10px', width: '100%' }}>
              Dominio de origen
            </P3>
            <H5
              style={{
                marginBottom: '16px',
                color: colors.primaryBase,
                width: '100%',
              }}
            >
              {domainName}
            </H5>
          </div>
          <img
            style={{ marginTop: isAlbor ? '0px' : '9px' }}
            src={isAlbor ? AlborLogo : FinnegansLogo}
            alt=""
            width={isAlbor ? '100px' : '174px'}
            height="60px"
            loading="lazy"
          />
        </div>
        <ValidateErpForm navigate={navigate} token={tokenFromErp} />
      </Box>
    </AuthContainer>
  );
});

export default ValidateErpContainer;
