import React from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import { P2, P3, P4, H4, H6 } from 'components/common/typography/styles';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'theme/colors';
import AllariaLogo from 'assets/Logos/allariaPlusLogo.png';
import { DialogBody, DialogContainer, DialogHeader } from './index.styled';
import Button from '../buttons/button';
import { CuitsRoot } from 'store/cuit-store/cuitModel';

export interface BusinessDarumaModalProps {
  allCuits: number;
  cuits: CuitsRoot[];
  open: boolean;
  onClose: () => void;
  onJoin: () => void;
  openModalInfo: () => void;
}

const BusinessDarumaModal: React.FC<BusinessDarumaModalProps> = ({
  allCuits,
  cuits,
  open,
  onClose,
  onJoin,
  openModalInfo,
}) => {
  return (
    <DialogContainer open={open} onClose={onClose} scroll="body" daruma>
      <Box>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 10,
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogHeader style={{ paddingTop: '25px' }}>
          <img loading="lazy" src={AllariaLogo} alt="Daruma" />
          <H4 color={colors.tertiaryBase} style={{ marginTop: '20px' }}>
            Integración con ecosistema de agrofinanzas Allaria
          </H4>
        </DialogHeader>
      </Box>
      <DialogBody daruma style={{ display: 'flex', flexDirection: 'column' }}>
        <H6 color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
          {cuits.length} de {allCuits} empresas vinculadas
        </H6>

        <Box display="flex" flexDirection="column" gap="8px">
          {cuits.map((item) => (
            <Paper
              key={item.cuit}
              elevation={1}
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignSelf={{
                  xs: 'flex-start',
                  tabletS: 'center',
                }}
              >
                <P2 color="black">{item.razonSocial}</P2>
              </Box>

              <Box
                alignItems="center"
                width={{
                  xs: '100%',
                  tabletS: '120px',
                }}
              >
                <P3 color={colors.tertiaryMedium}>{item.cuit}</P3>
              </Box>
            </Paper>
          ))}
        </Box>

        <Box
          mt="24px"
          display="flex"
          justifyContent="space-between"
          flexDirection={{
            xs: 'column',
            tablet: 'row',
          }}
          gap="16px"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="5px"
            width="100%"
          >
            <H6 color={colors.black} style={{ fontWeight: 350 }}>
              Sumá más empresas al ecosistema
            </H6>
            <P4 color={colors.tertiaryMedium}>
              Confiá en el ecosistema de reconocida trayectoria financiera
            </P4>
          </Box>
          <Box display="flex" gap="3px">
            <Box width="143px">
              <Button
                variant="outlined"
                preset="normal"
                size="small"
                onClick={openModalInfo}
                text="Más información"
              />
            </Box>
            <Box
              display={{
                xs: 'none',
                laptop: 'block',
              }}
              width="170px"
            >
              <Button
                variant="contained"
                preset="normal"
                size="small"
                onClick={onJoin}
                text="Sumar otra empresa"
              />
            </Box>
          </Box>
        </Box>
      </DialogBody>
    </DialogContainer>
  );
};

export default BusinessDarumaModal;
