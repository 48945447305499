import { ApiOkResponse } from 'apisauce';

import type {
  ResponseError,
  InsumoNoStandard,
  InsumoPuma,
} from './InsumosType';
import type {
  ExternalLoginApiResponse,
  RegisterExternalApiResponse,
  SuccessfulExternalLoginResponse,
} from 'services/api/authApi';
import type {
  UploadOTCultivosFileResponse,
  SuccessOTCultivosFileResponse,
} from 'types/OTCultivosTypes';

export function isSuccessfulExternalLoginResponse(
  response: ExternalLoginApiResponse,
): response is ApiOkResponse<SuccessfulExternalLoginResponse> {
  return response.ok && response.status === 200;
}

export function isSuccessfulRegisterExternalResponse(
  response: RegisterExternalApiResponse,
) {
  return response.ok && response.status === 200;
}

export function isSuccessfulOTCultivosFileResponse(
  response: UploadOTCultivosFileResponse,
): response is ApiOkResponse<SuccessOTCultivosFileResponse> {
  return response.status === 200;
}

export function isResponseError<T>(
  response: T | ResponseError,
): response is ResponseError {
  return (response as ResponseError).kind === 'bad-data';
}
