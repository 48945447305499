import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import {ReactNode,FC} from "react";

type TInputMaskCorrect = Omit<InputMaskProps, 'children'> & { children?: (inputProps: any) => JSX.Element };
export const Mask: FC<TInputMaskCorrect> = ({ children, ...props }) => {
    const child = children as ReactNode;
    return (
        <InputMask {...props}>
            {child}
        </InputMask>
    );
}