import { SancorDispatchContext, SancorStateContext } from 'contexts/Sancor';
import { useContext } from 'react';

export function useSancorContext() {
  const state = useContext(SancorStateContext);
  const dispatch = useContext(SancorDispatchContext);
  if (!state || !dispatch) {
    throw new Error('useSancorContext debe ser usado con SancorProvider');
  }
  return {
    state,
    dispatch,
  };
}
