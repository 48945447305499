import { ProcedureAvailable } from '../models/procedure';

const colors: Record<string, string> = {
  brightTurquoise: '#0EF3BC',
  darkTurquoise: '#0CCBE5',
  dodgerBlue: '#179FEC',
  mariner: '#1766DB',
  hanPurple: '#4615D2',
  daisyBush: '#61199A',
  violetEggplant: '#9F11AC',
  violet: '#AD008B',
  disco: '#8F0A4A',
  chineseRed: '#AA4317',
  westSide: '#FF8E08',
  corn: '#FEE45B',
  juneBud: '#CAF04E',
};

export const generateRandomColor = () => {
  return colors[
    Object.keys(colors)[Math.floor(Math.random() * Object.keys(colors).length)]
  ];
};

export const tabModes = {
  CAMPO: 'Campo',
  LOTES: 'Lotes',
};

export const ESTADO_CIVIL = [
  {
    label: 'Soltero(a)',
    value: 'soltero',
  },
  {
    label: 'Casado(a)',
    value: 'casado',
  },
  {
    label: 'Viudo(a)',
    value: 'viudo',
  },
  {
    label: 'Divorciado(a)',
    value: 'divorciado',
  },
];

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function changeDataByAlias(
  aliasTipoTramite: string | undefined,
  dataToChange: string,
) {
  if (aliasTipoTramite)
    return aliasTipoTramite === 'certificacion'
      ? dataToChange
      : aliasTipoTramite;
  return null;
}

export function checkFileSize(size: number) {
  const MAX_FILE_SIZE_5_MB = 5120; // 5MB
  return size / 1024 < MAX_FILE_SIZE_5_MB;
}

export const isProcedureEqualToHuellaDeCarbono = (
  tramite: ProcedureAvailable,
) =>
  tramite.alias === 'huella_carbono' ||
  tramite.alias === 'huella_carbono_bulmar';
