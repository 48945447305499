import { lazy } from 'react';

export const LazyNewCertificationScreen = lazy(
  () => import('./solicitud/index'),
);

export const LazyNewCertificationValidationScreen = lazy(
  () => import('./solicitud-validacion'),
);
export const LazyNewCertificationReviewScreen = lazy(
  () => import('./confirmar-hectareas'),
);
export const LazyNewCertificationAssignScreen = lazy(
  () => import('./declaracion-jurada'),
);
export const LazyFinishedCertificationScreen = lazy(
  () => import('./certificacion-finalizada'),
);
