import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

export interface UserRegister {
  nombre: string;
  apellido: string;
  password: string;
}

export class InviteApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async sendInvite(newGuestUser: { rol: string; email: string }): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/invitaciones/invitar`,
        newGuestUser,
      );

      if (!response.ok) {
        if (response.status === 409) {
          return { kind: 'conflict' };
        }

        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) {
        return { kind: 'ok', data };
      }
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getInvitation(token: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/invitaciones/invitacion/${token}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async validateTokenFromErp(token: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/remote_auth/acquire/${token}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async registerNewUserFromErp(
    token: string,
    firstNAme: string,
    lastName: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `remote_auth/activate/${token}/${firstNAme}/${lastName}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async activateInvitation(token: string, body: UserRegister): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/invitaciones/invitacion/${token}/activar`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
