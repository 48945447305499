/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useSancorContext } from 'contexts/Sancor/useSancorContext';
import {
  LazyInsuranceAmountScreen,
  LazyInsuranceGenerationScreen,
  LazyInsuranceQuotationFinishedScreen,
  LazyInsuranceQuotationScreen,
} from '../lazySancor';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import StepProcedureContainer from 'components/common/cards/stepProcedure';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { ProcedureInProcessInsurance, ProcedureStatus } from 'models/procedure';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';

export const RoutesWithProcedureInitiated = () => {
  const {
    state: { routeState },
    dispatch,
  } = useSancorContext();
  const { code, alias } = useParams();
  const [procedure, setProcedure] =
    useState<ProcedureInProcessInsurance | null>(null);
  const [loading, setLoading] = useState(true);
  const { procedureHeaderStore } = useStores();
  const ACEPTADO = true;
  const getProcedureInfo = useCallback(async () => {
    if (code && alias) {
      const response = await api.procedure.getProcedureDetail('seguro', code);
      setProcedure(response);

      dispatch({ type: 'set-routeState', value: response.estado });

      const logos = [];

      if (response.producto.logo) {
        logos.push(response.producto.logo);
      }

      if (response.producto.entidad.logo) {
        logos.push(response.producto.entidad.logo);
      }

      const procedureInfo = {
        alias: response.producto.alias as string,
        productName: response.producto.nombre as string,
        markdownTemplate: response.producto.markdownTemplate as string,
        headerImg: response.producto.imgHeader as string,
        images: logos as string[],
        status:
          response.aceptoOferta === null
            ? ('ongoing' as ProcedureStatus)
            : response.aceptoOferta === ACEPTADO
              ? ('finished' as ProcedureStatus)
              : ('rejected' as ProcedureStatus),
      };
      procedureHeaderStore.setProcedureInfo(procedureInfo);

      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getProcedureInfo();
  }, [getProcedureInfo]);

  if (procedure && routeState) {
    if (routeState === 'chequeo_agrology')
      return (
        <ProcedureStepSuspense>
          <LazyInsuranceGenerationScreen
            procedure={procedure}
            loading={loading}
          />
        </ProcedureStepSuspense>
      );
    if (routeState === 'monto_asegurar')
      return (
        <ProcedureStepSuspense>
          <LazyInsuranceAmountScreen procedure={procedure} loading={loading} />
        </ProcedureStepSuspense>
      );
    if (routeState === 'cotizacion')
      return (
        <ProcedureStepSuspense>
          <StepProcedureContainer>
            <LazyInsuranceQuotationScreen
              procedure={procedure}
              loading={loading}
            />
          </StepProcedureContainer>
        </ProcedureStepSuspense>
      );
    if (routeState === 'solicitud_enviada')
      return (
        <ProcedureStepSuspense>
          <StepProcedureContainer>
            <LazyInsuranceQuotationFinishedScreen
              procedure={procedure}
              loading={loading}
            />
          </StepProcedureContainer>
        </ProcedureStepSuspense>
      );
  }
  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
