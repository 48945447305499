import React from 'react';
import { Stack } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Field } from 'models/fields';
import DataVerificationChip from 'components/common/chips/dataVerification';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';

type ResumeProps = {
  localidad: Field['localidad'];
};

const Resume = ({ localidad }: ResumeProps) => {
  if (!localidad) {
    return (
      <Stack alignItems="center">
        <DataVerificationChip text="Datos incompletos" isValid={false} />
      </Stack>
    );
  }

  const { partido } = localidad;
  const name = `${localidad.nombre}, ${partido.nombre}`;

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      <PinDropOutlinedIcon
        sx={{ fontSize: '32px', color: colors.tertiaryLow }}
      />
      <P3>{name}</P3>
    </Stack>
  );
};

export default Resume;
