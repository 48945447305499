import { Inputs } from 'models/dynamicForms';

export enum FieldNames {
  fieldPassword = 'password',
  fieldRepeatPassword = 'repeatNewPassword',
}

export const inputsForm: Inputs = [
  [
    {
      id: FieldNames.fieldPassword as string,
      name: FieldNames.fieldPassword as string,
      disabled: false,
      label: 'Nueva contraseña',
      placeholder: 'Tu nueva contraseña',
      type: 'password',
      explanation:
        'Usá 8 o más caracteres con al menos una mayúscula, un número, una letra y un carácter especial',
    },
    {
      id: FieldNames.fieldRepeatPassword as string,
      name: FieldNames.fieldRepeatPassword as string,
      disabled: false,
      label: '',
      placeholder: 'Repetí tu contraseña',
      type: 'password',
    },
    {
      type: 'button',
      text: 'Recuperar cuenta',
      variant: 'contained',
      size: 'large',
      preset: 'normal',
      fullWidth: false,
    },
  ],
  [],
];
