import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Title } from '../alerts/index.styled';
import colors from 'theme/colors';
import { P3 } from '../typography/styles';
import CreateLoteForm from '../forms/createLote';
import { Perimeter } from 'utils/types';

type Props = {
  open: boolean;
  polygon: Perimeter | null;
  handleClose: () => void;
  handleCreate: (name: string) => void;
};

const CreateLoteModal = ({
  open,
  polygon,
  handleClose,
  handleCreate,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{ elevation: 24 }}
    >
      <DialogTitle
        sx={{
          paddingX: {
            sm: '16px',
            md: '34px',
          },
        }}
      >
        <Title sx={{ color: colors.complementaryGreen }}>Creando lote</Title>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingX: {
            sm: '16px',
            md: '34px',
          },
          width: {
            md: 'calc(482px - 68px)',
          },
        }}
      >
        <P3 color={colors.tertiaryMedium} sx={{ fontSize: '15px' }}>
          Asigna un nombre al lote dibujado que te permita reconocerlo en
          futuras operaciones
        </P3>
        <CreateLoteForm
          polygon={polygon}
          handleClose={handleClose}
          handleCreate={handleCreate}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateLoteModal;
