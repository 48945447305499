import React from 'react';
import { H1, P2 } from 'components/common/typography/styles';
import MaintenanceImg from 'assets/maintenance.png';
import AgrologyLogo from 'assets/Logos/agrology.png';
import { Box } from '@mui/material';
import {
  StyledContainer,
  StyledImageCover,
  StyledWrapper,
} from './index.styled';

const Maintenance: React.FC = () => {
  return (
    <StyledContainer>
      <StyledImageCover>
        <div className="logo">
          <img loading="lazy" src={AgrologyLogo} alt="agrology-logo" />
        </div>

        <img
          loading="lazy"
          src={MaintenanceImg}
          alt="slider-img"
          className="cover-img"
        />
      </StyledImageCover>
      <StyledWrapper>
        <Box padding="24px">
          <H1 color="#333333" style={{ marginBottom: '16px' }}>
            En mantenimiento
          </H1>
          <P2
            color="#4B4846"
            style={{ maxWidth: '500px', marginBottom: '16px' }}
          >
            Nos encontramos temporalmente realizando un mantenimiento programado
            para actualizar el sitio.
          </P2>
          <P2 color="#4B4846" style={{ maxWidth: '500px' }}>
            Por favor, refrescá el sitio o volvé a visitarnos en unos minutos.
          </P2>
        </Box>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default Maintenance;
