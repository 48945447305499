import React from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  useMediaQuery,
} from '@mui/material';
import { P3, P4, H5 } from 'components/common/typography/styles';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';

const ProfileAccounts: React.FC = () => {
  const {
    sessionStore,
    sessionStore: { currentUser },
  } = useStores();
  // width >= 768
  const width = useMediaQuery('(min-width:768px)');

  return (
    <Paper
      elevation={2}
      sx={{
        width: 'calc(100% - 32px)',
        padding: '16px',
        marginTop: '16px',
      }}
    >
      <H5 style={{ marginBottom: '21px' }}>Mis cuentas Agrology</H5>
      <Table>
        {width && (
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '12px 0', border: 'none' }}>
                <P4 color={colors.tertiaryMedium}>CUENTA</P4>
              </TableCell>
              <TableCell sx={{ padding: '12px 0', border: 'none' }}>
                <P4 color={colors.tertiaryMedium}>ROL</P4>
              </TableCell>
              <TableCell sx={{ padding: '12px 0', border: 'none' }}>
                <P4 color={colors.tertiaryMedium}>ALTA</P4>
              </TableCell>
              <TableCell sx={{ padding: '12px 0', border: 'none' }}>
                <P4>&nbsp;</P4>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {currentUser &&
            currentUser?.misCuentas?.map((row: any, key: number) => (
              <TableRow key={row.id}>
                {width ? (
                  <>
                    <TableCell
                      sx={{
                        padding: '12px 0',
                        borderTop:
                          key !== 0
                            ? `1px solid ${colors.tertiaryIntermediate}`
                            : 'none',
                        borderBottom: 'none',
                      }}
                    >
                      <P3>{row.nombre}</P3>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '12px 0',
                        borderTop:
                          key !== 0
                            ? `1px solid ${colors.tertiaryIntermediate}`
                            : 'none',
                        borderBottom: 'none',
                      }}
                    >
                      <P3>
                        {
                          sessionStore.roles.find(
                            (rol) => rol.codigo === row.rol,
                          )?.nombre
                        }
                      </P3>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '12px 0',
                        borderTop:
                          key !== 0
                            ? `1px solid ${colors.tertiaryIntermediate}`
                            : 'none',
                        borderBottom: 'none',
                      }}
                    >
                      <P3>
                        {row.createdAt
                          ? format(new Date(row.createdAt), 'dd/MM/yyyy')
                          : ''}
                      </P3>
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      padding: '12px 0',
                      borderTop:
                        key !== 0
                          ? `1px solid ${colors.tertiaryIntermediate}`
                          : 'none',
                      borderBottom: 'none',
                    }}
                  >
                    <Box>
                      <Box>
                        <P3 color={colors.tertiaryMedium}>
                          {
                            sessionStore.roles.find(
                              (rol) => rol.codigo === row.rol,
                            )?.nombre
                          }
                        </P3>
                        <P3 color={colors.tertiaryBase}>{row.nombre}</P3>
                        <P4 color={colors.tertiaryMedium}>
                          {row.createdAt
                            ? format(new Date(row.createdAt), 'dd/MM/yyyy')
                            : ''}
                        </P4>
                      </Box>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default observer(ProfileAccounts);
