import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import ProcedureProgress from 'components/common/progress/procedureProgress';
import { ArgenpymeStateContext } from 'contexts/Argenpymes';
import { RoutesWithProcedure } from './RutasConTrazabilidad/RoutesWithProcedure';
import { RoutesWithoutProcedure } from './RutasSinTrazabilidad/RoutesWithoutProcedure';

export const ArgenpymeContainer: React.FC = observer(() => {
  const { stateProcedure, refused } = useContext(ArgenpymeStateContext);

  return (
    <>
      <Box width="100%">
        <Box px={{ xs: '16px', tablet: '24px' }}>
          <ProcedureProgress
            procedure="argenpymes"
            state={stateProcedure}
            refused={refused}
          />
        </Box>
        <Routes>
          <Route path="/" element={<RoutesWithoutProcedure />} />
          <Route path="/:code" element={<RoutesWithProcedure />} />
        </Routes>
      </Box>
    </>
  );
});