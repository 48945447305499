import React from 'react';
import BulletModalIcon from './icons/bulletModalIcon';
import SmallBulletModalIcon from './icons/smallBulletModalIcon';
import CosechaIcon from './icons/cosechaIcon';
import CompraIcon from './icons/compraIcon';
import FilterIcon from './icons/filterIcon';

export interface SvgProps {
  color?: string;
  height?: number;
  width?: number;
}

interface IconProps extends SvgProps {
  icon: IconTypes;
}

type IconTypes = keyof typeof icons;

const icons = {
  bulletModal: BulletModalIcon,
  smallBulletModal: SmallBulletModalIcon,
  cosecha: CosechaIcon,
  compra: CompraIcon,
  filter: FilterIcon,
};

const Icon: React.FC<IconProps> = (props) => {
  const { icon, color, height, width } = props;
  return icons[icon]({ color, height, width });
};

export default Icon;
