import { StyledLinkBtn } from './index.styled';

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  inline?: boolean;
}

const LinkBtn = ({ onClick, children, inline = false }: Props) => {
  return (
    <StyledLinkBtn type="button" inline={inline} onClick={(e) => onClick(e)}>
      {children}
    </StyledLinkBtn>
  );
};

export default LinkBtn;
