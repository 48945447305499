import React from 'react';
import {
  StyledContentCarousel,
  StyledWrapperContentCarousel,
} from './index.styled';

// TODO: Export this interface from utils/types
interface ContentCarouselProps {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  hOffset?: string;
  carousel: React.ReactNode;
  children: React.ReactNode;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({
  flexDirection = 'column',
  justifyContent = 'center',
  alignItems = 'center',
  hOffset = '0px',
  carousel,
  children,
}) => {
  return (
    <StyledWrapperContentCarousel hOffset={hOffset || '0px'}>
      {carousel}
      <StyledContentCarousel
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        {children}
      </StyledContentCarousel>
    </StyledWrapperContentCarousel>
  );
};

export default ContentCarousel;
