/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const MapStoreModel = types
  .model('MapStore')
  .props({
    drawPolygon: types.optional(types.boolean, false),
    idPolygonToRemove: types.maybeNull(types.number),
    refreshPolygons: types.optional(types.boolean, false),
  })
  .actions((self) => {
    function setDrawPolygon(value: boolean) {
      self.drawPolygon = value;
    }

    function setIdPolygonToRemove(value: number | null) {
      self.idPolygonToRemove = value;
    }

    /**
     * Para que se refresquen los polígonos en el mapa con valores actualizados
     * forzando a que se vuelva a renderizar el componente
     */
    function setRefreshPolygons() {
      self.refreshPolygons = !self.refreshPolygons;
    }

    return {
      setDrawPolygon,
      setIdPolygonToRemove,
      setRefreshPolygons,
    };
  });

type MapStoreModelType = Instance<typeof MapStoreModel>;
export type MapStore = MapStoreModelType;
type MapStoreSnapshotType = SnapshotOut<typeof MapStoreModel>;
export type MapStoreSnapshot = MapStoreSnapshotType;
export const createMapStoreDefaultModel = () =>
  types.optional(MapStoreModel, {});
