/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { BlockerFunction, useBlocker, useNavigate } from 'react-router';

export type WantToNavigateTo = {
  location: { pathname: string };
};

type UseNavigatingAwayReturnType = {
  showDialogPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
};

/**
 * useNavigatingAway` es un custom hook que se encarga de manejar la navegacion, blockeando y
 * avisando al usuario con un Modal si intentan navegar hacia otra pagina.
 * @param {boolean} canShowDialogPrompt - Es un booleano que indica si se deberia o no mostrar
 * el modal.
 * @returns La funcion `useNavigatingAway` devuelve un objeto con las siguientes caracteristicas:
 *
 * `showDialogPrompt:` es un booleando que indica si se abre o no el modal.
 *
 * `confirmNavigation:` es una funcion de tipo void que se deberia llamar cuando la nevegacion es exitosa.
 *
 * `cancelNavigation:` es una funcion de tipo void que se deberia llamar cuando la navegacion es cancelada.
 */
export function useNavigatingAway(
  canShowDialogPrompt: boolean,
): UseNavigatingAwayReturnType {
  const navigate = useNavigate();

  const [showDialogPrompt, setShowDialogPrompt] = useState<boolean>(false);
  const [wantToNavigateTo, setWantToNavigateTo] =
    useState<WantToNavigateTo | null>(null);
  const [isNavigationConfirmed, setIsNavigationConfirmed] =
    useState<boolean>(false);

  const handleNavigationBlocking = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (
        !isNavigationConfirmed &&
        nextLocation.pathname !== currentLocation.pathname
      ) {
        setShowDialogPrompt(true);
        setWantToNavigateTo({ location: { pathname: nextLocation.pathname } });
        return true;
      }

      return false;
    },
    [isNavigationConfirmed],
  );

  const blocker = useBlocker(handleNavigationBlocking);

  const cancelNavigation = useCallback(() => {
    setIsNavigationConfirmed(false);
    setShowDialogPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setIsNavigationConfirmed(true);
    setShowDialogPrompt(false);
  }, []);

  useEffect(() => {
    if (isNavigationConfirmed && wantToNavigateTo) {
      navigate(wantToNavigateTo.location?.pathname);
    }
    setIsNavigationConfirmed(!canShowDialogPrompt);
  }, [isNavigationConfirmed, wantToNavigateTo, canShowDialogPrompt]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isNavigationConfirmed) {
      blocker.reset();
    }
  }, [blocker, isNavigationConfirmed]);

  return { showDialogPrompt, confirmNavigation, cancelNavigation };
}
