import { ApiOkResponse, ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';
import { CustomApiError } from 'utils/apiErrors';
import { isSuccessfulOTCultivosFileResponse } from 'types/typeguards';
import {
  SuccessOTCultivosFileResponse,
  UploadCultivoFileParams,
  UploadOTCultivosFileResponse,
} from 'types/OTCultivosTypes';

interface AxiosError extends Error {
  response: {
    status: number;
    statusText: string;
    data: {
      message: string;
    };
  };
}

export class CommonApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getRoles(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        '/agrology/roles',
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getEndangeredZones(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/endangeredZones/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getProvinces(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/provincias`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getDepartaments(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/departaments`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getProvinceDepartaments(provinceId: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/provincias/${provinceId}/partidos`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getLocalities(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/localidades`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getDepartmentLocalities(departmentId: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/partidos/${departmentId}/localidades`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getField(id: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/campos/${id}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getFieldHC(
    alias: string,
    id: number,
    especiesIds: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/campos/${id}?alias_producto=${alias}&especies_ids=${especiesIds}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateField(id: number, field: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.put(
        `/agrology/campos/${id}`,
        field,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendMessage(body: {
    recipient: string;
    subject: string;
    message: string;
  }): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/agrology/mensaje/enviar`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMenu(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/menu`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCarousel(category: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/agrology/carrousel/?categoria=${category}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      return data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postOTCultivosFile({
    form,
    onLoading,
  }: UploadCultivoFileParams): Promise<
    AxiosError | ApiOkResponse<SuccessOTCultivosFileResponse>
  > {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${this.api.token}`,
    };
    try {
      const response: UploadOTCultivosFileResponse =
        await this.api.apisauce.axiosInstance.post(
          '/agrology/me/external/excelupload',
          form,
          { headers, onUploadProgress: onLoading },
        );

      if (isSuccessfulOTCultivosFileResponse(response)) return response;

      throw new CustomApiError({
        name: 'OTCultivosFile',
        message: 'Error al subir el archivo',
        cause: {
          status: 400,
          ok: false,
          problem: 'CLIENT_ERROR',
        },
      });
    } catch (error) {
      return error as AxiosError;
    }
  }
}
