/* eslint-disable import/no-duplicates */
import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Box, CardMedia, Stack, useMediaQuery } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Link } from 'react-router-dom';
import { P3, P3Ellipsis, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { proceduresStatesMultiplexorClassMap } from 'utils/procedureStates';
import CustomTooltip from 'components/common/tooltips';
import { EstablecimientoEntity, ProcedureInProcess } from 'models/procedure';
import {
  BadgeTime,
  CardMediaProductStyle,
  TraceCard,
  TraceCardContent,
} from './index.styled';
import { isProcessingAllowed } from 'utils/AllowedProcedures';

interface ProcessCardDetailProps {
  waitingTime: boolean;
  trace: ProcedureInProcess;
}

interface ProcessCardProps extends ProcessCardDetailProps {
  isJuridico?: boolean;
  procedure: 'certificaciones' | 'financiamiento' | 'seguros';
  handleOnAlert: () => void;
}

const fieldText = (states: EstablecimientoEntity[]) => {
  if (states.length === 1) {
    return states[0].establecimiento.nombre;
  }
  if (states.length > 1) {
    return `${states[0].establecimiento.nombre} y ${states.length - 1} campo${
      states.length - 1 > 1 ? 's' : ''
    } más`;
  }
  return '';
};

const ProcessCardDetail = (props: ProcessCardDetailProps) => {
  const { trace, waitingTime } = props;
  const { estado } = trace;
  const { multiplexorClass, nombre: productoNombre } = trace.producto;
  const { nombre: entidadNombre } = trace.producto.entidad;
  const createDate = trace.fechaCreacion ? trace.fechaCreacion : '';
  const title =
    trace.cuitSolicitante?.razonSocial || fieldText(trace.establecimientos);

  const { color, name, percentage } =
    proceduresStatesMultiplexorClassMap[multiplexorClass][estado];

  const since = formatDistance(new Date(createDate), new Date(), {
    locale: es,
    addSuffix: true,
  });

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 4,
    borderRadius: 4,
    backgroundColor: colors.tertiaryHigh,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: color,
    },
  }));

  return (
    <TraceCard elevation={1}>
      <TraceCardContent>
        <Stack direction="row" alignItems="center" gap="5px">
          <Stack justifyContent="center" alignItems="center">
            <CardMedia
              sx={CardMediaProductStyle}
              component="img"
              alt="product"
              loading="lazy"
              image={`${import.meta.env.VITE_API_BASE_URL}${
                trace.producto.logo ?? trace.producto.entidad.logo
              }`}
            />
          </Stack>
          <Stack>
            <P3
              style={{
                color: colors.tertiaryBase,
                fontWeight: 600,
              }}
            >
              {productoNombre}
            </P3>
            <P4
              style={{
                margin: '0 0 8px 0',
                color: colors.tertiaryMedium,
                lineHeight: '15px',
              }}
            >
              {entidadNombre}
            </P4>
          </Stack>
        </Stack>

        <Stack gap="8px">
          <Stack>
            <CustomTooltip title={title} arrowLocation="top-start">
              <P3Ellipsis style={{ color: colors.black }}>{title}</P3Ellipsis>
            </CustomTooltip>
            <P3
              style={{
                fontWeight: '600',
                color,
              }}
            >
              {name}
            </P3>
          </Stack>
          <BorderLinearProgress variant="determinate" value={percentage} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            color={colors.tertiaryMedium}
          >
            <BadgeTime>
              <P4
                style={{
                  color: colors.tertiaryMedium,
                }}
              >
                {since}
              </P4>
            </BadgeTime>
            {waitingTime ? (
              <TimerOutlinedIcon sx={{ fontSize: 16 }} />
            ) : (
              <NotificationsActiveIcon sx={{ fontSize: 16 }} />
            )}
          </Stack>
        </Stack>
      </TraceCardContent>
    </TraceCard>
  );
};

const ProcedureProcessCard = (props: ProcessCardProps) => {
  const { trace, procedure, handleOnAlert } = props;

  const { codigo } = trace;
  const { alias } = trace.producto;

  const urlRedirect = `/${procedure}/${alias}/${codigo}`;
  const isNotDesktop = useMediaQuery('(max-width:768px)');

  if (!isNotDesktop || !isProcessingAllowed(alias)) {
    return (
      <Link to={urlRedirect} style={{ textDecoration: 'none' }}>
        <ProcessCardDetail {...props} />
      </Link>
    );
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      onClick={handleOnAlert}
    >
      <ProcessCardDetail {...props} />
    </Box>
  );
};

export default ProcedureProcessCard;
