import apisauce, { ApiResponse } from 'apisauce';
import { Api } from './api';
import { DEFAULT_API_CONFIG } from './api-config';
import { getGeneralApiProblem } from './api-problem';
import {
  isSuccessfulExternalLoginResponse,
  isSuccessfulRegisterExternalResponse,
} from 'types/typeguards';
import { CustomApiError } from 'utils/apiErrors';
import { Strings } from 'constants/strings';

const newApi = apisauce.create({
  baseURL: DEFAULT_API_CONFIG.url,
  timeout: DEFAULT_API_CONFIG.timeout,
});

export interface ApiListResponse<T> {
  data: T[];
}

export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getToken(
    username: string,
    password: string,
    token?: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<{ token: string }> =
        await this.api.apisauce.post('/token_auth/', {
          username,
          password,
          'g-recaptcha-response': token,
        });

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return { ...problem, status: response.status };
      }

      const { data } = response;
      if (data) {
        this.api.apisauce.setHeaders({
          Authorization: `Token ${data.token}`,
        });
        return { token: data.token };
      }
      return { token: '' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async logout(): Promise<any> {
    try {
      const response: ApiResponse<{ token: string }> =
        await this.api.apisauce.post('/token_deauth/');

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      this.api.apisauce.deleteHeader('Authorization');

      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

type ExternalLoginParams = {
  accessToken: string;
  onSuccess: (token: string | undefined) => void;
  onFailure: (message: string | undefined) => void;
};

export interface SuccessfulExternalLoginResponse {
  token: string;
}

export interface ErrorExternalLoginResponse {
  message: string;
}

export type ExternalLoginApiResponse = ApiResponse<
  SuccessfulExternalLoginResponse,
  ErrorExternalLoginResponse
>;

export const externalLogin: (params: ExternalLoginParams) => void = async ({
  accessToken,
  onSuccess,
  onFailure,
}) => {
  try {
    const response: ExternalLoginApiResponse = await newApi.post(
      `/external_login/`,
      {
        token: accessToken,
      },
    );
    if (isSuccessfulExternalLoginResponse(response)) {
      onSuccess(response.data?.token);
    } else {
      throw new CustomApiError({
        message: response.data?.message
          ? 'Error al procesar la solicitud, intente de nuevo mas tarde'
          : 'Algo salio mal',
        name: 'ExternalLogin',
        cause: {
          status: response.status,
          ok: response.ok,
          problem: response.problem ?? '',
        },
      });
    }
  } catch (error) {
    if (error instanceof CustomApiError) {
      onFailure(error.message);
    }
  }
};

export interface RegisterExternalParams {
  email: string;
  password: string;
  recaptchaToken?: string;
  onSuccess: () => void;
  onFailure: (message: string | undefined) => void;
}

export type RegisterExternalApiResponse = ApiResponse<
  void,
  ErrorExternalLoginResponse
>;

export const registerExternal: (
  params: RegisterExternalParams,
) => void = async ({
  email,
  password,
  recaptchaToken,
  onSuccess,
  onFailure,
}) => {
  try {
    const response: RegisterExternalApiResponse = await newApi.post(
      `/register_external/`,
      {
        email,
        password,
        'g-recaptcha-response': recaptchaToken,
      },
    );

    if (isSuccessfulRegisterExternalResponse(response)) {
      onSuccess();
    } else {
      throw new CustomApiError({
        message: response.data?.message ?? Strings.SomethingWentWrong,
        name: 'ExternalRegister',
        cause: {
          status: response.status,
          ok: response.ok,
          problem: response.problem ?? '',
        },
      });
    }
  } catch (error) {
    if (error instanceof CustomApiError) {
      onFailure(error.message);
    }
  }
};

interface TokenAccessoExternoParams {
  uuid: string;
  onSuccess: (token: string | undefined) => void;
  onFailure: (message: string | undefined) => void;
}

export const solicitudTokenAccesoExterno: (
  params: TokenAccessoExternoParams,
) => Promise<void> = async ({ uuid, onSuccess, onFailure }) => {
  try {
    const response: ExternalLoginApiResponse = await newApi.get(
      `/external_login/${uuid}`,
    );

    if (isSuccessfulExternalLoginResponse(response)) {
      onSuccess(response.data?.token);
    } else {
      throw new CustomApiError({
        message:
          response.status === 401
            ? 'El enlace de verificación de correo se encuentra vencido o ya ha sido utilizado.'
            : 'Algo salio mal',
        name: 'ExternalLogin',
        cause: {
          status: response.status,
          ok: response.ok,
          problem: response.problem,
        },
      });
    }
  } catch (error) {
    if (error instanceof CustomApiError) {
      onFailure(error.message);
    }
  }
};
