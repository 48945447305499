import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchSorter } from 'match-sorter';
import debounce from 'lodash/debounce';
import { Box, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import colors from 'theme/colors';
import useWindowDimensions from 'hooks/useWindowsSize';
import { Field } from 'models/fields';
import { StyledInput, IconBtn } from '../index.styled';

interface SearchInputProps {
  id: string;
  name: string;
  placeholder: string;
  setSearchedField: (v: any) => void; // TODO FIXEAR Dispatch<SetStateAction<Field[]>>;
  fields: Field[];
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { id, name, placeholder, setSearchedField, fields } = props;
  const [hasValue, setHasValue] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const onChange = (value: string) => {
    setHasValue(!!value);

    const fieldsFiltered = matchSorter(fields, value, {
      keys: [
        { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'nombre' },
        { threshold: matchSorter.rankings.CONTAINS, key: 'nombre' },
      ],
    });
    setSearchedField(fieldsFiltered);
  };

  const clearInput = () => {
    setHasValue(false);
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    onChange('');
  };

  const debaounceFunction = useMemo(() => {
    return debounce(onChange, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const width = useMediaQuery('(max-width:767px)');

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      width={width ? '100%' : '290px'}
    >
      <IconBtn
        type="submit"
        aria-label="search"
        location="left"
        className="icon-btn-search"
      >
        <SearchIcon
          className="search-icon"
          sx={{
            fontSize: 20,
            color: focus ? colors.tertiaryMedium : colors.tertiaryBase,
          }}
        />
      </IconBtn>
      <StyledInput
        id={id}
        width="207px"
        height="32px"
        name={name}
        float
        padding="0px 10px 0px 30px"
        type="text"
        placeholder={placeholder}
        onFocus={() => setFocus?.(true)}
        onBlur={() => setFocus?.(false)}
        onChange={(e) => debaounceFunction(e.target.value)}
        ref={inputRef}
      />
      {hasValue ? (
        <IconBtn
          type="submit"
          aria-label="delete"
          location="right"
          onClick={clearInput}
        >
          <CancelIcon sx={{ fontSize: 16, color: colors.tertiaryBase }} />
        </IconBtn>
      ) : null}
    </Box>
  );
};

export default SearchInput;
