import { types, Instance } from 'mobx-state-tree';

export const FirmanteType = types.model('Firmante').props({
  id: types.number,
  nombre: types.string,
  apellido: types.string,
  domicilio: types.string,
  email: types.string,
  telefono: types.string,
  dniDorso: types.string,
  dniFrente: types.string,
  firma: types.string,
  paisOrigen: types.maybeNull(types.number),
  estadoCivil: types.string,
  politicamenteExpuesto: types.string,
});

export const ContactoType = types.model('Contacto').props({
  id: types.number,
  nombre: types.string,
  apellido: types.string,
  telefono: types.string,
  email: types.string,
  cargo: types.string,
});

export const DarumaType = types.model('Daruma').props({
  cuitId: types.number,
  estado: types.string,
});
type DarumaModelType = Instance<typeof DarumaType>;
export type Daruma = DarumaModelType;

export const ArgenpymeType = types.model('Argenpyme').props({
  cuitId: types.number,
  estado: types.string,
  montoPreaprobado: types.string,
});
type ArgenpymeModelType = Instance<typeof ArgenpymeType>;
export type Argenpyme = ArgenpymeModelType;

export const TercerosType = types.model('Terceros').props({
  daruma: types.maybe(DarumaType),
  argenpymes: types.maybe(ArgenpymeType),
});
type TercerosModelType = Instance<typeof TercerosType>;
export type Terceros = TercerosModelType;

export const CuitsRootTypes = types.model('CuitsRoot').props({
  id: types.number,
  cuit: types.string,
  razonSocial: types.string,
  firmante: types.maybeNull(FirmanteType),
  contacto: types.maybeNull(ContactoType),
  juridico: types.boolean,
  terceros: types.optional(TercerosType, {}),
  estado: types.enumeration(['aprobado', 'no_aprobado', 'pendiente']),
  estatuto: types.maybeNull(types.string),
});
type CuitsRootModelType = Instance<typeof CuitsRootTypes>;
export type CuitsRoot = CuitsRootModelType;
