/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { HuellaDeCarbonoContext } from 'contexts/HuellaDeCarbono/index';
import { HuellaDeCarbonoContainer } from './huellaDeCarbono';
import { CertificacionesViterraContainer } from './viterra/viterraRoutes';
import { ViterraContext } from 'contexts/Viterra';

const CertsSubtipoRoutes: React.FC = () => {
  const [subtipo, setSubtipo] = React.useState<string | null>(null);
  const { sessionStore } = useStores();
  const { alias } = useParams();
  const navigate = useNavigate();

  const getCurrentProcedure = async () => {
    if (alias) {
      const response = await api.procedure.getSelectedProcedure(alias);
      if (!(response && response.alias === alias)) {
        navigate('/', { replace: true });
      }
      setSubtipo(response.subTipoTramite.alias);
    }
  };

  useEffect(() => {
    getCurrentProcedure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  return (
    <Routes>
      {subtipo === 'viterra_2bsvs' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <ViterraContext>
                <CertificacionesViterraContainer />
              </ViterraContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
      {subtipo === 'cert_emisiones' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <HuellaDeCarbonoContext>
                <HuellaDeCarbonoContainer />
              </HuellaDeCarbonoContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
    </Routes>
  );
};

export default observer(CertsSubtipoRoutes);
