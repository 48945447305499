import { ArgenpymesAction, InitialStateArgenpymes } from './index.types';

export default function argenpymesReducer(
  initialValues: InitialStateArgenpymes,
  action: ArgenpymesAction,
) {
  switch (action.type) {
    case 'set-cuit': {
      return { ...initialValues, cuit: action.value };
    }

    case 'set-cuits': {
      return { ...initialValues, cuits: action.value };
    }

    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-estadoSolicitudBody': {
      return { ...initialValues, estadoSolicitudBody: action.value };
    }

    case 'set-productName': {
      return { ...initialValues, productName: action.value };
    }

    default:
      return initialValues;
  }
}
