import React, { ReactNode } from 'react';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { H5, H6 } from 'components/common/typography/styles';
import { SpinnerSM, SpinnerSMW } from 'components/common/icon/icons/spinner';
import { StyledButton } from './index.styled';

interface IButton {
  text: string | ReactNode;
  variant: 'text' | 'outlined' | 'contained';
  preset: 'normal' | 'danger' | 'daruma';
  disabled?: boolean;
  onClick?: (v?: any) => void;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  size: 'small' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme>;
  type?: 'submit' | 'reset' | 'button';
}

const textButtonClass = (
  preset: IButton['preset'],
  variant: IButton['variant'],
  disabled: boolean,
  loading: boolean,
) => {
  if (loading) return 'btn-text-loading';

  if (preset === 'normal' && variant === 'contained')
    return 'btn-text-normal-contained';
  if (preset === 'normal' && variant === 'outlined')
    return 'btn-text-normal-outlined';

  if (preset === 'danger' && variant === 'contained')
    return 'btn-text-danger-contained';
  if (preset === 'danger' && variant === 'outlined')
    return 'btn-text-danger-outlined';

  if (preset === 'daruma' && variant === 'contained')
    return 'btn-text-daruma-contained';
  if (preset === 'daruma' && variant === 'outlined')
    return 'btn-text-daruma-outlined';

  if (disabled) return 'btn-text-disabled';

  return 'btn-text-normal-contained';
};

const Button: React.FC<IButton> = (props) => {
  const { text, loading, preset, sx, ...btnProps } = props;
  const fullWidth = useMediaQuery('(max-width:500px)');
  const width = useMediaQuery('(min-width:1024px)');

  const textClass = textButtonClass(
    preset,
    btnProps.variant,
    Boolean(btnProps.disabled),
    Boolean(loading),
  );

  return (
    <StyledButton
      {...btnProps}
      size={width ? btnProps.size : 'small'}
      fullWidth={fullWidth || btnProps.fullWidth}
      sx={sx}
      disableElevation
    >
      {loading && (
        <Box className="btn-loading">
          {btnProps.variant === 'outlined' ? (
            <SpinnerSM width="22px" />
          ) : (
            <SpinnerSMW />
          )}
        </Box>
      )}
      {btnProps.size === 'large' && width ? (
        <H5 className={textClass}>{text}</H5>
      ) : (
        <H6 className={textClass}>{text}</H6>
      )}
    </StyledButton>
  );
};

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  href: undefined,
  startIcon: undefined,
  endIcon: undefined,
  fullWidth: false,
  loading: false,
  sx: undefined,
  type: 'button',
  target: '_self',
};

export default Button;
