/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { H5 } from 'components/common/typography/styles';
import ProcedureAvailableCard from 'components/common/cards/procedures/Available';
import { AvailableProcedure } from 'models/finance';
import colors from 'theme/colors';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { ARRAY_EMPTY } from '../proceduresEmpty/messages.procedures';
import { aliasPermitidos } from 'utils/AllowedProcedures';

type TCredits = {
  handleOpen: (v: any, isProcedureInfo: boolean) => void;
  width: number;
  setIsThereDaruma: (v: boolean) => void;
  procedures: AvailableProcedure[];
  isLoading: boolean;
};

export const Credits = ({
  handleOpen,
  width,
  setIsThereDaruma,
  procedures,
  isLoading,
}: TCredits) => {
  const darumaFiltered = procedures?.filter(
    (procedure: AvailableProcedure) => !procedure.alias.includes('alta_daruma'),
  );
  const isThereDaruma =
    procedures?.filter((procedure: AvailableProcedure) =>
      procedure.alias.includes('alta_daruma'),
    ).length > 0;

  useEffect(() => {
    setIsThereDaruma(isThereDaruma);
  }, [isThereDaruma]);
  return (
    <>
      {(procedures !== undefined && procedures.length === ARRAY_EMPTY) ||
      (procedures === undefined && !isLoading) ? null : (
        <>
          <H5 style={{ color: colors.tertiaryMedium, marginTop: '26px' }}>
            Lineas de crédito
          </H5>
          <Grid
            container
            spacing={width < 600 ? '12px' : '12px'}
            my={width < 600 ? 1 : '0px'}
          >
            {isLoading && procedures === undefined ? (
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  height: '40px',
                  margin: '20px',
                }}
              >
                <SpinnerXL />
              </Box>
            ) : (
              darumaFiltered &&
              darumaFiltered.map((procedure: any) => {
                if (aliasPermitidos.includes(procedure.alias)) {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={`${procedure.subTipoTramite.alias}-${procedure.subTipoTramite.id}`}
                    >
                      <ProcedureAvailableCard
                        procedure={procedure}
                        startProcedureURL={`/financiamiento/${procedure.alias}`}
                        textBtn="Iniciar solicitud"
                        callback={() => handleOpen(procedure, width > 1022)}
                        width={width}
                      />
                    </Grid>
                  );
                }

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`${procedure.subTipoTramite.alias}-${procedure.subTipoTramite.id}`}
                  >
                    <ProcedureAvailableCard
                      procedure={procedure}
                      startProcedureURL={`/financiamiento/${procedure.alias}/seleccion`}
                      textBtn="Iniciar solicitud"
                      callback={() => handleOpen(procedure, width > 1022)}
                      width={width}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </>
      )}
    </>
  );
};