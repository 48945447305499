/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, IconButton } from '@mui/material';
import { P3, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import CustomTooltip from 'components/common/tooltips';
import { addFormikFieldProps } from 'hooks/useAddFormikFieldProps';
import { StyledLabel, StyledError, StyledInput } from '../index.styled';

interface TextInputProps {
  id: string;
  label: string;
  disabled: boolean;
  name: string;
  width: string;
  height: string;
  maxLength?: number;
  max?: string;
  warning?: string | undefined;
  explanation?: string | undefined;
  type: 'text' | 'email' | 'password' | 'number' | 'file';
  labelColor?: string;
  labelWeight?: string;
  labelSize?: string;
  placeholder: string;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;

  widthBox?: string;
  handleFocus?: () => void;
  handleBlur?: () => void;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    id,
    label,
    disabled,
    name,
    width,
    height,
    type,
    placeholder,
    warning,
    explanation,
    maxLength,
    max,
    handleSubmit,
    widthBox = '100%',
    handleFocus,
    labelColor,
    labelWeight,
    labelSize,
    handleBlur,
  } = props;
  const { error, value, onBlur, onChange } = addFormikFieldProps(name);
  const [iconVisibility, setIconVisibility] = useState(false);
  const showPassword = () => {
    setIconVisibility(!iconVisibility);
  };
  const typeInput = () => {
    if (type === 'password' && iconVisibility) {
      return 'text';
    }
    return type;
  };

  return (
    <Box width={widthBox}>
      <StyledLabel htmlFor={id}>
        <P3 color={colors.tertiaryBase}>{label}</P3>
      </StyledLabel>
      <Box position="relative" width="100%">
        <StyledInput
          disabled={disabled}
          id={id}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
          onKeyPress={(event) =>
            handleSubmit && event.key === 'Enter' && handleSubmit()
          }
          name={name}
          maxLength={maxLength}
          width={width}
          height={height}
          type={typeInput()}
          error={error || warning || undefined}
          placeholder={placeholder}
          onFocus={handleFocus}
          max={max}
        />
        {type === 'password' && (
          <IconButton
            aria-label="toggle password visibility"
            onClick={showPassword}
            sx={{
              position: 'absolute',
              right: error ? '38px' : '9px',
              top: '5px',
            }}
          >
            {iconVisibility ? (
              <CustomTooltip
                title="Ocultar contraseña"
                arrowLocation="top-start"
              >
                <VisibilityOffIcon
                  style={{ color: error ? colors.complementaryError : '' }}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip
                title="Mostrar contraseña"
                arrowLocation="top-start"
              >
                <VisibilityIcon
                  style={{ color: error ? colors.complementaryError : '' }}
                />
              </CustomTooltip>
            )}
          </IconButton>
        )}
        {disabled && !error && (
          <LockIcon
            sx={{
              position: 'absolute',
              right: '18px',
              top: '12px',
              color: colors.tertiaryMedium,
            }}
          />
        )}
        {error && (
          <WarningIcon
            sx={{
              color: colors.complementaryError,
              position: 'absolute',
              right: '18px',
              top: '12px',
            }}
          />
        )}
      </Box>
      {(error || warning) && (
        <StyledError>
          <P4 color={colors.complementaryError}>{error || warning}</P4>
        </StyledError>
      )}
      {explanation && (
        <StyledError>
          <P4 color={colors.tertiaryMedium}>{explanation}</P4>
        </StyledError>
      )}
    </Box>
  );
};

TextInput.defaultProps = {
  explanation: undefined,
  warning: undefined,
  maxLength: undefined,
  handleSubmit: undefined,
  handleFocus: undefined,
  handleBlur: undefined,
  widthBox: undefined,
  max: undefined,
  labelColor: colors.tertiaryBase,
  labelWeight: '350',
  labelSize: '13px',
};
export default TextInput;
