import { InitialStateSancor } from './index.types';

export const initialStateSancor: InitialStateSancor = {
  stateProcedure: 'seleccion_campos',
  refused: false,
  moveForward: false,
  routeState: 'seleccion',
  selectedFields: [],
  cuitApplicant: {
    id: null,
    nombre: null,
    apellido: null,
    email: null,
    telefono: null,
    cargo: null,
    cuenta: null,
  },
  social: null,
  nameInsurance: '',
};
