export const ColorByCheckList = (progress: number, colors: any): string => {
  return progress > 99
    ? colors.complementaryGreen
    : progress >= 40 && progress <= 99
    ? colors.complementaryYellow
    : colors.complementaryError;
};

export const moreEightChar = (value: string) => {
  if (value === undefined) {
    // Manejar el caso en que value es undefined, por ejemplo, devolver false
    return { checked: false, key: 'moreEightChar' };
  }

  if (value.length < 8) {
    return { checked: false, key: 'moreEightChar' };
  }
  return { checked: true, key: 'moreEightChar' };
};

export const specialChar = (value: string) => ({
  checked: /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(value),
  key: 'specialChar',
});

export const hasNumber = (value: string) => ({
  checked: /\d/g.test(value),
  key: 'hasNumber',
});

export const capitalLetter = (value: string) => {
  if (value === undefined) {
    return { checked: false, key: 'capitalLetter' };
  }

  let i = 0;
  if (value.length === 0) {
    return { checked: false, key: 'capitalLetter' };
  }
  while (i < value.length) {
    const character = value.charAt(i);
    if (character === character.toLowerCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character === character.toUpperCase()) {
      return { checked: true, key: 'capitalLetter' };
    }
    i++;
  }
  return { checked: false, key: 'capitalLetter' };
};

export const lowercaseLetter = (value: string) => {
  if (value === undefined) {
    return { checked: false, key: 'lowercaseLetter' };
  }

  let i = 0;

  if (value.length === 0) {
    return { checked: false, key: 'lowercaseLetter' };
  }

  while (i < value.length) {
    const character = value.charAt(i);

    if (character === character.toUpperCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character === character.toLowerCase()) {
      return { checked: true, key: 'lowercaseLetter' };
    }
    i++;
  }
  return { checked: false, key: 'lowercaseLetter' };
};

export const checks = [
  {
    label: '8 caracteres',
    checked: false,
    value: 20,
    key: 'moreEightChar',
  },
  {
    label: '1 mayúscula',
    checked: true,
    value: 20,
    key: 'capitalLetter',
  },
  {
    label: '1 minúscula',
    checked: false,
    value: 20,
    key: 'lowercaseLetter',
  },
  {
    label: '1 número',
    checked: false,
    value: 20,
    key: 'hasNumber',
  },
  {
    label: '1 carácter especial (!@#$%^&*)',
    checked: false,
    value: 20,
    key: 'specialChar',
  },
];

export const pipe =
  (...fns: any[]) =>
  (x: string) =>
    fns.map((e) => e(x));
