import React from 'react';
import { Box } from '@mui/material';
import { CapsMedium, P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { IDatosDelProducto } from 'models/models.daruma';

const DatosDeProducto: React.FC<IDatosDelProducto> = ({ product }) => {
  return (
    <Box width="50%">
      <CapsMedium color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
        PRODUCTOS ADICIONALES
      </CapsMedium>
      <P3 color={colors.tertiaryBase}>{product}</P3>
    </Box>
  );
};
export default DatosDeProducto;
