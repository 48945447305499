import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: hidden;

  @media (max-width: 768px) {
    /* max-width: 768px; */
    flex-direction: column;
    height: auto;
  }
`;

export const StyledImageCover = styled.div`
  width: 50vw;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(75, 72, 70, 0.9) 0%,
      rgba(9, 9, 121, 0) 50%,
      rgba(75, 72, 70, 0.9) 100%
    );
    @media (max-width: 768px) {
      background: linear-gradient(
        0deg,
        rgba(9, 9, 121, 0) 50%,
        rgba(75, 72, 70, 0.9) 100%
      );
    }
  }

  .logo {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 150px;
    height: 40px;
    z-index: 2;
  }

  .cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    /* max-width: 280px; */
    width: 100vw;
    height: 280px;
    .logo {
      left: calc(50% - 75px);
    }
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  width: 50vw;
  padding: 25px 0;
  @media (max-width: 768px) {
    /* max-width: 280px; */
    width: 100vw;
    align-items: flex-start;
  }
`;
