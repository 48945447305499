import styled from 'styled-components';
import { Box } from '@mui/material';
import colors from 'theme/colors';
import { H5, P3 } from 'components/common/typography/styles';

export const StyledAlert = styled(Box)`
  position: absolute;
  top: 50%;
  left: 48%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);
  border-radius: 8px;
  width: 90%;
  max-width: 376px;
  margin: 8px;

  &:focus-visible {
    outline: none;
  }
`;

export const WrapperContent = styled.div`
  margin: 16px;
  position: relative;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
`;

export const Title = styled(H5)`
  color: ${colors.complementaryGreen};
  margin-bottom: 10px;
  text-overflow: initial;
  white-space: initial;
`;

export const Message = styled(P3)`
  color: ${colors.tertiaryMedium};
`;

export const Anchor = styled.a`
  color: ${colors.tertiaryMedium};
  text-decoration: underline;
  text-underline-offset: 1px;
`;
