import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import { P2, P4 } from 'components/common/typography/styles';
import ImageViewer from 'components/common/secureMedia/imageViewer';
import colors from 'theme/colors';
import { addFormikFieldProps } from 'hooks/useAddFormikFieldProps';
import { StyledLoadFile, StyledError } from '../index.styled';

interface LoadFileProps {
  id: string;
  placeholder: string;
  image: string;
  explanation?: string;
  name: string;
  cbUpload: () => void;
}

const LoadFile: React.FC<LoadFileProps> = ({
  id,
  name,
  placeholder,
  image,
  explanation,
  cbUpload,
}) => {
  const { error, onChange } = addFormikFieldProps(name);
  const [hasImage, setHasImage] = useState(false);

  useEffect(() => {
    onChange(image as unknown as ChangeEvent<HTMLInputElement>);
    setHasImage(!!image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <Box
      width={{
        xs: '100%',
        md: '320px',
      }}
      mb={error || explanation ? '16px' : '0px'}
    >
      <StyledLoadFile
        id={id}
        error={!!error}
        hasFile={!!image}
        onClick={cbUpload}
      >
        {image ? (
          <ImageViewer
            styles={{
              width: '180px',
              height: '112px',
              borderRadius: '4px',
              boxShadow: '0px 2px 3px rgba(75, 72, 70, 0.2)',
            }}
            fileURL={image}
            altText={placeholder}
          />
        ) : (
          <P2
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AttachFileIcon fontSize="large" />
            {placeholder}
          </P2>
        )}

        <button
          type="button"
          aria-label="Editar imagen"
          style={{
            background: 'none',
            border: 'none',
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            zIndex: '10',
            cursor: 'pointer',
          }}
        />
        {hasImage && (
          <EditIcon
            className="edit-icon"
            sx={{
              color: 'white',
              background: colors.tertiaryBase,
              borderRadius: '50%',
              cursor: 'pointer',
              padding: '5px',
              position: 'absolute',
              right: '16px',
            }}
          />
        )}
      </StyledLoadFile>
      <StyledError>
        {error ? (
          <P4 color={colors.red}>{error}</P4>
        ) : explanation ? (
          <P4 color={colors.tertiaryMedium}>{explanation}</P4>
        ) : null}
      </StyledError>
    </Box>
  );
};

export default LoadFile;
