import React, { createContext, useReducer } from 'react';
import { initialStateDaruma } from './initialValues';
import darumaReducer from './index.reducer';
import { DarumaDispatch, InitialStateDaruma } from './index.types';

export const DarumaStateContext =
  createContext<InitialStateDaruma>(initialStateDaruma);
export const DarumaDispatchContext = createContext<DarumaDispatch>(
  {} as DarumaDispatch,
);

export const DarumaContext = ({ children }: { children: React.ReactNode }) => {
  const [darumaValues, dispatch] = useReducer(
    darumaReducer,
    initialStateDaruma,
  );
  return (
    <DarumaDispatchContext.Provider value={dispatch}>
      <DarumaStateContext.Provider value={darumaValues}>
        {children}
      </DarumaStateContext.Provider>
    </DarumaDispatchContext.Provider>
  );
};
