import React, { useState } from 'react';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import TextInput from 'components/common/inputs/text';
import { P3 } from 'components/common/typography/styles';
import { ToSModal } from 'components/common/modals/tosModal';
import { RegisterFromErpSchema } from 'utils/schema-validations';
import { useValidateTokenErp } from 'screens/portal/activar-cuenta/useValidateTokenErp';
import Button from '../buttons/button';

enum FieldNames {
  fieldName = 'nombre',
  fieldSurname = 'apellido',
}

interface ValidateErpFormProps {
  token: string;
  navigate: (url: string) => void;
}

const ValidateErpForm = (props: ValidateErpFormProps) => {
  const { token, navigate } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const { openModal, setOpenModal, initialValues, registerFromErp } =
    useValidateTokenErp(token);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={registerFromErp}
      validationSchema={RegisterFromErpSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }

        return (
          <>
            <ToSModal open={openModal} onClose={() => setOpenModal(false)} />
            <Box my="10px" width="100%">
              <TextInput
                id="name-field"
                label="Nombres"
                maxLength={150}
                disabled={false}
                name={FieldNames.fieldName}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Ingresá tu nombre completo"
              />
            </Box>
            <Box my="10px" width="100%">
              <TextInput
                id="lastname-field"
                label="Apellidos"
                maxLength={150}
                disabled={false}
                name={FieldNames.fieldSurname}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Ingresá tus apellidos"
              />
            </Box>
            <Box
              textAlign={{
                xs: 'center',
                laptop: 'left',
              }}
              width="100%"
            >
              <P3
                style={{
                  color: '#333333',
                  marginTop: '14px',
                }}
              >
                Al crear una cuenta, confirmo que leí y acepto las&nbsp;
                <P3
                  style={{
                    color: '#333333',
                    textDecoration: 'underline',
                    textUnderlineOffset: '1px',
                    display: 'inline',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  Condiciones, Políticas de datos y privacidad.
                </P3>
              </P3>
            </Box>
            <Box marginTop="24px" id="activar_cuenta_ingresar">
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Activar cuenta"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default ValidateErpForm;
